import React, { useEffect, useState } from "react";
import SidebarItem from "./SidebarItem"
import UserService from "../apis/UserService";

export default function Sidebar({ data,loginButton }) {
  const [items, setItems] = useState([])
  const [Loader, setLoader] = useState(false);

  useEffect(() => {
    async function calAyncFunction() {
      await getCategories();
    }

    calAyncFunction();
  }, []);

  const getCategories = async () => {
    try {
      setLoader(true)
      await UserService.getGCatsData().then(res => {
        if (res && res.data.responseCode) {
          var msg = res.data.response;
          if (msg == "Success") {
            setItems(res.data.result)
            setLoader(false)
          }
          else {
            setLoader(false)
            console.log(msg)
          }
        }
      }).catch(error => {
        setLoader(false)
        console.log(error)
      })
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  return (
<>
{/* Loader */}
    {Loader && 
      <div className="boxes-bg">
    <div className="boxes">
        <div className="box">
            <div />
            <div />
            <div />
            <div />
        </div>
        <div className="box">
            <div />
            <div />
            <div />
            <div />
        </div>
        <div className="box">
            <div />
            <div />
            <div />
            <div />
        </div>
        <div className="box">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
</div>}

{/* Left Sidebar */}
    <div className="left-site-menu">
      <div className="left-box">
        <header className="header">
          <nav className="menu">
            <ul className="main-list-menu">
              <li>
            <a className="sidebar-item d-sm-none" href={process.env.REACT_APP_UP_BaseName +"/#"}>
                      <i className="homeIcon fa fa-home"  />
                    Home
                  </a> 
                  </li>
              <li>
              {items.map((item, index) => <SidebarItem key={index} item={item} loginButton={loginButton} data={data.catId == item.id ? data : null} />)}
              </li>
            </ul>
            <div className="hamburger">
             <span />
          </div>
          <div className="hamb">
            <span />
          </div>
          <div className="dimmer" />
          </nav>
     
        </header>
      </div>
    </div>
    </>
  )
}