import { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("#profile1");

    const Logout = () => {
        localStorage.removeItem("up_token")
        navigate("/");
    }

    return (
        <>
            <div className="body-middle">
                {/*Breadcumnd*/}
                <div className="breadcumnd-banner">
                    <div className="container">
                        <div className="breadcumd-content">
                            <h1>
                                Profile
                            </h1>
                            <ul className="bread-tag">
                                <li>
                                    <a href="#0">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fas fa-arrow-right" />
                                </li>
                                <li>
                                    Profile
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*Breadcumnd*/}
                {/*profile*/}
                <div className="profile-section pb-60">
                    <div className="container">
                        <div className="profile-tab">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className="nav-link link-secondary active" id="profile-tab" data-bs-toggle="tab" onClick={() => setActiveTab("#profile1")} href="#"><span>Profile</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-secondary" id="profile-tab01" onClick={() => setActiveTab("#profile2")} data-bs-toggle="tab" href="#"><span>Settings</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-secondary" id="profile-tab02" data-bs-toggle="tab" onClick={() => setActiveTab("#profile3")} href="#"><span>Rewards</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-secondary" id="profile-tab03" data-bs-toggle="tab" onClick={() => setActiveTab("#profile4")} href="#"><span>Wallet</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-secondary" id="profile-tab04" data-bs-toggle="tab" onClick={() => setActiveTab("#profile5")} href="#"><span>Verification</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-secondary" id="profile-tab05" data-bs-toggle="tab" onClick={() => Logout()} href="#"><span>Log out</span></a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content pt-60" id="pro-wrap">
                            <div className={activeTab == "#profile1" ? "tab-pane fade show active" : "tab-pane fade"} id="profile1" role="tabpanel">
                                <div className="row">
                                    <div className="col-xxl-7 col-xl-12 col-lg-7">
                                        <div className="profile-left-wrap">
                                            <div className="accounts-box">
                                                <span>Account</span>
                                                <p>Login Username: jijiwi2841</p>
                                                <a href="#0">RESET NET PROFIT</a>
                                            </div>
                                            <div className="form-box">
                                                <div className="form-head">
                                                    <span>Details</span>
                                                </div>
                                                <form action="#0">
                                                    <div className="form-grp">
                                                        <input type="text" placeholder="jijiwi2841" />
                                                        <div className="left-icon">
                                                            <i className="fas fa-user" />
                                                        </div>
                                                        <div className="right-lock">
                                                            <i className="fa-solid fa-lock" />
                                                        </div>
                                                    </div>
                                                    <div className="form-grp form-grp-two">
                                                        <input type="email" placeholder="jijiwi2841@edxplus.com" />
                                                        <div className="left-icon">
                                                            <i className="fas fa-envelope" />
                                                        </div>
                                                        <div className="right-lock">
                                                            <i className="fa-solid fa-lock" />
                                                        </div>
                                                        <a href="#0" className="repeat">
                                                            <i className="fas fa-repeat" />
                                                        </a>
                                                    </div>
                                                    <div className="form-grp">
                                                        <input type="number" placeholder="(208) 555-0112" />
                                                        <div className="left-icon">
                                                            <i className="fas fa-phone" />
                                                        </div>
                                                        <div className="right-lock">
                                                            <i className="fa-solid fa-lock" />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="change-picture-box">
                                                <div className="head">
                                                    <span>Change profile picture</span>
                                                </div>
                                                <div className="change-box">
                                                    <div className="down">
                                                        <img src="assets/img/table/details/picture.png" alt="img" />
                                                    </div>
                                                    <a href="#0">
                                                        Choose file to upload
                                                    </a>
                                                    <p>Supported Files: JPG, JPEG, PNG, BMP PDF, TIE TIFF with a max size of 5 ME</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-5 col-xl-12 col-lg-5">
                                        <div className="right-lebel">
                                            <div className="verification">
                                                <span>Verification</span>
                                                <p>
                                                    In consectetur pharetra ipsum id aliquam. Ut viverra enim a arcu accumsan, at vehicula nulla malesuada.
                                                </p>
                                                <h6>
                                                    O tasks completed out of 3
                                                </h6>
                                                <span className="bar" />
                                            </div>
                                            <ul className="lebel-wrap">
                                                <li>
                                                    <span className="text1">Level 1</span>
                                                    <a href="#0" className="text2">Click here to complete</a>
                                                </li>
                                                <li>
                                                    <span>Level 2</span>
                                                    <a href="#0">Complete level 1 first</a>
                                                </li>
                                                <li>
                                                    <span>Level 3</span>
                                                    <a href="#0">Complete level 1 first</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={activeTab == "#profile2" ? "tab-pane fade show active" : "tab-pane fade"} id="profile2" role="tabpanel">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="setting-wrap">
                                            <div className="setting-box">
                                                <h4>
                                                    Language bb
                                                </h4>
                                                <select name="setting-select" id="setting">
                                                    <option value={1}>
                                                        English
                                                    </option>
                                                    <option value={2}>
                                                        Bangla
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="setting-box">
                                                <h4>
                                                    Chat History Length
                                                </h4>
                                                <div className="history">
                                                    <span className="current-text">Live Support Button Visibility:</span>
                                                    <a href="#0" className="cmn--btn">
                                                        <span>50</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="setting-box">
                                                <h4>
                                                    Live Support
                                                </h4>
                                                <div className="history">
                                                    <span className="current-text">Current history length:</span>
                                                    <a href="#0" className="cmn--btn">
                                                        <span>Visible</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={activeTab == "#profile3" ? "tab-pane fade show active" : "tab-pane fade"} id="profile3" role="tabpanel">
                                <div className="row justify-content-between">
                                    <div className="col-xxl-7 col-xl-12 col-lg-7">
                                        <div className="reword-wrap-left">
                                            <div className="reword-box">
                                                <div className="profited-man">
                                                    <img src="assets/img/table/details/porfitman.png" alt="man" />
                                                </div>
                                                <div className="profit-lebel">
                                                    <div className="label">
                                                        <span>Level 1</span>
                                                        <span>Level 2</span>
                                                    </div>
                                                    <span className="bar" />
                                                    <a href="#0">
                                                        67976 XP remaining to your next Tier
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="reword-history">
                                                <div className="head">
                                                    <h6>Rewards</h6>
                                                    <span>View reward history</span>
                                                </div>
                                                <div className="reword-history-wrap">
                                                    <div className="reword-history-box">
                                                        <a href="#0" className="re-btn">
                                                            <span className="text-base">Instant</span> Rakeback
                                                        </a>
                                                        <div className="reword-history-right-timing">
                                                            <a href="#0">
                                                                $0
                                                            </a>
                                                            <i className="fa-solid fa-lock" />
                                                            <div className="timing">
                                                                <span>Unavailable</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reword-history-box">
                                                        <a href="#0" className="re-btn">
                                                            <span className="text-base">Weekly</span> Rakeback
                                                        </a>
                                                        <div className="reword-history-right-timing">
                                                            <a href="#0">
                                                                $0
                                                            </a>
                                                            <i className="fa-solid fa-lock" />
                                                            <div className="timing">
                                                                <ul className="countdown" data-date="10/29/2022 16:16:00">
                                                                    <li>
                                                                        09-17
                                                                    </li>
                                                                    <li>
                                                                        <span className="date">03:</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="seconds">22s</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reword-history-box">
                                                        <a href="#0" className="re-btn">
                                                            <span className="text-base">Monthly</span> Rakeback
                                                        </a>
                                                        <div className="reword-history-right-timing">
                                                            <a href="#0">
                                                                $0
                                                            </a>
                                                            <i className="fa-solid fa-lock" />
                                                            <div className="timing">
                                                                <ul className="countdown" data-date="10/29/2022 16:16:00">
                                                                    <li>
                                                                        09-17
                                                                    </li>
                                                                    <li>
                                                                        <span className="date">03:</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="seconds">22s</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-12 col-lg-4">
                                        <div className="rakeback-wrap">
                                            <h6 className="rakeback-head">
                                                Rakeback Tiers
                                            </h6>
                                            <div className="detail-match-winner rakeback-accordion">
                                                <div className="details-match-wrap">
                                                    <div className="accordion" id="rakeback-accordion">
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback1" aria-expanded="false" aria-controls="rakeback1">
                                                                    <span>Level 1 - 10</span>
                                                                </button>
                                                                <div id="rakeback1" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback2" aria-expanded="false" aria-controls="rakeback2">
                                                                    <span>Level 11-20</span>
                                                                </button>
                                                                <div id="rakeback2" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback3" aria-expanded="false" aria-controls="rakeback3">
                                                                    <span>Level 21-30</span>
                                                                </button>
                                                                <div id="rakeback3" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback4" aria-expanded="false" aria-controls="rakeback4">
                                                                    <span>Level 41-50</span>
                                                                </button>
                                                                <div id="rakeback4" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback5" aria-expanded="false" aria-controls="rakeback5">
                                                                    <span>Level 51 - 65</span>
                                                                </button>
                                                                <div id="rakeback5" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback6" aria-expanded="false" aria-controls="rakeback6">
                                                                    <span>Level 66-80</span>
                                                                </button>
                                                                <div id="rakeback6" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback7" aria-expanded="false" aria-controls="rakeback7">
                                                                    <span>Level 81-100</span>
                                                                </button>
                                                                <div id="rakeback7" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback8" aria-expanded="false" aria-controls="rakeback8">
                                                                    <span>Level 101-120</span>
                                                                </button>
                                                                <div id="rakeback8" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback9" aria-expanded="false" aria-controls="rakeback9">
                                                                    <span>Level 121-140</span>
                                                                </button>
                                                                <div id="rakeback9" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <div className="accordion-header">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#rakeback10" aria-expanded="false" aria-controls="rakeback10">
                                                                    <span>Level 141+</span>
                                                                </button>
                                                                <div id="rakeback10" className="accordion-collapse collapse">
                                                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                                                        <div className="rakeback-accor-body">
                                                                            <p>
                                                                                Users in this category have automatically increased Rakebacks
                                                                            </p>
                                                                            <ul>
                                                                                <li>
                                                                                    Instant Rakeback <span className="text-base">6%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Daily Surprise Up To <span className="text-base">35%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Weekly Rakeback <span className="text-base">2%</span>
                                                                                </li>
                                                                                <li>
                                                                                    Monthly Rakeback <span className="text-base">1%</span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={activeTab == "#profile4" ? "tab-pane fadeshow active" : "tab-pane fade"} id="profile4" role="tabpanel">
                                <div className="row justify-content-between">
                                    <h3 className="wallet-title">
                                        Wallet
                                    </h3>
                                    <div className="wallet-tab-wrap">
                                        <ul className="nav">
                                            <li className="nav-item">
                                                <a className="nav-link link-secondary active" id="wallet-tab" data-bs-toggle="tab" data-bs-target="#wallet1" href="#"><span>Deposit</span></a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link link-secondary" id="wallet-tab01" data-bs-toggle="tab" data-bs-target="#wallet2" href="#"><span>Withdraw</span></a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link link-secondary" id="wallet-tab03" data-bs-toggle="tab" data-bs-target="#wallet3" href="#"><span>Transactions</span></a>
                                            </li>
                                        </ul>
                                        <div className="gift-card">
                                            <a href="#0" className="left-cart">
                                                <span><img src="assets/img/table/details/gitft.png" alt="img" /></span>
                                                <span>Redeem Gift Card</span>
                                            </a>
                                            <a href="#0" className="cmn--btn">
                                                <span>
                                                    Claim
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="wall-wrap">
                                        <div className="tab-pane fade show active" id="wallet1" role="tabpanel">
                                            <div className="currency-wrap">
                                                <h5 className="currency-title">
                                                    Crypto Currencies
                                                </h5>
                                                <div className="currency-head">
                                                    <div className="currency-head-left">
                                                        <div className="icon">
                                                            <img src="assets/img/table/details/bitcoin.png" alt="icon" />
                                                        </div>
                                                        <div className="cont">
                                                            <h6>
                                                                Purchase Crypto with Card
                                                            </h6>
                                                            <p>
                                                                You don`t have Crypto? Buy it here now.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="currency-head-right">
                                                        <div className="icon">
                                                            <img src="assets/img/table/details/monnyplay.png" alt="icon" />
                                                        </div>
                                                        <a href="#0" className="cmn--btn">
                                                            <span>Purchase Crypto</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="row mb-4 g-3">
                                                    <div className="col-lg-6">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/bitcoin.png" alt="icon" />
                                                                </span>
                                                                <span>Bitcoin</span>
                                                            </a>
                                                            <a href="#0">
                                                                <span> 1 BTC = $19565.46</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/ethereum.png" alt="icon" />
                                                                </span>
                                                                <span>Ethereum</span>
                                                            </a>
                                                            <a href="#0">
                                                                <span> 1 ETH = $1343.21</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/thether.png" alt="icon" />
                                                                </span>
                                                                <span>TETHER</span>
                                                            </a>
                                                            <a href="#0">
                                                                <span> 1 USDT = $1.00
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/litecoin.png" alt="icon" />
                                                                </span>
                                                                <span>Litecoin</span>
                                                            </a>
                                                            <a href="#0">
                                                                <span> 1 LTC = $53.76</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="currency-title">
                                                    Bank Deposit
                                                </h5>
                                                <div className="row mb-4 g-3">
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/b1.png" alt="icon" />
                                                                </span>
                                                                <span>Ecopayz</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/b2.png" alt="icon" />
                                                                </span>
                                                                <span>Cards through PayDo wallet top-up</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item select-algeria">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/algeria.png" alt="icon" />
                                                                </span>
                                                            </a>
                                                            <select name="algeria" id="algeri-select">
                                                                <option value={1}>
                                                                    Algeria
                                                                </option>
                                                                <option value={1}>
                                                                    England
                                                                </option>
                                                                <option value={1}>
                                                                    Croatia
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="currency-title">
                                                    Gift Cards
                                                </h5>
                                                <div className="row g-3">
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/master.png" alt="icon" />
                                                                </span>
                                                                <span>MasterCard</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/visa.png" alt="icon" />
                                                                </span>
                                                                <span>Visa</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/paypal.png" alt="icon" />
                                                                </span>
                                                                <span>Paypal</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/paysafe.png" alt="icon" />
                                                                </span>
                                                                <span>Paysafecard</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/gplay.png" alt="icon" />
                                                                </span>
                                                                <span>Google Pay</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/trustly.png" alt="icon" />
                                                                </span>
                                                                <span>Trustly</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="wallet2" role="tabpanel">
                                            <div className="currency-wrap">
                                                <h5 className="currency-title">
                                                    Crypto Currencies
                                                </h5>
                                                <div className="row mb-4 g-3">
                                                    <div className="col-lg-6">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/bitcoin.png" alt="icon" />
                                                                </span>
                                                                <span>Bitcoin</span>
                                                            </a>
                                                            <a href="#0">
                                                                <span> 1 BTC = $19565.46</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/ethereum.png" alt="icon" />
                                                                </span>
                                                                <span>Ethereum</span>
                                                            </a>
                                                            <a href="#0">
                                                                <span> 1 ETH = $1343.21</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/thether.png" alt="icon" />
                                                                </span>
                                                                <span>TETHER</span>
                                                            </a>
                                                            <a href="#0">
                                                                <span> 1 USDT = $1.00
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="bitcoin-item">
                                                            <a href="#0" className="icon-wrap">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/details/litecoin.png" alt="icon" />
                                                                </span>
                                                                <span>Litecoin</span>
                                                            </a>
                                                            <a href="#0">
                                                                <span> 1 LTC = $53.76</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="wallet3" role="tabpanel">
                                            <div className="description-table">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th>Amount</th>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        <tr className="tb1">
                                                            <td>
                                                                <span className="text1">-$620</span>
                                                            </td>
                                                            <td>
                                                                04:02 PM
                                                                <span>10 Mar 2022</span>
                                                            </td>
                                                            <td>
                                                                In Progress
                                                            </td>
                                                            <td>
                                                                <span className="text1">Withdraw</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="tb2">
                                                            <td>
                                                                <span className="text1">-$420</span>
                                                            </td>
                                                            <td>
                                                                05:02 PM
                                                                <span>10 Mar 2022</span>
                                                            </td>
                                                            <td>
                                                                Completed
                                                            </td>
                                                            <td>
                                                                <span className="text1">Deposit</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="tb1">
                                                            <td>
                                                                <span className="text1">-$220</span>
                                                            </td>
                                                            <td>
                                                                11:2 PM
                                                                <span>10 Mar 2022</span>
                                                            </td>
                                                            <td>
                                                                Pending
                                                            </td>
                                                            <td>
                                                                <span className="text1">Withdraw</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="tb2">
                                                            <td>
                                                                <span className="text1">-$320</span>
                                                            </td>
                                                            <td>
                                                                09:45 PM
                                                                <span>10 Mar 2022</span>
                                                            </td>
                                                            <td>
                                                                Completed
                                                            </td>
                                                            <td>
                                                                <span className="text1">Deposit</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="tb1">
                                                            <td>
                                                                <span className="text1">-$920</span>
                                                            </td>
                                                            <td>
                                                                07:20 PM
                                                                <span>10 Mar 2022</span>
                                                            </td>
                                                            <td>
                                                                Pending
                                                            </td>
                                                            <td>
                                                                <span className="text1">Withdraw</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="tb1">
                                                            <td>
                                                                <span className="text1">-$920</span>
                                                            </td>
                                                            <td>
                                                                03:53 PM
                                                                <span>10 Mar 2022</span>
                                                            </td>
                                                            <td>
                                                                Cancelled
                                                            </td>
                                                            <td>
                                                                <span className="text1">Withdraw</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={activeTab == "#profile5" ? "tab-pane fadeshow active" : "tab-pane fade"} id="profile5" role="tabpanel">
                                <div className="varification">
                                    <div className="varification-head">
                                        <h4>
                                            Verification
                                        </h4>
                                        <p>
                                            Nulla facilisi. Quisque in elit vehicula, iaculis risus non, pharetra magna. Aliquam non augue a risus consequat hendrerit a quis odio. Suspendisse eleifend luctus purus, vitae tincidunt ligula venenatis nec. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                                        </p>
                                    </div>
                                    <form action="#0">
                                        <span className="title">Personal Information</span>
                                        <div className="row mb-4 g-3">
                                            <div className="col-xxl-2 col-xl-4">
                                                <div className="form-grp">
                                                    <input type="text" placeholder="Title" />
                                                    <i className="fas fa-user" />
                                                </div>
                                            </div>
                                            <div className="col-xxl-5 col-xl-4">
                                                <div className="form-grp">
                                                    <input type="text" placeholder="First nmae" />
                                                    <i className="fas fa-user" />
                                                </div>
                                            </div>
                                            <div className="col-xxl-5 col-xl-4">
                                                <div className="form-grp">
                                                    <input type="text" placeholder="Last nmae" />
                                                    <i className="fas fa-user" />
                                                </div>
                                            </div>
                                        </div>
                                        <span className="title">Date of Birth</span>
                                        <div className="year-wrap mb-4">
                                            <div className="row">
                                                <div className="col-xxl-5 col-xl-12">
                                                    <div className="date-wrap">
                                                        <div className="items">
                                                            <select name="year" id="year-select2my">
                                                                <option value={1}>
                                                                    Year
                                                                </option>
                                                                <option value={1}>
                                                                    2021
                                                                </option>
                                                                <option value={1}>
                                                                    2022
                                                                </option>
                                                            </select>
                                                            <span className="icon"><i className="fas fa-thumbtack" /></span>
                                                        </div>
                                                        <div className="items">
                                                            <select name="year" id="year-select2">
                                                                <option value={1}>
                                                                    Month
                                                                </option>
                                                                <option value={1}>
                                                                    January
                                                                </option>
                                                                <option value={1}>
                                                                    February
                                                                </option>
                                                            </select>
                                                            <span className="icon"><i className="fas fa-thumbtack" /></span>
                                                        </div>
                                                        <div className="items">
                                                            <select name="year" id="year-select3">
                                                                <option value={1}>
                                                                    Day
                                                                </option>
                                                                <option value={1}>
                                                                    1
                                                                </option>
                                                                <option value={1}>
                                                                    2
                                                                </option>
                                                            </select>
                                                            <span className="icon"><i className="fas fa-thumbtack" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="title">Address</span>
                                        <div className="row g-3">
                                            <div className="col-lg-6">
                                                <div className="form-grp form-grp-two">
                                                    <input type="text" placeholder="Address" />
                                                    <i className="fas fa-thumbtack" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-grp form-grp-two">
                                                    <input type="text" placeholder="Address (Additional information)" />
                                                    <i className="fas fa-thumbtack" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-grp form-grp-two">
                                                    <input type="text" placeholder="ZIP / Postal Code" />
                                                    <i className="fas fa-thumbtack" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-grp form-grp-two">
                                                    <input type="text" placeholder="City" />
                                                    <i className="fas fa-thumbtack" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-grp form-grp-two">
                                                    <input type="text" placeholder="State or Province" />
                                                    <i className="fas fa-thumbtack" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="date-wrap">
                                                    <div className="items items2 w-100">
                                                        <select name="year" id="year-select">
                                                            <option value={1}>
                                                                Country
                                                            </option>
                                                            <option value={1}>
                                                                India
                                                            </option>
                                                            <option value={1}>
                                                                Us
                                                            </option>
                                                        </select>
                                                        <span className="icon"><i className="fas fa-thumbtack" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-12">
                                                <div className="form-grp form-label">
                                                    <label className="form-check-label d-flex align-items-center" htmlFor="coun">
                                                        <input className="form-check-input" type="checkbox" defaultValue id="coun" defaultChecked />
                                                        I verify that the document submitted is accurate.
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-grp">
                                                    <button type="submit" className="cmn--btn">
                                                        <span>Submit Information</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="row justify-content-between">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*profile*/}
                <Footer />
                <a href="#0" className="click-btn" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                    <i className="icon-basketball" />
                </a>
            </div>

            {/*Right Box Chat*/}
            <div className="right-site-menu">
                <div className="right-box">
                    <div className="right-wrapper-area">
                        <div className="blance-items">
                            <div className="left-blance">
                                <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                                <span className="text-blabce">Balance</span>
                            </div>
                            <span className="blance">
                                $300
                            </span>
                        </div>
                        <div className="betslip-wrapper">
                            <a href="#0" className="left-betslip">
                                <span className="bed2"><img src="assets/img/header/right-icon/bed2.png" alt="icon" /></span>
                                <span className="bed1"><img src="assets/img/header/right-icon/bed.svg" alt="icon" /></span>
                                <span className="text-bets">Bet Slip</span>
                            </a>
                            <a href="#0" className="left-betslip">
                                <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                                <span className="text-bets">My Bets</span>
                            </a>
                        </div>
                        <div className="combo-box">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className="nav-link link-secondary" id="combo-tab" data-bs-toggle="tab" data-bs-target="#coombo" href="#"><span>Single bets</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-secondary active" id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>Combo</span></a>
                                </li>
                            </ul>
                            <div className="tab-content" id="tabContentboxes">
                                <div className="tab-pane fade" id="coombo" role="tabpanel">
                                    <div className="combo-wrapper">
                                        <div className="combo-wrapper">
                                            <div className="close-box">
                                                <div className="close-items">
                                                    <div className="close-head">
                                                        <span>Latvia vs Moldova</span>
                                                        <span className="close">
                                                            <i className="fas fa-xmark" />
                                                        </span>
                                                    </div>
                                                    <div className="match-fixing">
                                                        <div className="match-items">
                                                            <div className="match-items-left">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                </div>
                                                                <div className="cont">
                                                                    <span>Moldova</span>
                                                                    <span className="winner">Match Winner</span>
                                                                </div>
                                                            </div>
                                                            <div className="match-items-right">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                </div>
                                                                <span>5.20</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="combo-switch">
                                                <span>
                                                    Accept all odds changes
                                                </span>
                                                <label className="switch-com">
                                                    <input type="checkbox" className="checkbox" hidden />
                                                    <span className="checkbox-label">
                                                        <span className="ball" />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="ammount-items">
                                                <form action="#">
                                                    <input type="number" placeholder="Bet Amount" />
                                                    <button type="submit">
                                                        Max
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="possible-win">
                                                <span>Possible win</span>
                                                <span className="amount">$300</span>
                                            </div>
                                            <div className="combo-footer">
                                                <a href="#0" className="cmn--btn">
                                                    <span> Place Bet $300</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show active" id="bbets" role="tabpanel">
                                    <div className="combo-wrapper">
                                        <div className="close-box">
                                            <div className="close-items">
                                                <div className="close-head">
                                                    <span>Scotland vs Ukraine</span>
                                                    <span className="close">
                                                        <i className="fas fa-xmark" />
                                                    </span>
                                                </div>
                                                <div className="match-fixing">
                                                    <div className="match-items">
                                                        <div className="match-items-left">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                            </div>
                                                            <div className="cont">
                                                                <span>Scotland</span>
                                                                <span className="winner">Match Winner</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>3.20</span>
                                                        </div>
                                                    </div>
                                                    <div className="match-items">
                                                        <div className="match-items-left">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                            </div>
                                                            <div className="cont">
                                                                <span>Draw</span>
                                                                <span className="winner">Match Winner</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>4.60</span>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        Add 2 more outcome(s) with odds greater than 1.4 to get x1.08
                                                    </p>
                                                    <div className="match-items match-progress">
                                                        <div className="match-items-left">
                                                            <div className="prabox-wrap">
                                                                <div className="terminal-bar">
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                </div>
                                                                <div className="bar-count">
                                                                    <p>x1.08</p>
                                                                    <p>x1,15</p>
                                                                    <p>x1.20</p>
                                                                    <p>x1.50</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>1.00</span>
                                                        </div>
                                                    </div>
                                                    <div className="match-items match-odds">
                                                        <div className="match-items-left">
                                                            <div className="cont">
                                                                <span>Total odds</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>12.19</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="combo-switch">
                                            <span>
                                                Accept all odds changes
                                            </span>
                                            <label className="switch-com">
                                                <input type="checkbox" className="checkbox" hidden />
                                                <span className="checkbox-label">
                                                    <span className="ball" />
                                                </span>
                                            </label>
                                        </div>
                                        <div className="ammount-items">
                                            <form action="#">
                                                <input type="number" placeholder="Bet Amount" />
                                                <button type="submit">
                                                    Max
                                                </button>
                                            </form>
                                        </div>
                                        <div className="possible-win">
                                            <span>Possible win</span>
                                            <span className="amount">$300</span>
                                        </div>
                                        <div className="combo-footer">
                                            <a href="#0" className="cmn--btn">
                                                <span> Place Bet $300</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-box">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <span className="icon"><i className="fa-sharp fa-solid fa-gear" /></span>
                                            <span>Settings</span>
                                        </button>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="select-wrap">
                                                    <div className="select-one">
                                                        <select className="select" name="select" id="n-select">
                                                            <option value={1}>English</option>
                                                            <option value={1}>Spanish</option>
                                                        </select>
                                                        <span className="add">Lunguage</span>
                                                    </div>
                                                    <div className="select-two">
                                                        <select className="select" name="select" id="s-select">
                                                            <option value={1}>Decimal</option>
                                                            <option value={1}>Decimal Odds</option>
                                                        </select>
                                                        <span className="add">Odds</span>
                                                    </div>
                                                </div>
                                                <a href="#0" className="condition-box">
                                                    Terms &amp; Conditions
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popular-bets">
                        <div className="head">
                            <h6>
                                Popular bets
                            </h6>
                        </div>
                        <ul className="popoular-body">
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault1" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Prance
                                        </span>
                                        <span className="text3">
                                            France - Austria
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        2.37
                                    </span>
                                </div>
                            </li>
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault2" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Turkey
                                        </span>
                                        <span className="text3">
                                            Turkey - Luxembourg
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        1.37
                                    </span>
                                </div>
                            </li>
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault3" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Alkmaar
                                        </span>
                                        <span className="text3">
                                            Alkmaar - Apollon
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        3.47
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/*menu modal*/}
            <div className="modal right-menu-modal fade" id="exampleModal3" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <button type="button" className="btn-close cross-btn" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="right-site-menu">
                                <div className="right-box">
                                    <div className="right-wrapper-area">
                                        <div className="blance-items">
                                            <div className="left-blance">
                                                <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                                                <span className="text-blabce">Balance</span>
                                            </div>
                                            <span className="blance">
                                                $300
                                            </span>
                                        </div>
                                        <div className="betslip-wrapper">
                                            <a href="#0" className="left-betslip">
                                                <span><img src="assets/img/header/right-icon/bed.svg" alt="icon" /></span>
                                                <span className="text-bets">Bet Slip</span>
                                            </a>
                                            <a href="#0" className="left-betslip">
                                                <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                                                <span className="text-bets">My Bets</span>
                                            </a>
                                        </div>
                                        <div className="combo-box">
                                            <ul className="nav">
                                                <li className="nav-item">
                                                    <a className="nav-link link-secondary" data-bs-toggle="tab" data-bs-target="#coombomobile" href="#"><span>Single bets</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link link-secondary active" data-bs-toggle="tab" data-bs-target="#bbetsmobile" href="#"><span>Combo</span></a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="tabContentboxesmobile">
                                                <div className="tab-pane fade" id="coombomobile" role="tabpanel">
                                                    <div className="combo-wrapper">
                                                        <div className="combo-wrapper">
                                                            <div className="close-box">
                                                                <div className="close-items">
                                                                    <div className="close-head">
                                                                        <span>Latvia vs Moldova</span>
                                                                        <span className="close">
                                                                            <i className="fas fa-xmark" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="match-fixing">
                                                                        <div className="match-items">
                                                                            <div className="match-items-left">
                                                                                <div className="icon">
                                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                                </div>
                                                                                <div className="cont">
                                                                                    <span>Moldova</span>
                                                                                    <span className="winner">Match Winner</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="match-items-right">
                                                                                <div className="icon">
                                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                                </div>
                                                                                <span>5.20</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="combo-switch">
                                                                <span>
                                                                    Accept all odds changes
                                                                </span>
                                                                <label className="switch-com">
                                                                    <input type="checkbox" className="checkbox" hidden />
                                                                    <span className="checkbox-label">
                                                                        <span className="ball" />
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="ammount-items">
                                                                <form action="#">
                                                                    <input type="number" placeholder="Bet Amount" />
                                                                    <button type="submit">
                                                                        Max
                                                                    </button>
                                                                </form>
                                                            </div>
                                                            <div className="possible-win">
                                                                <span>Possible win</span>
                                                                <span className="amount">$300</span>
                                                            </div>
                                                            <div className="combo-footer">
                                                                <a href="#0" className="cmn--btn">
                                                                    <span> Place Bet $300</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade show active" id="bbetsmobile" role="tabpanel">
                                                    <div className="combo-wrapper">
                                                        <div className="close-box">
                                                            <div className="close-items">
                                                                <div className="close-head">
                                                                    <span>Scotland vs Ukraine</span>
                                                                    <span className="close">
                                                                        <i className="fas fa-xmark" />
                                                                    </span>
                                                                </div>
                                                                <div className="match-fixing">
                                                                    <div className="match-items">
                                                                        <div className="match-items-left">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                            </div>
                                                                            <div className="cont">
                                                                                <span>Scotland</span>
                                                                                <span className="winner">Match Winner</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>3.20</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="match-items">
                                                                        <div className="match-items-left">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                            </div>
                                                                            <div className="cont">
                                                                                <span>Draw</span>
                                                                                <span className="winner">Match Winner</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>4.60</span>
                                                                        </div>
                                                                    </div>
                                                                    <p>
                                                                        Add 2 more outcome(s) with odds greater than 1.4 to get x1.08
                                                                    </p>
                                                                    <div className="match-items match-progress">
                                                                        <div className="match-items-left">
                                                                            <div className="prabox-wrap">
                                                                                <div className="terminal-bar">
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                </div>
                                                                                <div className="bar-count">
                                                                                    <p>x1.08</p>
                                                                                    <p>x1,15</p>
                                                                                    <p>x1.20</p>
                                                                                    <p>x1.50</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>1.00</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="match-items match-odds">
                                                                        <div className="match-items-left">
                                                                            <div className="cont">
                                                                                <span>Total odds</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>12.19</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="combo-switch">
                                                            <span>
                                                                Accept all odds changes
                                                            </span>
                                                            <label className="switch-com">
                                                                <input type="checkbox" className="checkbox" hidden />
                                                                <span className="checkbox-label">
                                                                    <span className="ball" />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="ammount-items">
                                                            <form action="#">
                                                                <input type="number" placeholder="Bet Amount" />
                                                                <button type="submit">
                                                                    Max
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="possible-win">
                                                            <span>Possible win</span>
                                                            <span className="amount">$300</span>
                                                        </div>
                                                        <div className="combo-footer">
                                                            <a href="#0" className="cmn--btn">
                                                                <span> Place Bet $300</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-box">
                                            <div className="accordion" id="accordionExamplemy">
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingThree03">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            <span className="icon"><i className="fa-sharp fa-solid fa-gear" /></span>
                                                            <span>Settings</span>
                                                        </button>
                                                        <div id="collapseThreethree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <a href="#0" className="condition-box">
                                                                    Terms &amp; Conditions
                                                                </a>
                                                                <div className="select-wrap">
                                                                    <div className="select-one">
                                                                        <select className="select" name="select" id="n-select2">
                                                                            <option value={1}>Lunguage</option>
                                                                            <option value={1}>English</option>
                                                                            <option value={1}>Spanish</option>
                                                                        </select>
                                                                        <span className="add">English</span>
                                                                    </div>
                                                                    <div className="select-two">
                                                                        <select className="select" name="select" id="s-select2">
                                                                            <option value={1}>Odds</option>
                                                                            <option value={1}>Decimal</option>
                                                                            <option value={1}>Decimal Odds</option>
                                                                        </select>
                                                                        <span className="add">Decimal</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popular-bets">
                                        <div className="head">
                                            <h6>
                                                Popular bets
                                            </h6>
                                        </div>
                                        <ul className="popoular-body">
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault4" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Prance
                                                        </span>
                                                        <span className="text3">
                                                            France - Austria
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        2.37
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault5" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Turkey
                                                        </span>
                                                        <span className="text3">
                                                            Turkey - Luxembourg
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        1.37
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault6" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Alkmaar
                                                        </span>
                                                        <span className="text3">
                                                            Alkmaar - Apollon
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        3.47
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile