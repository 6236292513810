import api from "../apis/api"

const getToken = data => {
    return api.LoginInstance.post("/Auth/RRRV2", data);
};
const getGCatsData = () => {
    return api.instance.post("/game/GetGCatsData");
};
const GetFEventsByGCat = data => {
    return api.instance.post("/game/GetFEventsByGCat", data);
};

const GetFLMarkets = data => {
    return api.instance.post("/game/GetFLMarkets", data);
};

const GetFLMarketsAnony = data => {
    return api.instance.post("/game/GetFLMarketsAnony", data);
};

const GetLMarketDetails = data => {
    return api.instance.post("/game/GetLMarketDetails", data);
};
const AddBet = data => {
    return api.instance.post("/bet/AddBet", data);
};
const GetBets = data => {
    return api.instance.post("/bet/GetBetHistory", data);
};
const GetBanners = () => {
    return api.instance.post("Banner/GetBanners")
}
const GetStakeButtons = data => {
    return api.instance.post("StakeButton/GetStakeButtons", data)
}
const UpdateSButtons = data => {
    return api.instance.post("StakeButton/UpdateSButtons", data)
}
const GetMNotifications = data => {
    return api.instance.post("Game/GetMNotifications", data)
}

export default {
    getGCatsData, getToken, UpdateSButtons, GetBanners, GetFEventsByGCat, GetFLMarkets, GetMNotifications,
    GetLMarketDetails, AddBet, GetBets, GetStakeButtons, GetFLMarketsAnony
};