import React, { useEffect, useState, useRef } from 'react'
import Footer from '../components/Footer'
import ApiService from "../apis/ApiService";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import ProtoTypes from '../utilties/ProtoTypes';
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { useOutletContext } from "react-router-dom";



Number.prototype.round = function (decimals) {
    return Number((Math.round(this + "e" + decimals) + "e-" + decimals));
}

const AccountStatement = () => {
    const SPORTSID = useRef(1);
    const [shownetPL, setshownetPL] = useState(true)
    const [resultcommisionshow, resultsetcommisionshow] = useState(true)
    const [showMoNation, setShowMoNation] = useState(false)
    const [dtt, setdtt] = useState()
    const [breakenationarray, setbreakenationarray] = useState()
    const [winner, setWinner] = useState();
    const [PL, SETPL] = useState();
    const [COM, SETCOM] = useState()
    const [totalCount, setTotalCount] = useState([]);
    const [itemsPerPage, setitemsPerPage] = useState(100);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [RECORD, SETRECORD] = useState([]);
    const [mktid, setmktid] = useState('');
    const [bthistory, setbthistory] = useState([]);
    const [Loader, setLoader] = useState(false);
    const [showtableError, settableError] = useState(false);
    const [showBetstable, setShowBetstable] = useState(false);
    const [transid, setTransid] = useState(0);
    const [ntPl, setnetPl] = useState()
    const [commisionshow, setcommisionshow] = useState(false)
    const [Plshow, setplshow] = useState(false)
    const { balInfo, setBalInfo } = useOutletContext();
    const [BetDetails, SetBetDetails] = useState({
        MarketId: "",
        IsCancelled: false,
        Side: null
    });


    let currentD = new Date();
    currentD.setDate(currentD.getDate() - 30);
    let d = currentD.getDate();
    let mon = currentD.getMonth() + 1;
    let ye = currentD.getFullYear();
    let onemonthDate = `${d}-${mon}-${ye}`;
    let monthend = onemonthDate.split("-").reverse().join("-")
    let fnldt = formatDate(monthend)



    let currentDt = new Date();
    currentDt.setDate(currentDt.getDate());
    let dt = currentDt.getDate();
    let mont = currentDt.getMonth() + 1;
    let yer = currentDt.getFullYear();
    let tdDate = `${dt}-${mont}-${yer}`;
    let curday = tdDate.split("-").reverse().join("-")
    let fnlcurday = formatDate(curday)

    const [minDate, setMinDate] = useState(fnldt)
    const [maxDate, setMaxDate] = useState(fnlcurday)

    const [minMaxDate, setMinMaxDate] = useState({
        minFrom: "",
        maxFrom: "",
        minTo: "",
        maxTo: ""
    })

    var lastweekDate = new Date();
    lastweekDate.setDate(lastweekDate.getDate());
    var upcomingweek = new Date()
    upcomingweek.setDate(upcomingweek.getDate());

    const [filterData, setFilterData] = useState({
        FromDate: lastweekDate.toJSON().split('T')[0],
        ToDate: upcomingweek.toJSON().split('T')[0],
    })


    useEffect(() => {
        async function callAyncFunction() {
            if (balInfo) {
                await onSearch(transid);
                setBalInfo(false)
            }
        }
        callAyncFunction();

    }, [balInfo])

    useEffect(() => {
        async function callAyncFunction() {
            await onSearch(transid);
        }
        callAyncFunction();

    }, [])


    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(RECORD.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(RECORD.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, RECORD]);

    const handlePageClick = (e) => {
        var select = e.selected
        const newOffset = select * itemsPerPage % RECORD.length;
        setItemOffset(newOffset);
    };

    let index = (itemOffset);

    const setpagesizedata = (e) => {
        const liEelment = document.querySelector('[aria-label="Page 1"]');
        if (liEelment) {
            liEelment.click();
        }
        setitemsPerPage(parseInt(e.value))

    }

    useEffect(() => {
        if (mktid)
            GetMarketUserBets(mktid)
        handleCustomDate()
    }, [BetDetails.Side, BetDetails.IsCancelled,])


    const handleCustomDate = (e) => {
        let selected_date = new Date();
        let d = selected_date.getDate();
        let mon = selected_date.getMonth() + 1;
        let ye = selected_date.getFullYear();
        let _Date_ = `${d}-${mon}-${ye}`;
        _Date_ = formatDate(_Date_.split("-").reverse().join("-"));

        let yearmin = selected_date
        yearmin.setFullYear(selected_date.getFullYear() - 1);
        let min_Date_ = `${d}-${mon}-${yearmin.getFullYear()}`;
        min_Date_ = formatDate(min_Date_.split("-").reverse().join("-"));

        setMinMaxDate({ ...minMaxDate, maxFrom: _Date_, minFrom: min_Date_, maxTo: _Date_, minTo: getMinMaxDate(_Date_, "min") });

        setFilterData({ ...filterData, ToDate: _Date_, FromDate: _Date_ });

    }


    const handleAll = (e) => {
        if (e.target.value) {
            var result = e.target.value;
            if (e.target.value == 'null')
                result = null
            SetBetDetails({ ...BetDetails, Side: result });
        }
    }

    const handleMatched = (e) => {
        if (e.target.value) {
            var matchdata = e.target.value;
            var convertdata = JSON.parse(matchdata)
            SetBetDetails({ ...BetDetails, IsCancelled: convertdata });
        }
    }

    // const handleInput = (e) => {
    //     const name = e.target.name;
    //     const value = e.target.value;
    //     setFilterData({ ...filterData, [name]: value, });
    // }


    const handleDateInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (value) {

            let min_date;
            let max_date;

            if (name === "FromDate") {
                min_date = value;
                max_date = getMinMaxDate(value, "max");
                if (checkMaxdateToday(max_date)) {
                    let selected_date = new Date();
                    let d = selected_date.getDate();
                    let mon = selected_date.getMonth() + 1;
                    let ye = selected_date.getFullYear();
                    let _Date_ = `${d}-${mon}-${ye}`;
                    max_date = formatDate(_Date_.split("-").reverse().join("-"))
                }
                setMinMaxDate({ ...minMaxDate, maxTo: max_date, minTo: min_date });
                setFilterData({ ...filterData, [name]: value, ["ToDate"]: value });
            }
            else {
                setFilterData({ ...filterData, [name]: value });
            }
        }
    }

    function checkMaxdateToday(dateStr) {

        const selectedDate = new Date(dateStr);
        const todayDate = new Date();

        return Date.parse(selectedDate) > Date.parse(todayDate)

    }

    function formatDate(date) {
        var date2 = date.split('-');
        var month = date2[1];
        var day = date2[2];
        var year = date2[0];
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');

    }

    function getMinMaxDate(date, typ) {

        let selected_date = new Date(date);
        let _Date_;
        if (typ == "min") {
            selected_date.setDate(selected_date.getDate() - 30);
        }
        else if (typ == "max") {
            selected_date.setDate(selected_date.getDate() + 30);
        }
        let d = selected_date.getDate();
        let mon = selected_date.getMonth() + 1;
        let ye = selected_date.getFullYear();
        _Date_ = `${d}-${mon}-${ye}`;
        _Date_ = formatDate(_Date_.split("-").reverse().join("-"))

        return _Date_
    }

    const GetMarketUserBets = async (marketId) => {
        try {
            var betdata = {
                MarketId: marketId,
                Side: BetDetails.Side,
                IsCancelled: BetDetails.IsCancelled,
            }
            if (marketId !== null && marketId !== "") {
                await ApiService.GetMarketUserBets(betdata).then(res => {
                    if (res) {
                        const records = res.data.result
                        setbthistory(records)
                        const totlcountlnth = res.data.result.length
                        var totalntpl = 0
                        var isCom = false
                        for (var i = 0; i < records.length; i++) {
                            totalntpl += (records[i].pl + records[i].commision);
                        }
                        var totalcm = 0
                        for (var i = 0; i < records.length; i++) {
                            totalcm += records[i].commision;
                        }
                        var rr = res.data.result
                        for (let index = 0; index < rr.length; index++) {
                            if (rr[index].commision > 0 || rr[index].commision < 0) {
                                isCom = true
                            }
                        }
                        var totalpl = 0
                        for (var i = 0; i < records.length; i++) {
                            totalpl += records[i].pl;
                        }
                        setnetPl(totalntpl)
                        setTotalCount(totlcountlnth)
                        SETCOM(totalcm)
                        SETPL(totalpl)
                        resultsetcommisionshow(isCom)
                        setshownetPL(isCom)
                    }
                    if (res.data.result.length == 0) {
                        setShowBetstable(true)
                    } else {
                        setShowBetstable(false)
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
        } catch (error) {
            console.log(error)
        }
    }


    const SelectedRowData = (history) => {
        if (SPORTSID.current == 1) {
            return
        }
        SetBetDetails({ ...BetDetails, Side: null, IsCancelled: false })
        if (history) {
            var recordhistory = history.remarks
            var historyData = recordhistory.split("/");
            let WinnerName = historyData.pop();
            var dttime = (history.transDate.replace('T', ' ').split('.')[0] + '')
            setdtt(dttime)
            if (WinnerName == Number(WinnerName))
                setWinner(WinnerName.split(".")[0])
            else
                setWinner(WinnerName)

            var test = recordhistory.replaceAll("/", " > ")
            var newStr = test.split(" > ").slice(0, -1).join(" > ");
            setbreakenationarray(newStr)
            setmktid(history.marketId)
            GetMarketUserBets(history.marketId)

            if (historyData[2] == " Bookmaker" || historyData[2] == " Match Odds")
                setShowMoNation(true)
            else
                setShowMoNation(false)
        }

    }

    const onSearch = async (e) => {
        setLoader(true)
        var sid = SPORTSID.current
        try {
            var accountdata = {
                TransType: sid,
                FromDate: ProtoTypes.IstToUtc(filterData.FromDate),
                ToDate: ProtoTypes.IstToUtc(filterData.ToDate),
            }
            await ApiService.GetAccountHistoryDetails(accountdata).then(res => {
                if (res) {
                    setLoader(false)
                    const recorddata = res.data.result
                    SETRECORD(recorddata)
                } if (res.data.result.length == 0) {
                    settableError(true)
                } else {
                    settableError(false)
                }
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error)
        }
    }

    const option = [
        { value: '1', label: 'Deposit/Withdraw' },
        { value: '3', label: 'Sports' }
    ]

    const SportsTypeDropDown = (e) => {
        const gamevalue = parseInt(e.value)
        SPORTSID.current = gamevalue
        setTransid(gamevalue)
        onSearch(gamevalue)
        const liEelment = document.querySelector('[aria-label="Page 1"]');
        if (liEelment) {
            liEelment.click();
        }
        if (gamevalue == 1) {
            setcommisionshow(false)
            setplshow(false)
        } else {
            setcommisionshow(true)
            setplshow(true)
        }
    }

    const perPagedata = [
        { value: "100", label: "100" },
        { value: "200", label: "200" },
        { value: "300", label: "300" },
        { value: "500", label: "500" },
    ]

    const passaccountReportData = () => {
        var username = ""
        var usrn = document.getElementById("usernm")
        if (usrn)
            username = usrn.innerHTML;
        window.createAccountDetailReport(RECORD, itemOffset, filterData, username, transid)
    }

    return (
        <>
            {/* Loader */}
            {Loader && <div className="boxes-bg">
                <div className="boxes">
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>}

            <div className="body-middle">
                {/*Breadcumnd*/}
                <div className="breadcumnd-banner">
                    <div className="container">
                        <div className="breadcumd-content">
                            <h1>
                                Account Statement
                            </h1>
                        </div>
                    </div>
                </div>

                {/* Date Filter */}

                <div className="profile-section pb-60">
                    <div className="container">
                        <div className="tab-content ob-content">
                            <div className="row ">
                                <div className=" col-sm-12 filterrecord form-grp d-sm-flex">
                                    <Grid item xs={12} sm={6} md={2} sx={{ pr: 3, }} >
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                size='small'
                                                name="FromDate"
                                                type="date"
                                                inputProps={{ max: minMaxDate.maxFrom, min: minMaxDate.minFrom }}
                                                onKeyDown={(e) => e.preventDefault()}
                                                value={filterData.FromDate}
                                                onChange={handleDateInput}
                                                aria-describedby='validation-schema-EventDate'
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2} sx={{ pr: 3, marginTop: { md: 0, sm: '0', xs: '10px' } }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <TextField
                                                size='small'
                                                name="ToDate"
                                                type="date"
                                                inputProps={{ max: minMaxDate.maxTo, min: minMaxDate.minTo }}
                                                value={filterData.ToDate}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onChange={handleDateInput}
                                                aria-describedby='validation-schema-EventDate'
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* <div className=" col-sm-6 col-md-6 col-lg-4 DT">
                                        <div className="col-sm-6 col-xs-6 gift-card fm pe-2">
                                            <input
                                                className='filtrdate'
                                                type="date"
                                                name="FromDate"
                                                min={minMaxDate.minFrom}
                                                max={minMaxDate.maxFrom}
                                                onKeyDown={(e) => e.preventDefault()}
                                                value={filterData.FromDate}
                                                onChange={handleDateInput} />
                                        </div>

                                        <div className='col-sm-6 col-xs-6  gift-card to pe-2 '>
                                            <input
                                                className='filtrdate'
                                                type="date"
                                                name="ToDate"
                                                min={minMaxDate.minFrom}
                                                max={minMaxDate.maxFrom}
                                                onKeyDown={(e) => e.preventDefault()}
                                                value={filterData.ToDate}
                                                onChange={handleDateInput} />
                                        </div>
                                    </div> */}

                                    <div className='col-sm-3 col-md-4 col-lg-3 pag'>
                                        <div className='gift-wrap pe-2'>
                                            <Select defaultValue={option[0]} onChange={e => SportsTypeDropDown(e)} options={option}></Select>
                                        </div>
                                    </div>
                                    <div className='col-sm-3 col-md-2'>
                                        <a onClick={e => onSearch(transid)} className="cmn--btn" style={{ cursor: "pointer" }}>
                                            <span >Search</span>
                                        </a>
                                    </div>
                                </div>

                                {/* Pagination */}

                                <div className="row flex mt-3 pe-0">

                                    <div className="col-sm-2 col-md-2 col-lg-1  pe-0 accStat">
                                        <Select defaultValue={perPagedata[1]} onChange={e => setpagesizedata(e)} options={perPagedata}></Select>
                                    </div>
                                    <div className="col-sm-6 col-md-7 col-lg-8 text-md-end text-center xsmy-2">
                                        <ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            breakLabel={".."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={e => handlePageClick(e)}
                                            containerClassName={"pagination"}
                                            activeClassName={"active"}
                                        />

                                    </div>
                                    <div className="col-sm-3 col-md-3  pe-0">
                                        <a onClick={passaccountReportData} className="cmn--btn" style={{ cursor: "pointer" }}>
                                            <span style={{ whiteSpace: "nowrap" }}>Download Report</span>
                                        </a>
                                    </div>
                                </div>

                                {/* Main Table */}

                                <div className="tab-content mt-height">
                                    <div className="description-table ob-table padTable8">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{ textAlign: "right" }}>SrNo.</th>
                                                    <th style={{ textAlign: "left" }}>Date</th>
                                                    {Plshow && <th style={{ textAlign: "right" }}>PL</th>}
                                                    {commisionshow && <th style={{ textAlign: "right" }}>Commission</th>}
                                                    <th style={{ textAlign: "right" }}>Credit</th>
                                                    <th style={{ textAlign: "right" }}>Debit</th>
                                                    <th style={{ textAlign: "right" }}>Balance</th>
                                                    <th style={{ textAlign: "left" }}>Remarks</th>
                                                </tr>
                                                {currentItems && currentItems.map((history, indexx) => {
                                                    if (history.marketId !== null && history.marketId !== "" && SPORTSID.current == 3) {
                                                        return (
                                                            <tr key={indexx} className="tb1" id={"#exampleModal4"} data-bs-toggle="modal" data-bs-target={"#exampleModal4"} onClick={e => SelectedRowData(history)} style={{ cursor: "pointer" }}>
                                                                <td style={{ textAlign: "right" }}>{++index}</td>
                                                                <td style={{ textAlign: "left" }}>{ProtoTypes.setLocalDate(history.transDate)}</td>
                                                                {Plshow && <td style={{ textAlign: "right" }} className={history.pl >= 0 ? "tbgreen" : "tbred"}>{ProtoTypes.Commas(history.pl)}</td>}
                                                                {commisionshow && <td style={{ textAlign: "right" }}>{ProtoTypes.Commas(history.commision)}</td>}
                                                                <td style={{ textAlign: "right" }} className={history.creditAmount >= 0 ? "tbgreen" : "tbred"}>{history.creditAmount > 0 && ProtoTypes.Commas(history.creditAmount)}</td>
                                                                <td style={{ textAlign: "right" }} className={history.creditAmount >= 0 ? "tbgreen" : "tbred"}>{history.creditAmount < 0 && ProtoTypes.Commas(history.creditAmount)}</td>
                                                                <td style={{ textAlign: "right" }} className={history.points >= 0 ? "tbgreen" : "tbred"}>{ProtoTypes.Commas(history.points)}</td>
                                                                <td style={{ textAlign: "left", whiteSpace: "nowrap" }}>{history.remarks}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <tr key={indexx} className="tb1" style={{ cursor: "pointer" }}>
                                                                <td style={{ textAlign: "right" }}>{++index}</td>
                                                                <td style={{ textAlign: "left" }}>{ProtoTypes.setLocalDate(history.transDate)}</td>
                                                                {Plshow && <td style={{ textAlign: "right" }}>{ProtoTypes.Commas(history.pl)}</td>}
                                                                {commisionshow && <td style={{ textAlign: "right" }}> {ProtoTypes.Commas(history.commision)}</td>}
                                                                <td style={{ textAlign: "right" }}>{history.creditAmount > 0 && ProtoTypes.Commas(history.creditAmount)}</td>
                                                                <td style={{ textAlign: "right" }}>{history.creditAmount < 0 && ProtoTypes.Commas(history.creditAmount)}</td>
                                                                <td style={{ textAlign: "right" }} >{ProtoTypes.Commas(history.points)}</td>
                                                                <td style={{ textAlign: "left" }}>{history.remarks}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                        {showtableError && (
                                            <div className="errorshow">
                                                <span>No Data Found!</span>
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*PopUp Table */}

                <div className="modal" id="exampleModal4" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content betcontent">
                            <div className="modal-header betdata">
                                <div className="head betrecord">
                                    <h5 className='expoaligntext'>
                                        Result
                                    </h5>
                                </div>
                                <div className='btnlft'>
                                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                                </div>
                            </div>

                            <div className="modal-body">
                                <div className="tab-content ob-content">
                                    <div className="row justify-content-between">
                                        <div className='nationdata'>
                                            <div className='nationn'>{breakenationarray}
                                                <span>Game Time: {ProtoTypes.setLocalDate(dtt)}</span>
                                            </div>
                                            <span style={{ fontWeight: "bold" }}>Winner: {winner}</span>
                                        </div>

                                        <div className="wallet-tab-wrap">
                                            <div className='gift-card PL'>
                                                <label><input type="radio" style={{ cursor: "pointer" }} name="fav_language" checked={BetDetails.IsCancelled == false} value="false" onChange={e => handleMatched(e)} /> Matched</label>
                                                <label><input type="radio" style={{ cursor: "pointer" }} name="fav_language" checked={BetDetails.IsCancelled == true} value="true" onChange={e => handleMatched(e)} /> Deleted</label>
                                            </div>
                                            <div className='gift-card PL'>
                                                <label><input type="radio" style={{ cursor: "pointer" }} name="fav_languag" checked={BetDetails.Side == null} value="null" onChange={e => handleAll(e)} /> All</label>
                                                <label><input type="radio" style={{ cursor: "pointer" }} name="fav_languag" checked={BetDetails.Side == "B"} value="B" onChange={e => handleAll(e)} /> Back</label>
                                                <label><input type="radio" style={{ cursor: "pointer" }} name="fav_languag" checked={BetDetails.Side == "L"} value="L" onChange={e => handleAll(e)} /> Lay</label>
                                            </div>
                                            <div className="gift-card PL">
                                                <div className="left-cart">
                                                    <div>PL</div>
                                                    <div className={PL >= 0 ? "tbgreen" : "tbred"}>{Number(PL).toFixed(2)}</div>
                                                </div>
                                            </div>

                                            {resultcommisionshow &&
                                                <div className="gift-card PL">
                                                    <div className="left-cart">
                                                        <div>Com.</div>
                                                        <div className={COM >= 0 ? "tbgreen" : "tbred"}>{COM && COM.round(2)}</div>
                                                    </div>
                                                </div>
                                            }
                                            {shownetPL &&
                                                <div className="gift-card PL">
                                                    <div className="left-cart">
                                                        <div>NetPL</div>
                                                        <div className={ntPl >= 0 ? "tbgreen" : "tbred"}>{ntPl && ntPl.round(2)}</div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="gift-card PL">
                                                <div className="left-cart">
                                                    <div>Count</div>
                                                    <div className={totalCount >= 0 ? "tbgreen" : "tbred"}>{totalCount}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="description-table ob-table">
                                            <table >
                                                <tbody>
                                                    <tr className="tb1">
                                                        {showMoNation && <th style={{ textAlign: "left" }}>Nation</th>}
                                                        <th style={{ textAlign: "left" }}>Side</th>
                                                        <th style={{ textAlign: "right" }}>Price</th>
                                                        <th style={{ textAlign: "right" }}>Odds</th>
                                                        <th style={{ textAlign: "right" }}>Amount</th>
                                                        <th style={{ textAlign: "right" }}>PL</th>
                                                        {resultcommisionshow && <th style={{ textAlign: "right" }}>Commission</th>}
                                                        {shownetPL && <th style={{ textAlign: "right" }}>NetPL</th>}
                                                        <th style={{ textAlign: "left" }}>Date</th>
                                                        <th style={{ textAlign: "right" }}>IpAddress</th>
                                                    </tr>
                                                    {bthistory.map((history, indexx) => {
                                                        return (
                                                            <tr key={indexx}>
                                                                {showMoNation && <td style={{ textAlign: "left" }}>{history.marketName}</td>}
                                                                <td style={{ textAlign: "left" }}><div className={history.side == 'B' ? "tbsec" : "tlsec"}>{history.side == 'B' ? 'Back' : 'Lay'}</div></td>
                                                                <td style={{ textAlign: "right" }}>{history.price}</td>
                                                                <td style={{ textAlign: "right" }}>{history.odds}</td>
                                                                <td style={{ textAlign: "right" }}>{history.amount.toLocaleString()}</td>
                                                                <td style={{ textAlign: "right" }} className={history.pl > 0 ? "tbgreen" : "tbred"}>{ProtoTypes.Commas(history.pl)}</td>
                                                                {resultcommisionshow && <td style={{ textAlign: "right" }}>{ProtoTypes.Commas(history.commision)}</td>}
                                                                {shownetPL && <td style={{ textAlign: "right" }} className={history.commision + history.pl >= 0 ? "tbgreen" : "tbred"}>{ProtoTypes.Commas(history.commision + history.pl)}</td>}
                                                                <td style={{ textAlign: "left" }}>{ProtoTypes.setLocalDate(history.eventDate)}</td>
                                                                <td style={{ cursor: "pointer", textAlign: "right" }} title={history.browser}>{history.ipAddress}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {showBetstable && (
                                                <div className="errorshow">
                                                    <span>No Data Found!</span>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default AccountStatement
