import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import ApiService from "../apis/ApiService";
import ProtoTypes from '../utilties/ProtoTypes';
import error from '../utilties/error';
import { ToastContainer } from 'react-toastify';
import UserService from "../apis/UserService";
import OtpInput from 'react-otp-input';
import axios from "axios";

Number.prototype.round = function (decimals) {
  return Number((Math.round(this + "e" + decimals) + "e-" + decimals));
}

const Topbar = ({ data, stakeFunction, st, loginFunction, bal }) => {

  const navigate = useNavigate();
  const [showOtp, setshowOtp] = useState(false)
  const [otp, setOtp] = useState('');
  const [temptoken, setTemptoken] = useState('');
  const [expoSummary, setExpoSummary] = useState([])
  const [balanceInfo, setBalanceInfo] = useState()
  const [transtype, settranstype] = useState()
  const [user, setUser] = useState([])
  const [showtableError, settableError] = useState(false)
  const [betError, setbetError] = useState(false)
  const [stakedetails, setStakedetails] = useState([])
  const [Loader, setLoader] = useState(false)
  const [allTempError, setAllTempError] = useState("");
  const [hideBalanceSection, sethideBalanceSection] = useState(false)
  const [showLoginButton, setshowLoginButton] = useState(false)
  const interval = useRef();
  const isLongPull = useRef(true);
  const [loginLoader, setLoginLoader] = useState(false)
  const [logindiv, setlogidiv] = useState(true)
  const curBalance = useRef()

  const [userLoginData, setUserLoginData] = useState({
    grant_type: "password",
    username: "",
    password: "",
  });

  const [loginErrors, setLoginErrors] = useState({
    loginNameError: "",
    loginPasswordError: "",
    loginResError: "",
  });

  const validateLogin = () => {
    if (userLoginData.username === "") {
      setLoginErrors({ loginNameError: "Username is required!" });
    } else if (userLoginData.password === "") {
      setLoginErrors({ loginPasswordError: "Password is required!" });
    } else {
      return true;
    }
  };

  const handleLoginInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserLoginData({
      ...userLoginData,
      [name]: value,
    });
  };

  const [userPasswordData, setUserPasswordData] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });

  const [errors, setErrors] = useState({
    oldPasswordError: "",
    newpasswordError: "",
    confirmPasswordError: "",
    matcherror: "",
    resError: "",
    sucessfullmsg: "",
  });

  useEffect(() => {
    async function calAyncFunction() {
      let token = localStorage.getItem('up_token')
      if (token) {
        isLongPull.current = true;
        await GetBalancesList()
      } else {
        setshowLoginButton(true)
        loginFunction(true)
        setlogidiv(false)
      }
    }
    calAyncFunction();
    return () => {
      isLongPull.current = false;
      clearTimeout(interval.current);
    }
  }, []);

  const getTokenBy2FA = async () => {
    try {
      // setLoader(true)
      setLoginLoader(true)
      var res = await axios.post(`${process.env.REACT_APP_UP_ENDPOINT}/Auth/RRR2FA`, {
        G2FAKey: otp,
      }, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + temptoken
        }
      })
      if (res.data.status == "Success") {
        setLoginLoader(false)
        window.localStorage.setItem("up_token", res.data.token);
        window.location.reload();
      } else {
        setLoginLoader(false)
        setOtp("")
        setLoginErrors({ loginResError: res.data });
      }
    } catch (error) {
      setLoginLoader(false)
      console.log(error)
    }
  }

  // LongPool Api//
  const GetBalancesList = async () => {
    try {
      await ApiService.GetBalances().then(res => {
        if (res && res?.data?.responseCode) {
          var msg = res.data.response;
          if (msg == "Success") {
            const user = res.data.result.userName
            setUser(user)
            var balancerecord = res.data.result.userBalances
            const findbalance = balancerecord.find(amount => {
              return amount.transType === 'Credit';
            });
            setBalanceInfo(findbalance.amount)
            var transrecord = res.data.result.userBalances
            const findtranstype = transrecord.find(amount => {
              return amount.transType === 'Exposure';
            });
            settranstype(findtranstype.amount)
            if (isLongPull.current) {
              interval.current = setTimeout(async () => {
                await GetBalancesList();
              }, 1000);
            }

            sethideBalanceSection(true)
            setshowLoginButton(false)
            loginFunction(false)
            var bl = (findbalance.amount - findtranstype.amount);
            if (curBalance.current !== bl) {  
              //  debugger  
             bal(true)
            
              // setBalanceInfo(true)
            }
            curBalance.current = bl
          }
        }
      }).catch(error => {
        console.log(error);
      })
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserPasswordData({
      ...userPasswordData,
      [name]: value,
    });
  };

  const handleBetSlipValue = (e, index,) => {
    var inp = e.target.value
    if (inp != "")
      inp = parseInt(e.target.value)

    let data = [...stakedetails];
    data[index][e.target.name] = inp;
    setStakedetails(data)
  };

  const handleBetSlipLabel = (e, index) => {
    var inp = e.target.value;
    let data = [...stakedetails];
    data[index][e.target.name] = inp;
    setStakedetails(data)
  };

  const validate = () => {
    if (userPasswordData.oldpassword === "") {
      setErrors({ oldPasswordError: "Old Password is required!" });
    } else if (userPasswordData.newpassword === "") {
      setErrors({ newpasswordError: "New Password is required!" });
    }
    else if (userPasswordData.confirmpassword === "") {
      setErrors({ confirmPasswordError: "Confirm Password is required!" });
    }
    else if (userPasswordData.oldpassword == userPasswordData.newpassword) {
      setErrors({ newpasswordError: "Old Password and New Password cannot be same!" });
    } else if (userPasswordData.newpassword !== userPasswordData.confirmpassword) {
      setErrors({ matcherror: "Password does not match!" });
    }
    else {
      setErrors([])
      return true;

    }
  };

  const validateForm = (stakedetails, e) => {
    var isError = stakedetails.filter((s => s.label == "" || s.stakeValue == "" || s.stakeValue == 0))
    if (isError && isError.length > 0) {
      setAllTempError("Please fill all fields!")
      return false
    }
    else
      return true
  }

  const changePassword = async (e) => {
    try {
      e.preventDefault();
      if (validate()) {
        setLoader(true)
        var obj = {
          NewPassword: userPasswordData.newpassword,
          OldPassword: userPasswordData.oldpassword,
        }
        await ApiService.UpdateUPassword(obj).then(async (res) => {
          setLoader(false)
          if (res && res.data.responseCode) {
            var msg = res.data.response;
            if (msg == "Success") {
              setErrors({ sucessfullmsg: "Updated Password Sucessfully" })
              setUserPasswordData(res.data.response);
              window.localStorage.removeItem("up_token")
              const timer = setTimeout(() => {
                navigate("/")
              }, 1500);
              return () => clearTimeout(timer);

            } else {
              console.log(msg)
              setErrors({ resError: res.data.response });
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }

    } catch (error) {
      console.log(error)
    }
  }

  const resetModal = () => {
    setErrors([])
    setUserPasswordData({ oldpassword: "", newpassword: "", confirmpassword: "" })
  }

  const resetStake = () => {
    setAllTempError("")
    GetStakeButtons()
  }

  const GetExpoSummary = async () => {
    try {
      await ApiService.GetExpoSummary().then(res => {
        if (res) {
          setExpoSummary(res.data.result)
        } if (res.data.result.length == 0) {
          settableError(true)
        } else {
          settableError(false)
        }
      }).catch(err => {
        console.log(err);
      })
      setLoader(false)
    } catch (error) {
      console.log(error)
    }
  }

  const GetStakeButtons = async () => {
    try {
      var stakebuttondata = {
        getAll: false,
        SingleClicked: false
      }
      await ApiService.GetStakeButtons(stakebuttondata).then(async (res) => {
        if (res && res.data.responseCode) {
          var msg = res.data.response;
          if (msg == "Success") {
            var rs = res.data.result
            setStakedetails(rs)
          }
          else {
            error.errorToastify(msg)
          }
          if (res.data.result.length == 0) {
            setbetError(true)
          } else {
            setbetError(false)
          }
        }
      }).catch(error => {
        console.log(error)
      })

    } catch (error) {
      console.log(error)
    }

  }

  const betSlipSubmit = async (e) => {
    setAllTempError("")
    try {
      e.preventDefault();
      var requst = []
      for (let index = 0; index < stakedetails.length; index++) {
        const element = stakedetails[index];
        requst.push(element)
      }
      if (validateForm(requst)) {
        var finalvalue = { Buttons: requst }
        checkEmptyClasss()
        await ApiService.UpdateSButtons(finalvalue).then(async (res) => {
          if (res && res.data.responseCode) {
            var msg = res.data.response;
            if (msg == "Success") {
              stakeFunction(!st)
              const liEelment = document.getElementsByClassName('stakePopup');
              if (liEelment) {
                liEelment[0].click();
              }
              error.successToastify(msg);
            } else {
              console.log(msg)
              error.errorToastify(res.data.response)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }

    } catch (error) {
      console.log(error)
    }
  }

  const accountdetailpage = () => {
    navigate("/accountstatement")
  }

  const loginhistorypage = () => {
    navigate("/loginhistory")
  }

  const bethistorypage = () => {
    navigate("/openhistory")
  }

  const handleSubmitLogin = async (e) => {
    try {
      e.preventDefault();
      if (validateLogin()) {
        setLoginLoader(true)
        await UserService.getToken(userLoginData).then(async (res) => {
          setshowOtp(false)
          if (res) {
            var sts = res.data.status;
            if (sts == "Success") {
              setLoginLoader(false)
              setUserLoginData({ ...userLoginData, password: "", username: "" });
              window.localStorage.setItem("up_token", res.data.token);
              window.location.reload();
            }
            else if (sts == "Force Change Password") {
              setLoginLoader(false)
              window.localStorage.setItem('up_cp_token', res.data.token)
              navigate("/confirmpassword")
              return
            }
            else if (sts == "2FA Enabled") {
              setLoginLoader(false)
              setTemptoken(res.data.token)
              setshowOtp(true)
            }
            else {
              setLoginErrors({ loginResError: res.data });
              setLoginLoader(false)
            }
          }
        }).catch(error => {
          setLoginLoader(false)
          console.log(error)
        })
      }
    } catch (error) {
      setLoginLoader(false)
      console.log(error)
    }
  }

  const handleLogout = () => {
    setlogidiv(false)
    setshowLoginButton(true)
    loginFunction(true)
    sethideBalanceSection(false)
    localStorage.removeItem("up_token")
    navigate("/")
  }

  const auth2fa = () => {
    navigate("/auth2fa")
  }

  const handleExpoApi = () => {
    GetExpoSummary()
  }

  const TotalExposureAmount = (Totalcounts) => {
    var expoSum = 0;
    for (var j = 0; j < Totalcounts.length; j++) {
      expoSum += Totalcounts[j].exposure;
    }
    if (expoSum == 0)
      return null;
    return Number(expoSum).toFixed(2)
  }

  const TotalCommisionAmount = (Totalcounts) => {
    var expoSum = 0;
    for (var j = 0; j < Totalcounts.length; j++) {
      expoSum += Totalcounts[j].commision;
    }
    if (expoSum == 0)
      return 0;
    return Number(expoSum).round(2)
  }

  const checkEmptyClasss = () => {
    const collection = document.getElementsByClassName("odin");
    for (let i = 0; i < collection.length; i++) {
      var isEmpty = collection[i].value
      if (isEmpty == "" || isEmpty == 0) {
        collection[i].style.border = "1px solid red";
        collection[i].style.borderRadius = "10px";
      }

      else
        collection[i].style.border = "none";
    }
  }

  const handleLoginData = () => {
    setLoginLoader(false)
    setshowOtp(false)
    setLoginErrors("")
    setUserLoginData({
      username: "",
      password: "",
    });

  }

  return (
    <>
      {/* Loader */}
      {Loader && <div className="boxes-bg">
        <div className="boxes">
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>}

      {/* Header Section */}
      <header className="header-section header-hidden">
        <div className="header-wrapper">

          <div className="menu-logo-adjust d-flex align-items-center">
            <div className="logo-menu me-5">
              <a href="" className="logo">
                <Link to="/" className="">
                  <span className="logoT text-base d-sm-none"><img src="assets/img/logo/light-sub-logo.svg"></img></span>
                  <span className="logoT text-base d-none d-sm-block"><img src="assets/img/logo/PilotExchange-logo-light.svg"></img></span>
                </Link>
              </a>
              <a href="" className="dark-logo">
                <Link to="/" className="">
                  <span className="logoT text-base d-sm-none"><img src="assets/img/logo/dark-sub-logo.svg"></img></span>
                  <span className="logoT text-base  d-none d-sm-block"><img src="assets/img/logo/PilotExchange-logo-dark.svg"></img></span>
                </Link>
              </a>
            </div>
            <ul className="main-menu" >
              <li className="">
                <Link to="/" className="">Home</Link>
              </li>
              <li>
                <Link to="/" className="">Live</Link>
              </li>
              <li>
                <Link to="/" className="">About Us</Link>
              </li>
              <li>
                <Link to="/" className="">Contact Us</Link>
              </li>

              <li style={{
                visibility: "hidden"
              }}>
                <Link to="/profile" className="">Promotions</Link>
              </li>
              <li className="menu--btn">
                <a href="#0" className="btn--two" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <span>Log In</span>
                </a>
              </li>
              <li className="menu--btn">
                <a href="#0" className="cmn--btn" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                  <span>Sign Up</span>
                </a>
              </li>

            </ul>


          </div>



          <div className="right-head">
            <div className="mode--toggle">
              <img src="assets/img/sun.png" />
            </div>
            <div className="andy12">
              <div className="signup-area">
                {showLoginButton &&
                  <Link className="btn--two" id="btnLoginHome" data-bs-toggle="modal" data-bs-target="#exampleModalogin" onClick={e => handleLoginData()}>
                    <span>Log In</span>
                  </Link>}
              </div>


              {logindiv &&
                <div className="Sand12">
                  {hideBalanceSection && <div className="BEx">
                    <div className="blance-items">
                      <div className="leftt-blance">
                        <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                        <span className="text-blabcee"><span className="BalanceText"></span> {(balanceInfo - transtype) && ProtoTypes.Commas(balanceInfo - transtype)}</span>
                      </div>
                    </div>
                    <div className="leftt-blance">
                      <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                      <a id="form_id" href="#0" className="reset-btn" data-target="#exampleModal" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={e => handleExpoApi()}><span className="text-blabcee">
                        <span className="ExpText"></span> {transtype && ProtoTypes.Commas(transtype)}</span>
                      </a>
                    </div>
                  </div>}

                  {/* TopBar DropDown Section */}

                  {hideBalanceSection && <div className="right-menu-reature">
                    <div className="language">
                      <div className="nice-select ty" tabIndex={0}>
                        <div className="glo-icon">
                          <i className="fas fa-user"></i>
                        </div>
                        <div id="usernm">{user}</div>
                        <ul className="list inline">

                          <li className="option selected focus" onClick={e => accountdetailpage()}>Account Statement</li>
                          <li className="option selected focus" onClick={e => bethistorypage()}>Open Bets</li>
                          <Link id="form_id" href="#0" className="reset-btn" data-target="#exampleModal2" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                            <li onClick={resetModal} className="option selected focus">Change Password</li>
                          </Link>
                          <li className="option selected focus" onClick={e => loginhistorypage()}>Login History</li>
                          <Link id="form_id" href="#0" className="reset-btn" data-target="#exampleModal3" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                            <li onClick={resetStake} className="option selected focus">Set Button Value</li>
                          </Link>
                          <li className="option selected focus" onClick={e => auth2fa()}>2FA Auth</li>
                          <li className="option selected focus" onClick={e => handleLogout()}>Logout</li>
                        </ul>
                      </div>
                    </div>
                  </div>}
                </div>
              }
              <div className="header-bar">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </header>

      <ToastContainer />

      {/* Login Modal */}
      <div className="modal mylogin fade" id="exampleModalogin" tabIndex={-1} aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered nsigin">
          <div className="modal-content">
            <div className="modal-header">
              <div className="head">
                <h5>
                  {showOtp ? <span><img src='assets/img/ga3.svg' height={'30px'} width={'30px'} />  &nbsp;Google Authenticator </span> : "Sign into your account"}
                </h5>
              </div>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
            </div>
            <div className="modal-body">
              <div className="register-from">
                <form onSubmit={(e) => handleSubmitLogin(e)}>
                  <div className="items">
                    <div className="form-input">
                      <label htmlFor="email3" className="form-label">UserName</label>
                      <input type="username"
                        name="username"
                        value={userLoginData.username}
                        onChange={handleLoginInput}
                        disabled={showOtp}
                        placeholder="UserName" />
                      <div className="redone">
                        <span>{loginErrors.loginNameError}</span>
                      </div>
                    </div>
                  </div>

                  {/* Otp Section */}
                  <div className="items">
                    {!showOtp && <div className="form-input">
                      <label htmlFor="password-field" className="form-label">Password</label>
                      <div className="form-group">
                        <input
                          value={userLoginData.password}
                          onChange={handleLoginInput}
                          id="password-field"
                          type="password" className="form-control" placeholder="Your Password" name="password" />
                      </div>
                      <div className="redone">
                        <span>{loginErrors.loginResError}</span>
                        <span>{loginErrors.loginPasswordError}</span>
                      </div>
                    </div>}

                    {showOtp && <div className="">
                      <label htmlFor="password-field" className="form-label">Enter Secure Code</label>

                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputType="text"
                        inputStyle={{
                          width: "100%",
                          marginLeft: "5px",
                          marginRight: "5px",
                          height: "45px",
                          fontSize: "26px"
                        }}

                        shouldAutoFocus={true}
                      />
                      <div className="redone">
                        <span>{loginErrors.loginResError}</span>
                        <span>{loginErrors.loginPasswordError}</span>
                      </div>
                    </div>}
                  </div>
                  <div className="items">
                    <div className="remember d-flex align-items-center justify-content-between">
                      <div className="form-check">
                      </div>
                    </div>
                  </div>
                  <div className="items text-center">
                    {!showOtp && <button disabled={loginLoader} type="submit" className="cmn--btn cd-popup-close repopup">
                      <span>Login</span>  &nbsp;
                      <i className="fa fa-spinner fa-spin" style={loginLoader ? { "visibility": "visible" } : { "visibility": "hidden" }}></i>
                    </button>}

                    {showOtp && <button type="button" disabled={loginLoader} className="cmn--btn cd-popup-close repopup" onClick={() => getTokenBy2FA()}>
                      <span>Submit</span>  &nbsp;
                      <i className="fa fa-spinner fa-spin" style={loginLoader ? { "visibility": "visible" } : { "visibility": "hidden" }}></i>
                    </button>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </div >

      {/* Stake Label Modal */}
      <div className="modal mylogin fade" id="exampleModal3" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content stakeTem">
            <div className="modal-header" style={{ marginBottom: "7px" }}>
              <div className="head">
                <h5 style={{ marginBottom: "0px" }}>
                  Set Button Value
                </h5>
              </div>
              <button type="button" className="close stakePopup" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
            </div>
            <div className="modal-body">

              <div className="register-from">
                <div className="bothlabel">
                  <label className="slabel">Stake Label</label>
                  <label className="valuelabel">Stake Value</label>
                </div>
                <form onSubmit={(e) => betSlipSubmit(e)} >
                  {stakedetails.map((bet, index) => (
                    <div className="row mb-4 g-2" key={index}>
                      <div className="col-sm-6 col-xs-6">
                        <div className="itemss">
                          <div className="form-input">
                            <input type="text" maxLength={6} placeholder="Stake Label" className="odin" onBlur={checkEmptyClasss} name="label" onChange={e => handleBetSlipLabel(e, index)} value={bet.label} />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-6">
                        <div className="itemss">
                          <div className="form-input">
                            <input type="number" maxLength={10} min={0} placeholder="Bet Value" className="odin" onBlur={checkEmptyClasss} name="stakeValue" onChange={e => handleBetSlipValue(e, index)} value={bet.stakeValue} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {betError && (
                    <div className="errorshow">
                      <span>No Data Found!</span>
                    </div>
                  )}

                  <div className="items text-center">
                    <button type="submit" className="cmn--btn cd-popup-close repopup">
                      <span>Update</span>
                    </button>
                    <div className="redtwo">
                      <span>{allTempError}</span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Password Modal*/}
      <div className="modal mylogin fade" id="exampleModal2" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered nsigin">
          <div className="modal-content">
            <div className="modal-header">
              <div className="head">
                <h5>
                </h5>
                <h5>
                  Change Your Password?
                </h5>
              </div>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
            </div>

            <div className="modal-body">
              <div className="register-from">
                <form onSubmit={(e) => changePassword(e)} id="yourFormId">
                  <div className="items">
                    <div className="form-input">
                      <label htmlFor="email3" className="form-label">Old Password</label>
                      <input type="password"
                        name="oldpassword"
                        value={userPasswordData.oldpassword}
                        onChange={handleInput}
                        placeholder="OldPassword" />
                      <div className="redone">
                        <span>{errors.resError}</span>
                        <span>{errors.oldPasswordError}</span>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="form-input">
                      <label htmlFor="password-field" className="form-label">New Password</label>
                      <div className="form-group">
                        <input
                          value={userPasswordData.newpassword}
                          onChange={handleInput}
                          id="password-field"
                          type="password" className="form-control" placeholder="Your Password" name="newpassword" />
                      </div>
                      <div className="redone">
                        <span>{errors.newpasswordError}</span>
                      </div>
                    </div>
                  </div>

                  <div className="items">
                    <div className="form-input">
                      <label htmlFor="password-field" className="form-label">Confirm Password</label>
                      <div className="form-group">
                        <input
                          value={userPasswordData.confirmpassword}
                          onChange={handleInput}
                          id="password-field2"
                          type="password" className="form-control" placeholder="Your Password" name="confirmpassword" />

                      </div>
                      <div className="redone">
                        <span>{errors.confirmPasswordError}</span>
                        <span>{errors.matcherror}</span>
                      </div>
                      <span style={{ color: "white" }}>{errors.sucessfullmsg}</span>
                    </div>
                  </div>

                  <div className="items">
                    <div className="remember d-flex align-items-center justify-content-between">
                      <div className="form-check">
                      </div>
                    </div>
                  </div>
                  <div className="items text-center">
                    <button type="submit" className="cmn--btn cd-popup-close repopup" >
                      <span>Submit</span>
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Table Modal */}
      <div className="modal mylogin fade ExpoSum" id="exampleModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="container">
              <div className="col-xxl-12 col-xl-12 col-lg-4">
                <div className="rakeback-wrap">
                  <div className="head">
                    <p className="expoaligntext1">
                      Exposure Summary
                    </p>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                  </div>
                  <br></br>
                  <div className="col-xxl-12 col-xl-12 col-lg-12">
                    <div className="rakeback-wrap">
                      <div className="detail-match-winner rakeback-accordion">
                        <div className="details-match-wrap">
                          <div className="accordion" id="rakeback-accordion">
                            <div className="accordion-item">
                              {expoSummary && expoSummary.map((event, indexx) => (
                                <div className="accordion-header exp-header">
                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#rakeback" + indexx} aria-expanded="false">
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <ul className="bread-tag">
                                            <li>
                                              <span>{event.gameCatName}</span> <span style={{ color: "white" }}>&nbsp;{">"}&nbsp;</span>
                                              <span>{event.eventName}</span> <span style={{ color: "white" }}></span>

                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-lg-3">
                                          <span>Total: {TotalExposureAmount(event.openExpBCounts) && ProtoTypes.Commas(TotalExposureAmount(event.openExpBCounts))}</span>
                                          <br />
                                          <span>Commission: {TotalCommisionAmount(event.openExpBCounts) && ProtoTypes.Commas(TotalCommisionAmount(event.openExpBCounts))}</span>
                                        </div>
                                        <div className="col">
                                          <ul className="bread-tag1">
                                            <li>
                                              <span>{ProtoTypes.setLocalDate(event.eventDate)}
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </button>

                                  <div id={"rakeback" + indexx} className="accordion-collapse collapse">
                                    <div className="accordion-body d-flex align-items-center justify-content-between">
                                      <div className="rakeback-accor-body expotable">
                                        <div className="tab-content">
                                          <div className="description-table ob-table">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <th style={{ textAlign: "center" }}>Sr No</th>
                                                  <th style={{ textAlign: "left" }}>Header</th>
                                                  <th style={{ textAlign: "left" }}>Market</th>
                                                  <th style={{ textAlign: "right" }}>Bets Count</th>
                                                  <th style={{ textAlign: "right" }}>Amount</th>
                                                  <th style={{ textAlign: "right" }}>Commission</th>

                                                  <th style={{ textAlign: "right" }}>Exposure</th>
                                                </tr>
                                                {event.openExpBCounts && event.openExpBCounts.map((market, index) => (
                                                  <tr key={index}>
                                                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                    <td style={{ textAlign: "left" }}>{market.marketHName}</td>
                                                    <td style={{ textAlign: "left" }}>{market.marketName}</td>
                                                    <td style={{ textAlign: "right" }}>{market.betCount && ProtoTypes.Commas(market.betCount)}</td>
                                                    <td style={{ textAlign: "right" }}>{market.amount && ProtoTypes.Commas(market.amount)}</td>
                                                    <td style={{ textAlign: "right" }}>{market.commision && ProtoTypes.Commas(market.commision)}</td>
                                                    <td style={{ textAlign: "right" }}>{market.exposure && ProtoTypes.Commas(market.exposure,)}</td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        {showtableError && (
                                          <div className="errorshow">
                                            <span>No Data Found!</span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}


export default Topbar










