import React from 'react'
import { Navigate } from 'react-router';
import Login from './pages/Login';
import { useRoutes } from 'react-router';
import Home from './pages/Home';
import Layout from './components/Layout';
import Cricket from './pages/Cricket';
import Profile from './pages/Profile';
import Football from './pages/Football';
import Live from './pages/Live';
import Favourites from './pages/Favourites';
import Efootball from './pages/Efootball';
import CSgo from './pages/CSgo';
import Basketball from './pages/Basketball';
import EBasketball from './pages/EBasketball';
import Tennis from './pages/Tennis';
import ETennis from './pages/ETennis';
import Dota2 from './pages/Dota2';
import Lol from './pages/Lol';
import IceHockey from './pages/IceHockey';
import EHockey from './pages/EHockey';
import TableTennis from './pages/TableTennis';
import VolleyBall from './pages/VolleyBall';
import BaseBall from './pages/BaseBall';
import Rugby from './pages/Rugby';
import MMA from './pages/MMA';
import AmericanFootball from './pages/AmericanFootball';
import HandBall from './pages/HandBall';
import Rocket from './pages/Rocket';
import Boxing from './pages/Boxing';
import Rainbow from './pages/Rainbow';
import Futsal from './pages/Futsal';
import SC from './pages/SC';
import Valor from './pages/Valor';
import OW from './pages/OW';
import Details from './pages/Details';
import ConfirmPassword from './pages/ConfirmPassword';
import OpenHistory from './pages/OpenHistory';
import AccountStatement from './pages/AccountStatement';
import LoginHistory from './pages/LoginHistory';
import Auth2fa from './pages/Auth2fa';
import Tnc from './pages/Tnc';

const RoutePages = () => {
  return useRoutes([
    {
      //path: "/", element: localStorage.getItem("up_token") ?<Layout />: <Navigate to="/login" />  ,
      path: "/", element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/cricket", element: <Cricket /> },
        { path: "/profile", element: <Profile /> },
        { path: "/live", element: <Live /> },
        { path: "/football", element: <Football /> },
        { path: "/favourites", element: <Favourites /> },
        { path: "/efootball", element: <Efootball /> },
        { path: "/csgo", element: <CSgo /> },
        { path: "/basketball", element: <Basketball /> },
        { path: "/ebasketball", element: <EBasketball /> },
        { path: "/tennis", element: <Tennis /> },
        { path: "/etennis", element: <ETennis /> },
        { path: "/dota2", element: <Dota2 /> },
        { path: "/lol", element: <Lol /> },
        { path: "/icehockey", element: <IceHockey /> },
        { path: "/ehockey", element: <EHockey /> },
        { path: "/tabletennis", element: <TableTennis /> },
        { path: "/volleyball", element: <VolleyBall /> },
        { path: "/baseball", element: <BaseBall /> },
        { path: "/rugby", element: <Rugby /> },
        { path: "/mma", element: <MMA /> },
        { path: "/americanfootball", element: <AmericanFootball /> },
        { path: "/handball", element: <HandBall /> },
        { path: "/rocket", element: <Rocket /> },
        { path: "/boxing", element: <Boxing /> },
        { path: "/rainbow", element: <Rainbow /> },
        { path: "/futsal", element: <Futsal /> },
        { path: "/ow", element: <OW /> },
        { path: "/sc", element: <SC /> },
        { path: "/valor", element: <Valor /> },
        { path: "/details", element: <Details /> },
        { path: "/openhistory", element: <OpenHistory /> },
        { path: "/accountstatement", element: <AccountStatement /> },
        { path: "/loginhistory", element: <LoginHistory /> },
        { path: "/auth2fa", element: <Auth2fa /> },
        { path: "/tnc", element: <Tnc /> },
      ],
    },
    // { path: "/login", element: localStorage.getItem("up_token") ? <Navigate to="/" /> : <Login /> },
    { path: "/confirmpassword", element: <ConfirmPassword /> },

  ]);
};

export default RoutePages;
