import React, { useEffect, useState, useRef } from "react";
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';
import UserService from "../apis/UserService";
import CryptoJS from 'crypto-js';
import ApiService from "../apis/ApiService";
import parse from 'html-react-parser';
import { useOutletContext } from "react-router-dom";
import error from '../utilties/error';
import ProtoTypes from "../utilties/ProtoTypes";

const Home = () => {
    const navigate = useNavigate();
    const inputReff = useRef(null)
    const inputRefresponsivee = useRef(null)
    const [betsliploader, setbetsliploader] = useState(false)
    const { callApi, SetcallApi } = useOutletContext();
    const [Loader, setLoader] = useState(false)
    const [catgories, setCatgories] = useState([])
    const [events, setEvents] = useState([])
    const [livemrkts, setLivemrkts] = useState([])
    const [activeTab, setActiveTab] = useState("");
    const interval = useRef();
    const EventInterval = useRef();
    const catId = useRef(4);
    const isEventLongPull = useRef(true);
    const isLongPull = useRef(true);
    const [bannerlist, setbannerlist] = useState([])
    const [betError, setBetError] = useState("")
    const [betTab, setBetTab] = useState("2")
    const [moExp, setmoExp] = useState(0)
    const [mybets, setMyBets] = useState([])
    const [betSection, setBetSection] = useState(true)
    const [orderDetail, setOrderDetail] = useState({})
    const [betAmount, setBetAmount] = useState("")
    const [stakebuttons, SetStakebuttons] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [COM, SETCOM] = useState()
    const evtId = useRef();
    const mxbet = useRef("");
    const mnbet = useRef("");
    const eventsRef = useRef("");
    var mkts = [];
    const mkidsArry = useRef([]);
    const { sidebarDetails, SetsidebarDetails } = useOutletContext();
    const { loginButton, SetloginButton } = useOutletContext();

    Number.prototype.round = function (decimals) {
        return Number((Math.round(this + "e" + decimals) + "e-" + decimals));
    }

    useEffect(() => {
        async function calAyncFunction() {
            SetsidebarDetails([])
            setLoader(true)
            await getCategories();
            setActiveTab("Cricket");
            isEventLongPull.current = true;
            await GetFEventsByGCat(catId.current);
            let tk = localStorage.getItem("up_token")
            if (tk) {
                await GetFLMarkets(mkts)
                var stakebuttondata = {
                    getAll: true,
                    SingleClicked: true
                }
                await GetStakeButtons(stakebuttondata);
            } else {
                GetFLMarketsAnony(mkts)
            }
        }
        calAyncFunction();
        GetBannersList()
    }, []);

    const getCategories = async () => {
        try {
            await UserService.getGCatsData().then(res => {
                setLoader(false)
                if (res && res.data.responseCode) {
                    var msg = res.data.response;

                    if (msg == "Success") {
                        setCatgories(res.data.result)
                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
            setLoader(false)
        }
    }
    const loadscript = () => {
        if (!window.isscriptloaded) {
            window.isscriptloaded = true;
        }
    }

    useEffect(() => {
        mkts = [];
        const script = document.createElement('script');

        document.body.appendChild(script);
        loadscript();

        resetClasses()
        return () => {
            document.body.removeChild(script);
        }

    }, []);


    useEffect(() => {

        return () => {
            isLongPull.current = false;
            isEventLongPull.current = false;
            clearTimeout(interval.current);
            clearTimeout(EventInterval.current);
        }
    }, []);

    const resetClasses = () => {
        const elements = document.querySelectorAll('.tstel');
        elements.forEach((element) => {
            element.classList.remove('open');
        });

        const allelements = document.querySelectorAll('*');
        allelements.forEach((element) => {
            element.classList.remove('actvclss');
            element.classList.remove('c_actvclss');
            element.classList.remove('s_actvclss');
        });

    }

    const getEvents = async (cat) => {
        setActiveTab(cat.name);
        eventsRef.current = [];
        catId.current = cat.id
    }

    const GetFEventsByGCat = async (id) => {
        try {
            var rqstData = {
                "GCatId": id
            }
            await UserService.GetFEventsByGCat(rqstData).then(async (res) => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;

                    if (msg == "Success") {
                        isLongPull.current = true;
                        var alldata = res.data.result;
                        if(rqstData.GCatId === 1 && eventsRef.current?.length > 0){
                            eventsRef.current.forEach(ev_el => {
                                if(ev_el.hasOwnProperty('scoreData')){
                                    alldata.indexOf()
                                    var elementRowIndex = alldata?.indexOf(alldata?.find(s => s.eventId == ev_el.eventId));
                                    if (elementRowIndex != -1){
                                        alldata[elementRowIndex].scoreData = ev_el.scoreData;
                                    }
                                }
                            });
                            
                        }
                        eventsRef.current = alldata;
                        setEvents(alldata);
                        
                        var mrktsArry = [];
                        if(rqstData.GCatId === 1){
                            const allEventId = alldata.filter(el=> el.eventStatus=== "Live")?.map(a => a.eventId);
                            GetEventTimeline(allEventId, alldata);
                            
                        }

                        for (let index = 0; index < alldata.length; index++) {
                            const element = alldata[index];
                            // if (element.eventStatus === "Live" && rqstData.GCatId === 1)
                            //     GetEventTimeline(element.eventId, alldata);

                            if (element.moId)
                                mrktsArry.push(element.moId);
                        }
                        mkts = mrktsArry;

                        if (isEventLongPull.current) {
                            EventInterval.current = setTimeout(async () => {
                                await GetFEventsByGCat(catId.current);
                            }, loginButton ? 2000 : 1000);
                        }
                        setLoader(false)
                    }
                    else {
                        console.log(msg)
                        setLoader(false)
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const GetEventTimeline = (e_Ids, allEventdata) => {
        // e_Ids = 33527540;
        try {
            var rqstData = {
                "EventIds": e_Ids
            }
             ApiService.GetEventTimeline(rqstData).then((res) => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        var alldata = res.data.result;
                        // var alldata = [
                        //     {
                        //         "eventId": 33472460,
                        //         "eventTypeId": 1,
                        //         "timeElapsed": 31,
                        //         "status": "IN_PLAY",
                        //         "inPlayMatchStatus": "KickOff",
                        //         "score": {
                        //             "numberOfYellowCards": 0,
                        //             "numberOfRedCards": 0,
                        //             "numberOfCards": 0,
                        //             "numberOfCorners": 2,
                        //             "numberOfCornersFirstHalf": 2,
                        //             "numberOfCornersSecondHalf": 0,
                        //             "bookingPoints": 0,
                        //             "home": {
                        //                 "name": "ACS Vedita Colonesti MS",
                        //                 "score": "0",
                        //                 "halfTimeScore": "",
                        //                 "fullTimeScore": "",
                        //                 "penaltiesScore": "",
                        //                 "penaltiesSequence": [],
                        //                 "games": "",
                        //                 "sets": "",
                        //                 "numberOfYellowCards": 0,
                        //                 "numberOfRedCards": 0,
                        //                 "numberOfCards": 0,
                        //                 "numberOfCorners": 0,
                        //                 "numberOfCornersFirstHalf": 0,
                        //                 "numberOfCornersSecondHalf": 0,
                        //                 "bookingPoints": 0
                        //             },
                        //             "away": {
                        //                 "name": "FCM Alexandria",
                        //                 "score": "1",
                        //                 "halfTimeScore": "",
                        //                 "fullTimeScore": "",
                        //                 "penaltiesScore": "",
                        //                 "penaltiesSequence": [],
                        //                 "games": "",
                        //                 "sets": "",
                        //                 "numberOfYellowCards": 0,
                        //                 "numberOfRedCards": 0,
                        //                 "numberOfCards": 0,
                        //                 "numberOfCorners": 2,
                        //                 "numberOfCornersFirstHalf": 2,
                        //                 "numberOfCornersSecondHalf": 0,
                        //                 "bookingPoints": 0
                        //             }
                        //         }
                        //     }
                        // ]
                        for (let idx = 0; idx < alldata.length; idx++) {
                        let _score = (alldata[idx]);

                            var event_Id = _score.eventId;
                            // var event_Id = 33511486;
                            var elementRowIndex = allEventdata?.indexOf(allEventdata?.find(s => s.eventId == event_Id));
                            if (elementRowIndex != -1){
                                allEventdata[elementRowIndex].scoreData = _score;
                            }
                        }
                        eventsRef.current = allEventdata;
                        setEvents(allEventdata);
                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const GetBannersList = async (e) => {
        try {
            setLoader(true)
            await ApiService.GetBanners().then(res => {
                if (res && res.data.response == 'Success') {
                    var bannerdata = res.data.result
                    setbannerlist(bannerdata)
                    setLoader(false)
                }
                else {
                    console.log("Error")
                    setLoader(false)
                }
            }).catch(error => {
                setLoader(false)
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const GetFLMarkets = async (data) => {
        try {
            var rqstData = {
                "Markets": data
            }

            await UserService.GetFLMarkets(rqstData).then(async (res) => {
                setLoader(false)
                if (res && res.data.responseCode) {
                    var msg = res.data.response;

                    if (msg == "Success") {
                        var markets = res.data.result;
                        setLivemrkts(res.data.result);
                        if (isLongPull.current) {
                            interval.current = setTimeout(async () => {
                                await GetFLMarkets(mkts);
                            }, loginButton ? 2000 : 1000);
                        }
                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }
    }

    const GetFLMarketsAnony = async (data) => {
        try {
            var rqstData = {
                "Markets": data
            }

            await UserService.GetFLMarketsAnony(rqstData).then(async (res) => {
                setLoader(false)
                if (res && res.data.responseCode) {
                    var msg = res.data.response;

                    if (msg == "Success") {
                        var markets = res.data.result;
                        setLivemrkts(res.data.result);
                        if (isLongPull.current) {
                            interval.current = setTimeout(async () => {
                                await GetFLMarketsAnony(mkts);
                            }, 2000);
                        }
                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }
    }

    function isTomorrow(date1) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        const date = new Date(date1)
        if (tomorrow.toDateString() === date.toDateString()) {
            return "Tomorrow";
        }

        return "Today";
    }

    const OpenDetails = (data) => {
        if (!loginButton) {
            var row = {
                compId: data.compId,
                compName: data.compName,
                eventDate: data.eventDate,
                eventId: data.eventId,
                eventName: data.eventName,
                catId: catId.current
            }

            var rqstdata = CryptoJS.AES.encrypt(JSON.stringify(row), 'key@123').toString();
            navigate('/details', {
                state: { query: rqstdata }
            })
        }
        else {
            const btnLoginHome = document.getElementById('btnLoginHome');
            if (btnLoginHome)
                btnLoginHome.click();
        }
    }

    const GetStakeButtons = async (data) => {
        try {
            await UserService.GetStakeButtons(data).then(async (res) => {
                if (res && res?.data?.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        await GetBets()
                        setBetTab("1")
                        var rs = res.data.result
                        if (rs && rs.length > 0) {
                            var stakebtns = rs.filter(s => !s.singleClicked);
                            SetStakebuttons(stakebtns);
                        }
                    }
                    else {
                        error.errorToastify(msg)
                    }
                } else {
                    if (res.response.status === 403 || res.response.status === 401) {

                        const btnLoginHome = document.getElementById('btnLoginHome');
                        if (btnLoginHome)
                            btnLoginHome.click();

                        setTimeout(() => {
                            const btnCloseModalB = document.getElementById('btnCloseModalB');
                            if (btnCloseModalB)
                                btnCloseModalB.click();

                        }, 300);
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }

    }

    const GetBets = async () => {
        try {
            var rqstData = {
                EventId: evtId.current,
            }

            await UserService.GetBets(rqstData).then(async (res) => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        var rs = res.data.result.betHistories;
                        var totalcm = 0
                        var tt = []
                        for (var i = 0; i < rs.length; i++) {
                            var ts = rs[i].betHistories;
                            for (var ind = 0; ind < ts.length; ind++) {
                                totalcm += ts[ind].commision;
                                tt.push(ts[ind])
                            }
                        }

                        var optArr = [];

                        for (let index = 0; index < mkidsArry.current.length; index++) {
                            const el = mkidsArry.current[index];
                            const found = tt.find(a => a.marketId == el);

                            if (found) {
                                const isExist = optArr.find(a => a.marId == el);
                                if (isExist == undefined)
                                    optArr.push({ marId: el, marketName: found.marketName })
                            }

                        }
                        SETCOM(totalcm)
                        if (totalcm == 0) {
                            SETCOM(totalcm)
                        }
                        setMyBets(rs);

                        //    GetTotalPl(rs);
                    }
                    else {
                        error.errorToastify(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }

    }

    const openBetSlip = () => {
        setBetSection(true)
        setBetAmount("")
        setBetTab("1")
    }

    const openMyBet = async () => {
        setBetTab("2")
        await GetBets()
    }

    const setManualBetAmt = (amnt) => {
        inputReff.current?.focus()
        inputRefresponsivee.current?.focus()
        setBetAmount(amnt)
    }

    const checkNumber = (event) => {
        const result = event.target.value.replace(/[^0-9.]/g, '');
        setBetAmount(result)
    }

    const OpenBetModal = async (evnt, i, typ, e) => {
        setInterval(() => {
            inputReff.current?.focus()
            inputRefresponsivee.current?.focus()
        }, 50);
        setBetAmount("")
        setBetError("")
        var liveMORunners = {};
        if (livemrkts.length > 0) {
            var moR = livemrkts.find(s => s.marketID == evnt.moId);
            if (moR)
                var totalMORnr = moR.liveMORunners
            if (totalMORnr && totalMORnr.length > 0)
                liveMORunners = totalMORnr[i]
        }
        if (liveMORunners && liveMORunners != null && Object.keys(liveMORunners).length > 0) {
            setOrderDetail({
                marketHName: "Match Odds",
                price: typ == "B" ? liveMORunners.backPrice1 : liveMORunners.layPrice1,
                eventName: evnt.eventName,
                runnerName: liveMORunners.runnerName,
                type: typ,
                runnerId: liveMORunners.runnerId,
                marketId: liveMORunners.marketId,
                odds: typ == "B" ? liveMORunners.backOdds : liveMORunners.layOdds,
                rIndx: 1
            })
        } else {
            return
        }

        evtId.current = evnt.eventId
        var stakebuttondata = {
            getAll: true,
            SingleClicked: true
        }
        await GetStakeButtons(stakebuttondata);


    }

    const pressSCKey = (event) => {
        if (event.key === "Enter") {
            placeBet();
        }
    };

    const placeBet = async (e) => {
        try {
            e.preventDefault()
            if (betAmount == "") {
                setBetError("Amount is mandatory!")
                return
            }

            var rqstData = {
                EventId: evtId.current,
                MarketId: orderDetail.marketId,
                MoRunnerId: orderDetail.runnerId,
                Price: orderDetail.price,
                Odds: orderDetail.odds,
                Amount: betAmount,
                Side: orderDetail.type,
                MoRunnerIndex: orderDetail.rIndx
            }

            setIsLoad(true)
            setbetsliploader(true)
            await SinglePlaceBet(rqstData)

        } catch (error) {
            setIsLoad(false)
            console.log(error)
        }

    }

    const SinglePlaceBet = async (rqstData) => {
        try {
            setIsLoad(true)
            await UserService.AddBet(rqstData).then(async (res) => {
                setIsLoad(false)
                setbetsliploader(false)
                setBetError("")
                setBetAmount("")

                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        SetcallApi(!callApi);
                        error.successToastify(msg);
                        await openMyBet();
                    }
                    else {
                        error.errorToastify(res.data.response)
                        inputReff.current?.focus()
                        inputRefresponsivee.current?.focus()
                        if (res.data.responseCode == 120)
                            setBetAmount(mxbet.current)
                        else if (res.data.responseCode == 119)
                            setBetAmount(mnbet.current)
                    }
                }
            }).catch(error => {
                console.log(error)
                setIsLoad(false)
                setbetsliploader(false)
            })

        } catch (error) {
            setIsLoad(false)
            console.log(error)
            setbetsliploader(false)
        }

    }

    const checkOvlay = (moId, i) => {
        var msg = "";
        if (livemrkts && livemrkts.length > 0) {
            var mktEL = livemrkts.find(s => s.marketID == moId);
            if (mktEL && mktEL.marketStatus != "") {
                msg = mktEL.marketStatus;
            }
            else if (mktEL && mktEL.liveMORunners && mktEL.liveMORunners.length > 0) {
                try {
                    msg = mktEL.liveMORunners[i].runnerStatus
                } catch (error) {

                }

            } else {
                msg = ""
            }

        }

        if (msg == "Suspend")
            msg = "Suspended"

        return msg;

    }

    function fn_scoreData(evt){
        const _score = evt?.scoreData;
        if(evt.hasOwnProperty('scoreData')){
            if (_score.status == "IN_PLAY") {
                var timeElapsed = _score.timeElapsed;
                var homeScore = _score.score.home.score;
                var awayScore = _score.score.away.score;
                var inPlayMatchStatus = _score.inPlayMatchStatus;
                // var inPlayMatchStatus = "Finished";
                var strScore = "";
                // KickOff, FirstHalfEnd, SecondHalfKickOff, Finished
                if (inPlayMatchStatus == "Finished") {
                    return (
                        <div onClick={() => { OpenDetails(evt) }} className="matchDayTime xs-bgh soccer" >
                            <div className="mint">END</div>
                            <div className="goallist">
                                <div>{(homeScore == "" ? "0" : homeScore)}</div>
                                <div>{(awayScore == "" ? "0" : awayScore)}</div>
                            </div>
                        </div>
                    );
                }
                else if (inPlayMatchStatus == "FirstHalfEnd") {
                    return (
                        <div onClick={() => { OpenDetails(evt) }} className="matchDayTime xs-bgh soccer" >
                            <div className="mint">HT</div>
                            <div className="goallist">
                                <div>{(homeScore == "" ? "0" : homeScore)}</div>
                                <div>{(awayScore == "" ? "0" : awayScore)}</div>
                            </div>
                        </div>
                    );
                }
                else {
                    return (
                        <div onClick={() => { OpenDetails(evt) }} className="matchDayTime xs-bgh soccer" >
                            <div className="mint">{timeElapsed}'</div>
                            <div className="goallist">
                                <div>{(homeScore == "" ? "0" : homeScore)}</div>
                                <div>{(awayScore == "" ? "0" : awayScore)}</div>
                            </div>
                        </div>
                    );
                }
            }
            else if (_score.status == "COMPLETE") {
                var timeElapsed = _score.timeElapsed;
                var homeScore = _score.score.home.score;
                var awayScore = _score.score.away.score;
                var inPlayMatchStatus = _score.inPlayMatchStatus;
                var strScore = "";
                return (
                    <div onClick={() => { OpenDetails(evt) }} className="matchDayTime xs-bgh soccer" >
                            <div className="mint">END</div>
                            <div className="goallist">
                                <div>{(homeScore == "" ? "0" : homeScore)}</div>
                                <div>{(awayScore == "" ? "0" : awayScore)}</div>
                            </div>
                        </div>
                );
            }
            else {
                return (
                    <div onClick={() => { OpenDetails(evt) }} className="matchDayTime xs-bgh">   <div className="lds-ellipsis">In Play</div>
                    </div>
                );
            }
        }
        else {
            return (
                <div onClick={() => { OpenDetails(evt) }} className="matchDayTime xs-bgh">   <div className="lds-ellipsis">In Play</div>
                </div>
            );
        }
        
    }

    return (
        <>
            {/*loader*/}

            {Loader && <div className="boxes-bg">
                <div className="boxes">
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>}

            {/*Middle Body*/}
            <div className="body-middle">

                {/*Banner Image*/}
                <div className="banner-section">
                    <div className="container">
                        <div className="banner-wrapper owl-theme owl-carousel">
                            {bannerlist.map((banneritem, index) => (
                                <div className="banner-items" key={index} style={{ background: `url(${process.env.REACT_APP_AP_ENDPOINT + banneritem.bannerUrl}`, backgroundSize: "cover" }}>
                                    <div className="container">
                                        <div className="banner-content">
                                            <h1>
                                                {banneritem.caption}
                                            </h1>
                                            <h2>
                                                {banneritem.description != null && parse(banneritem.description)}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Game Category Tab */}
                <div className="banner-feature" >
                    <div className="container">
                        <div className="banner-feature-wrapper">
                            {catgories.map((cat, index) => (
                                <a key={index} className={activeTab == cat.name ? "banner-feature-items actbanner" : "banner-feature-items"} onClick={() => getEvents(cat)}>
                                    <span className="cat-icon">
                                        <i className={cat.icon}></i>
                                    </span>

                                    <span className="banner-feature-contentt">
                                        {cat.eventCount > 0 ? cat.name + " (" + cat.eventCount + ")" : cat.name}
                                    </span>
                                </a>

                            ))}
                        </div>
                    </div>
                </div>

                <div className="main-body-tabbing">
                    <div className="container">
                        <div className="main-tabbing">

                            <div className="tab-content" id="tabContentmain">

                                <div className="tab-pane fade show active" id="betsall" role="tabpanel">

                                    {/*Match Table Start*/}
                                    <div className="match-table pt-10">

                                        <div className="table-wrap mb-10 pb-10">
                                            <div className="table-inner ToverFlow-hide">

                                                <table className="table evnt-tbl mb-0 border-none">
                                                    <thead id="tb-evt" className="table-head" >
                                                        <tr>
                                                            <th>
                                                                <div className="matchRowHeader">
                                                                    <div className="MatchTitle">{activeTab}</div>
                                                                    <div className="matchPoint" style={{ textAlign: "center" }}>1</div>
                                                                    <div className="matchPoint" style={{ textAlign: "center" }}>X</div>
                                                                    <div className="matchPoint" style={{ textAlign: "center" }}>2</div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {events.map((evt, indx) => (


                                                                    <div className="matchRow" style={{ cursor: "pointer" }} key={indx} >
                                                                         <div class="matchTitle"> 
                                                                        {evt.eventStatus == "Live" ?
                                                                           
                                                                            <>
                                                                            {
                                                                                (catId.current === 1)  ? 
                                                                                fn_scoreData(evt) 
                                                                                : 
                                                                                <div onClick={() => { OpenDetails(evt) }} className="matchDayTime xs-bgh">  <div className="lds-ellipsis">Live<div></div><div></div><div></div><div></div></div>
                                                                                </div>
                                                                                }
                                                                                <div onClick={() => { OpenDetails(evt) }} className="matchTeam xs-bgh">{evt.eventName} <br /> {evt.compName == null ? "" : "(" + evt.compName + ")"}</div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className="matchDayTime" onClick={() => { OpenDetails(evt) }}>{isTomorrow(evt.eventDate)}

                                                                                    <br /> <span onClick={() => { OpenDetails(evt) }} className="  time-td xs-bgh">{ProtoTypes.SetLocalTime(evt.eventDate)}</span></div>

                                                                                <div onClick={() => { OpenDetails(evt) }} className="matchTeam xs-bgh">{evt.eventName} <br />
                                                                                    {evt.compName == null ? "" : "(" + evt.compName + ")"}</div>

                                                                            </>
                                                                           
                                                                        }
                                                                         </div>
                                                                        <div className="matchPoint">
                                                                            <div className="rgtlbec ">
                                                                                <div onClick={() => { loginButton ? void (0) : OpenBetModal(evt, 0, "B") }} data-bs-toggle="modal" data-bs-target={loginButton ? "#exampleModalogin" : "#bet-Modal"} className="bsec PointBox">{livemrkts.length > 0 && (livemrkts.find(s => s.marketID == evt.moId)) && (livemrkts.find(s => s.marketID == evt.moId).liveMORunners) && (livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0]) && (livemrkts.find(s => s.marketID == evt.moId)).liveMORunners[0].backPrice1 != "" ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].backPrice1 > 19.5 ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].backPrice1 : livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].backPrice1 : "-"}</div>
                                                                                <div onClick={() => { loginButton ? void (0) : OpenBetModal(evt, 0, "L") }} data-bs-toggle="modal" data-bs-target={loginButton ? "#exampleModalogin" : "#bet-Modal"} className="lsec PointBox">{livemrkts.length > 0 && (livemrkts.find(s => s.marketID == evt.moId)) && (livemrkts.find(s => s.marketID == evt.moId).liveMORunners) && (livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0]) && (livemrkts.find(s => s.marketID == evt.moId)).l != "" ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].layPrice1 > 19.5 ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].layPrice1 : livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].layPrice1 : "-"}</div>
                                                                               
                                                                            </div>
                                                                            {checkOvlay(evt.moId, 0) != "" && <div className="ov"><span>{checkOvlay(evt.moId, 0)}</span></div>}

                                                                        </div>
                                                                        <div className="matchPoint" >
                                                                            <div className="rgtlbec">
                                                                                <div onClick={() => { loginButton ? void (0) : OpenBetModal(evt, 2, "B") }} data-bs-toggle="modal" data-bs-target={loginButton ? "#exampleModalogin" : "#bet-Modal"} className="bsec PointBox">{livemrkts.length > 0 && (livemrkts.find(s => s.marketID == evt.moId)) && (livemrkts.find(s => s.marketID == evt.moId).liveMORunners) && livemrkts.find(s => s.marketID == evt.moId).liveMORunners.length > 2 && (livemrkts.find(s => s.marketID == evt.moId)).liveMORunners[2].backPrice1 != "" ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].backPrice1 > 19.5 ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[2].backPrice1 : livemrkts.find(s => s.marketID == evt.moId).liveMORunners[2].backPrice1 : "-"}</div>
                                                                                <div onClick={() => { loginButton ? void (0) : OpenBetModal(evt, 2, "L") }} data-bs-toggle="modal" data-bs-target={loginButton ? "#exampleModalogin" : "#bet-Modal"} className="lsec PointBox">{livemrkts.length > 0 && (livemrkts.find(s => s.marketID == evt.moId)) && (livemrkts.find(s => s.marketID == evt.moId).liveMORunners) && livemrkts.find(s => s.marketID == evt.moId).liveMORunners.length > 2 && (livemrkts.find(s => s.marketID == evt.moId)).liveMORunners[2].layPrice1 != "" ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].layPrice1 > 19.5 ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[2].layPrice1 : livemrkts.find(s => s.marketID == evt.moId).liveMORunners[2].layPrice1 : "-"}</div>
                                                                               
                                                                            </div>
                                                                            {checkOvlay(evt.moId, 2) != "" && <div className="ov"><span>{checkOvlay(evt.moId, 2)}</span></div>}
                                                                        </div>

                                                                        <div className="matchPoint" >

                                                                            <div className="rgtlbec">
                                                                                <div onClick={() => { loginButton ? void (0) : OpenBetModal(evt, 1, "B") }} data-bs-toggle="modal" data-bs-target={loginButton ? "#exampleModalogin" : "#bet-Modal"} className="bsec PointBox">{livemrkts.length > 0 && (livemrkts.find(s => s.marketID == evt.moId)) && (livemrkts.find(s => s.marketID == evt.moId).liveMORunners) && livemrkts.find(s => s.marketID == evt.moId).liveMORunners.length > 1 && (livemrkts.find(s => s.marketID == evt.moId)).liveMORunners[1].backPrice1 != "" ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].backPrice1 > 19.5 ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[1].backPrice1 : livemrkts.find(s => s.marketID == evt.moId).liveMORunners[1].backPrice1 : "-"}</div>
                                                                                <div onClick={() => { loginButton ? void (0) : OpenBetModal(evt, 1, "L") }} data-bs-toggle="modal" data-bs-target={loginButton ? "#exampleModalogin" : "#bet-Modal"} className="lsec PointBox">{livemrkts.length > 0 && (livemrkts.find(s => s.marketID == evt.moId)) && (livemrkts.find(s => s.marketID == evt.moId).liveMORunners) && livemrkts.find(s => s.marketID == evt.moId).liveMORunners.length > 1 && (livemrkts.find(s => s.marketID == evt.moId)).liveMORunners[1].layPrice1 != "" ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[0].layPrice1 > 19.5 ? livemrkts.find(s => s.marketID == evt.moId).liveMORunners[1].layPrice1 : livemrkts.find(s => s.marketID == evt.moId).liveMORunners[1].layPrice1 : "-"}</div>
                                                                                
                                                                            </div>
                                                                            
                                                                            {checkOvlay(evt.moId, 1) != "" && <div className="ov"><span>{checkOvlay(evt.moId, 1)}</span></div>}

                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </td></tr>
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Main-body-tabing*/}
                <Footer />

                {/*Bet Modal*/}

                <div className="modal mylogin fade custde" id="bet-Modal" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-top">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <button type="button" className="close" id="btnCloseModalB" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                            </div>

                            <div className="modal-body">
                                <div className="right-site-menu">
                                    <div className="right-box" >
                                        <div className=" ">
                                            <div className="combo-box">

                                                <ul className="nav">
                                                    <li className="nav-item">
                                                        <a className={betTab == "1" ? "nav-link link-secondary active " : "nav-link link-secondary"} onClick={() => openBetSlip()} id="combo-tab" data-bs-toggle="tab" data-bs-target="#coombo" href="#"><span>Bet Slip</span></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className={betTab == "2" ? "nav-link link-secondary active " : "nav-link link-secondary"} onClick={() => openMyBet()} id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>My Bets</span></a>
                                                    </li>

                                                </ul>
                                                <div className="tab-content" id="tabContentboxes">
                                                    <div className={betTab == "1" ? "tab-pane fade show active " : "tab-pane fade"} id="1" role="tabpanel">
                                                        <div className="combo-wrapper">
                                                            <div className={betsliploader ? "homeloader" : "homeloader2"}><img src="assets/img/loader.gif" alt="loader" width={"80"} /></div>
                                                            {betSection && <div className="combo-wrapper">
                                                                <div className="close-box">
                                                                    <div className="close-items">
                                                                        <div className="close-head">
                                                                            <span>{orderDetail.eventName}</span>
                                                                            <span className="close">
                                                                                <i className="fas fa-xmark" />
                                                                            </span>
                                                                        </div>
                                                                        <div className="match-fixing">
                                                                            <div className="match-items">

                                                                                <div className="match-items-left">

                                                                                    <div className="cont">
                                                                                        <span>{orderDetail.marketHName}</span>
                                                                                        <span className="winner tm-winner">{orderDetail.mktye == "LM" ? orderDetail.lmMarketrName : orderDetail.runnerName}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="match-items-right">
                                                                                    <div> <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />{" "}{orderDetail.price && orderDetail.price}</div>
                                                                                    <div className='moexp'>
                                                                                        <span className='positive'> {(orderDetail.marketHName == "Bookmaker" || orderDetail.marketHName == "Match Odds") && moExp != 0 && betAmount != "" && orderDetail && moExp}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="ammount-items">
                                                                    <form action="#">
                                                                        <input value={betAmount} ref={inputReff} onChange={(e) => checkNumber(e)} type="text" placeholder="Bet Amount" style={{ border: orderDetail.type == "B" ? "#72bbef 2px solid" : orderDetail.type == "L" ? "#f994ba 2px solid" : "" }} />
                                                                        {betError && <div className='errorb'>{betError}</div>}
                                                                        <div className='row fxprc-sec'>
                                                                            {stakebuttons && stakebuttons.map((stk, i) => (
                                                                                <div key={i} className="col-lg-3 "><span onClick={() => setManualBetAmt(stk.stakeValue)} className='cursorclass prc-btn'>{stk.label}</span></div>
                                                                            ))}
                                                                        </div>
                                                                        <div className={isLoad ? "combo-footer disabled " : "combo-footer cursorclass"} >
                                                                            <button className="cmn--btn pbtn" type='submit' onKeyPress={(e) => pressSCKey(e)} onClick={(e) => placeBet(e)}>
                                                                                <span> Place Bet</span>  &nbsp;
                                                                                <i className="fa fa-spinner fa-spin" style={isLoad ? { "visibility": "visible" } : { "visibility": "hidden" }}></i>
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>

                                                    <div className={betTab == "2" ? "tab-pane fade show active " : "tab-pane fade"} id="2" role="tabpanel">
                                                        <div className='leftt-blancee'>
                                                            Total Commission: <span>{COM && COM.round(2)}</span>
                                                        </div>

                                                        <>
                                                            <div className="detail-match-winner" >
                                                                <div className="details-match-wrap" >
                                                                    <div className="accordion" id="details-accordionscore">

                                                                        {mybets && mybets.map((bets, indx) => (
                                                                            <div className="accordion-item">
                                                                                <div className="popular-bets"  >
                                                                                    <div className="accordion-header" key={indx}>

                                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#detddaislaccor" + indx} aria-expanded="false" aria-controls="detaislaccor50">
                                                                                            <span>{bets.headerName}</span>
                                                                                        </button>


                                                                                        <div id={"detddaislaccor" + indx} className="accordion-collapse collapse show">
                                                                                            <div className="accordion-body bets-according">
                                                                                                <ul className="popoular-body">
                                                                                                    {bets.betHistories && bets.betHistories.map((childbets, i) => (

                                                                                                        childbets.isCancelled ? <del>
                                                                                                            <li className="popular-items b-brdr" key={i} title='Bet Cancelled'>
                                                                                                                <label className={childbets.side == "L" ? "popular-itmes-left lay-border" : "popular-itmes-left back-border"}>

                                                                                                                    <span className="cont">
                                                                                                                        <span className="text1" title='Odds'>
                                                                                                                            {bets.headerName == "Normal" ? childbets.odds : childbets.runnerName}                                                                    </span>
                                                                                                                        <span className="text2">
                                                                                                                            {childbets.marketName}
                                                                                                                        </span>
                                                                                                                        <span className="text3" title='Price'>
                                                                                                                            {childbets.price}
                                                                                                                        </span>

                                                                                                                    </span>

                                                                                                                </label>

                                                                                                                <div className="popular-itmes-right">
                                                                                                                    <span title='Amount'>
                                                                                                                        {childbets.amount}
                                                                                                                    </span>
                                                                                                                    <div style={{ textAlign: "right" }} title='Commision'>{childbets.commision > 0 ? (childbets.commision) : ""}</div>
                                                                                                                </div>

                                                                                                            </li >

                                                                                                        </del> :
                                                                                                            <li className="popular-items" key={i}>
                                                                                                                <label className={childbets.side == "L" ? "popular-itmes-left lay-border" : "popular-itmes-left back-border"}>

                                                                                                                    <span className="cont">
                                                                                                                        <span className="text1" title='Odds'>
                                                                                                                            {bets.headerName == "Normal" ? childbets.odds : childbets.runnerName}                                                                    </span>
                                                                                                                        <span className="text2">
                                                                                                                            {childbets.marketName}
                                                                                                                        </span>
                                                                                                                        <span className="text3" title='Price'>
                                                                                                                            {childbets.price}
                                                                                                                        </span>

                                                                                                                    </span>
                                                                                                                </label>

                                                                                                                <div className="popular-itmes-right">
                                                                                                                    <span title='Amount'>
                                                                                                                        {childbets.amount}
                                                                                                                    </span>
                                                                                                                    <div style={{ textAlign: "right" }} title='Commision'>{childbets.commision > 0 ? (childbets.commision) : ""}</div>
                                                                                                                </div>

                                                                                                            </li >

                                                                                                    ))}
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>

                                                    </div>

                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </div >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home