import React, { useEffect, useState, useRef } from 'react'
import Footer from '../components/Footer'
import ApiService from "../apis/ApiService";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import ProtoTypes from '../utilties/ProtoTypes';

Number.prototype.round = function (decimals) {
    return Number((Math.round(this + "e" + decimals) + "e-" + decimals));
}

const OpenHistory = () => {
const[username,setusername]=useState("")
    const [Loader, setLoader] = useState(false)
    const [bthistory, setbthistory] = useState([])
    const [showtableError, settableError] = useState(false)
    const [totlamount, settotlamount] = useState([])
    const [totlbetcount, setotlbetcount] = useState([])
    const [totalcommision, settotalcommision] = useState()
    const [commisionShow, setcommisionShow] = useState(true)

    const [paginationData, setPaginationData] = useState({
        PageNum: 1,
        Side: null,
        RowsCount: "200",
        PageCount: '',
        DisplayCancelled: false,
    });

    useEffect(() => {
        var username = document.getElementById("usernm")
        if (username && username != null) {
            var user = username.innerHTML
            setusername(user)
        }
    }, [])

    useEffect(() => {
        GetAllUserBets()
        if (paginationData.DisplayCancelled == true) {
            setpage1()
        }
    }, [paginationData.PageNum, paginationData.Side, paginationData.DisplayCancelled, paginationData.RowsCount])


    const handleAllSide = (e) => {
        if (e.target.value) {
            var result = e.target.value;
            if (e.target.value == "null")
                result = null
        }
        if (result == "B") {
            setPaginationData({ ...paginationData, Side: result, PageNum: 1 });
            setpage1()
        }
        if (result == "L") {
            setPaginationData({ ...paginationData, Side: result, PageNum: 1 });
            setpage1()
        }
        if (result == null) {
            setPaginationData({ ...paginationData, Side: result, PageNum: 1 });
            setpage1()
        }
        setPaginationData({ ...paginationData, Side: result, PageNum: 1 });
    }

    const handleMatched = (e) => {
        if (e.target.value) {
            var matchdata = e.target.value;
            var convertdata = JSON.parse(matchdata)
            if (convertdata == true) {
                setPaginationData({ ...paginationData, DisplayCancelled: convertdata, PageNum: 1 });
            } else {
                setPaginationData({ ...paginationData, DisplayCancelled: convertdata });
            }
        }
    }

    const GetAllUserBets = async (e) => {
        try {
            var sendBetsData = {
                Page: paginationData.PageNum,
                Limit: paginationData.RowsCount,
                Side: paginationData.Side,
                DisplayCancelled: paginationData.DisplayCancelled,
            }
            setLoader(true)
            await ApiService.GetAllUserBets(sendBetsData).then(res => {
                if (res && res.data.response == 'Success') {
                    var totalcm = res.data.result.totalCommision
                        settotalcommision(totalcm)
                    var tamount = res.data.result.totalBAmount
                    settotlamount(tamount)
                    var btcount = res.data.result.betCount
                    setotlbetcount(btcount)
                    var rr = res.data.result.userBets
                    setbthistory(res.data.result.userBets)
                    var records = (res.data.result.betCount / parseInt(paginationData.RowsCount))
                    setPaginationData({ ...paginationData, PageCount: Math.ceil(records) })
                    setLoader(false)
                    var isCom = false
                    for (let index = 0; index < rr.length; index++) {
                        if (rr[index].commision > 0 || rr[index].commision < 0) {
                            isCom = true
                        }
                    }
                    setcommisionShow(isCom)
                }
                else {
                    console.log("Error")
                    setLoader(false)
                }
                if (res.data.result.userBets.length == 0) {
                    settableError(true)
                } else {
                    settableError(false)
                }
            }).catch(error => {
                setLoader(false)
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }


    const options = [
        { value: '200', label: '200' },
        { value: '300', label: '300' },
        { value: '500', label: '500' },
        { value: '1000', label: '1000' },
    ]

    const handlePageChange = (e) => {
        setPaginationData({ ...paginationData, PageNum: e.selected + 1 })
    };


    const RowSizeHandler = (e) => {
        const liEelment = document.querySelector('[aria-label="Page 1"]');
        if (liEelment) {
            liEelment.click();
        }
        setPaginationData({ ...paginationData, RowsCount: e.value, PageNum: 1 })
    }

    const passopenhistoryReportData = () => {
        window.createOpenHistoryReport(bthistory, username)
    }


    const setpage1 = () => {
        const liEelment = document.querySelector('[aria-label="Page 1"]');
        if (liEelment) {
            liEelment.click();
        }
        else {
            const liEelment2 = document.querySelector('[aria-label="Go to page 1"]');
            if (liEelment2) {
                liEelment2.click();
            }
        }
    }

    return (
        <>
        {/* Loader */}
            {Loader && <div className="boxes-bg">
                <div className="boxes">
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>}

            <div className="body-middle">
                {/*Breadcumnd*/}
                <div className="breadcumnd-banner">
                    <div className="container">
                        <div className="breadcumd-content">
                            <h1>
                                Open Bets
                            </h1>
                        </div>
                    </div>
                </div>

              
                {/*profile*/}

                <div className="profile-section pb-60">
                    <div className="container">
                        <div className="tab-content ob-content ">
                            <div className="row justify-content-between">
                                {/* Radio Buttons */}
                                <div className="openHis wallet-tab-wrap">
                                    <div className='gift-card '>

                                        <label><input type="radio" style={{ cursor: "pointer" }} name="fav_language" value="false" defaultChecked onChange={e => handleMatched(e)} /> Matched</label>
                                        <label><input type="radio" style={{ cursor: "pointer" }} name="fav_language" value="true" onChange={e => handleMatched(e)} /> Deleted</label>
                                    </div>
                                    <div className='gift-card ms-sm-2'>
                                        <label><input type="radio" style={{ cursor: "pointer" }} name="fav_languag" value="null" defaultChecked onChange={e => handleAllSide(e)} /> All</label>
                                        <label><input type="radio" style={{ cursor: "pointer" }} name="fav_languag" value="B" onChange={e => handleAllSide(e)} /> Back</label>
                                        <label><input type="radio" style={{ cursor: "pointer" }} name="fav_languag" value="L" onChange={e => handleAllSide(e)} /> Lay</label>
                                    </div>
                                    <div className="gift-card ms-sm-2">
                                        <div className="left-cart">
                                            <div>Amount</div>
                                            <div>{(totlamount).toLocaleString()}</div>
                                        </div>
                                    </div>
                                    <div className="gift-card  ms-sm-2">
                                        <div className="left-cart">
                                            <div>Com.</div>
                                            <div>{(totalcommision && totalcommision.round(2))}</div>
                                        </div>
                                    </div>
                                    <div className="gift-card  ms-sm-2">
                                        <div className="left-cart">
                                            <div>Bets</div>
                                            <div>{(totlbetcount)}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Pagination */}
                                <div className='openHis wallet-tab-wrap paging-wrap'>
                                   
                                        <div className='col-sm-2 col-md-2 col-lg-1  pe-0 accStat'>
                                            <Select defaultValue={options[1]} onChange={e => RowSizeHandler(e)} options={options}></Select>
                                        </div>
                                        <div className='col-sm-6 col-md-7 col-lg-8 text-center xsmy-2 ms-2'>
                                            <ReactPaginate
                                                previousLabel={"Prev"}
                                                nextLabel={"Next"}
                                                breakLabel={".."}
                                                breakClassName={"break-me"}
                                                pageCount={paginationData.PageCount}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={1}
                                                onPageChange={(e) => handlePageChange(e)}
                                                containerClassName={"pagination"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                  
                                    <div className='col-sm-3 col-md-3 text-md-end  pe-0'>
                                        <a onClick={passopenhistoryReportData} className="cmn--btn" style={{ cursor: "pointer" }}>
                                            <span style={{whiteSpace:"nowrap"}}>Download Report</span>
                                        </a>
                                    </div>
                                </div>

                                {/* Main Table */}
                                <div className="tab-content mt-height">
                                    <div className="description-table ob-table">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{ textAlign: "left" }}>Game</th>
                                                    <th style={{ textAlign: "left" }}>Event</th>
                                                    <th style={{ textAlign: "left" }}>Market</th>
                                                    <th style={{ textAlign: "left" }}>Nation</th>
                                                    <th style={{ textAlign: "left" }}>Side</th>
                                                    <th style={{ textAlign: "right" }}>Price</th>
                                                    <th style={{ textAlign: "right" }}>Odds</th>
                                                    <th style={{ textAlign: "right" }}>Amount</th>
                                                    {commisionShow && <th style={{ textAlign: "right" }}>Commission </th>}
                                                    <th style={{ textAlign: "left" }}>Date</th>
                                                </tr>
                                                {bthistory.map((history, indexx) => {
                                                    return (
                                                        <tr key={indexx} className="tb1">
                                                            <td style={{ textAlign: "left" }}>{history.gameCatName}</td>
                                                            <td style={{ textAlign: "left" }}>{history.eventName}</td>
                                                            <td style={{ textAlign: "left" }}>{history.marketHName}</td>
                                                            <td style={{ textAlign: "left" }}>{history.isMOMarket == true ? <td style={{ textAlign: "left" }}>{history.marketName} - {history.runnerName}</td> : <td>{history.marketName}</td>}</td>
                                                            <td style={{ textAlign: "left" }}><div className={history.side == 'B' ? "tbsec" : "tlsec"}>{history.side == 'B' ? 'Back' : 'Lay'}</div></td>
                                                            <td style={{ textAlign: "right" }}>{history.price}</td>
                                                            <td style={{ textAlign: "right" }}>{history.odds}</td>
                                                            <td style={{ textAlign: "right" }}>{ProtoTypes.Commas(history.amount)}</td>
                                                            {commisionShow && <td style={{ textAlign: "right" }}>{(history.commision).round(2)}</td>}
                                                            <td style={{ textAlign: "left" }}> {ProtoTypes.setLocalDate(history.createdOn)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {showtableError && (
                                            <div className="errorshow">
                                                <span>No Data Found!</span>
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
             
            </div>
        </>
    )
}

export default OpenHistory