import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import UserService from "../apis/UserService";
import { ToastContainer } from 'react-toastify';
import error from '../utilties/error';
import { useOutletContext } from "react-router-dom";
import ProtoTypes from '../utilties/ProtoTypes';
import { useNavigate } from 'react-router-dom';
import ReactModal from "react-modal-resizable-draggable";
import ApiService from '../apis/ApiService';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

var mBets = [];

let observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
        let oldValue = mutation.oldValue;
        let newValue = mutation.target.textContent;
        if (oldValue !== newValue) {
            var el = mutation.target.parentElement
            if (el) {
                var pEl = el.parentElement;
                var sEl = pEl && pEl.parentElement;
                if ((pEl && (pEl.classList.contains("bsec") || pEl.classList.contains("lsec")))) {
                    pEl.classList.add("Score--bounce");

                    setTimeout(() => {
                        pEl.classList.remove("Score--bounce");
                    }, 600);
                }
                else if (sEl && (sEl.classList.contains("num2") || sEl.classList.contains("num1"))) {
                    sEl.classList.add("Score--bounce");

                    setTimeout(() => {
                        sEl.classList.remove("Score--bounce");
                    }, 600);
                }

            }

        }
    });
});
observer.observe(document.body, {
    characterDataOldValue: true,
    subtree: true,
    childList: true,
    characterData: true
});

const Details = () => {
    const scoreBoardDataRef = useRef([])
    var bowlCount = 0;
    const inputRef = useRef(null)
    const inputRefresponsive = useRef(null)
    const teamAname = useRef()
    const teamBname = useRef()
    const currentplayerRef = useRef([])
    const batTeamDataRef = useRef({})
    const teamPlayersDataRef = useRef()
    const scorePadBatsmensRef = useRef()
    const [scoreBoardLoader, setscoreBoardLoader] = useState(false)
    const loaderInitialized = useRef(false)
    const [finalBowl, setfinalBowl] = useState()
    const [scoreBoardshow, setscoreboardshow] = useState(0)
    const [scoreBattingData, setScoreBattingData] = useState([])
    const [scoreBowlingData, setScoreBowlingData] = useState([])
    const [yetToBat, setYetToBat] = useState([])
    const [scoreBoardData, setScoreBoardData] = useState([])
    const [teamPlayersData, setteamPlayersData] = useState([])
    const [currentInning, setcurrentInning] = useState(0)
    const [teamScore, setTeamScore] = useState([])
    const [oversData, setOversData] = useState([])
    const [drsData, setDrsData] = useState([])
    const [matchStatusMsg, setMatchStatusMsg] = useState("")
    const [showimage, setshowimage] = useState(false)
    const [miniTv, setminiTv] = useState(false)
    const [showTvModal, setshowTvModal] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [TvUrl, setTvUrl] = useState()
    const [betsliploader, setbetsliploader] = useState(false)
    const [clickbetloader, setclickbetloader] = useState(false)
    const [COM, SETCOM] = useState()
    const { callApi, SetcallApi } = useOutletContext();
    const { sidebarDetails, SetsidebarDetails } = useOutletContext();
    const { loadStake } = useOutletContext();
    const [Loader, setLoader] = useState(true)
    const [isStake, setIsStake] = useState(loadStake)
    const [showprice, setshowprice] = useState(false)
    const [showchartDrpdwn, setChartDrpdwn] = useState(true)
    const [isLoad, setIsLoad] = useState(false)
    const [betSection, setBetSection] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [disabSec, setdisabSec] = useState("")
    const [showSingleClick, setShowSingleClick] = useState(false)
    const { state } = useLocation();
    const location = useLocation();
    const { query } = state || "";
    const [marketDetails, setmarketDetails] = useState([])
    const [betAmount, setBetAmount] = useState("")
    const [hdName, setHdName] = useState("")
    const [betError, setBetError] = useState("")
    const [betTab, setBetTab] = useState("2")
    const [popBetTab, setPopBetTab] = useState("2")
    const [moExp, setmoExp] = useState(0)
    const [mybets, setMyBets] = useState([])
    const [options, setOptions] = useState([])
    const [livemrkts, setLivemrkts] = useState([])
    const [liveNoti, setLiveNoti] = useState([])
    const [expData, setExpData] = useState([])
    const [MORnrsExp, setMORnrsExp] = useState([])
    const [liveRnrMO, setLiveRnrMO] = useState([])
    const [stakebuttons, SetStakebuttons] = useState([])
    const [singleClickedBtns, SetSingleClickedBtns] = useState([])
    const [liveRnrBM, setLiveRnrBM] = useState([])
    const [liveRnrGL, setLiveRnrGL] = useState([])
    const [BMTotal, setBMTotal] = useState([])
    const [evtDetail, setevtDetail] = useState({})
    const [orderDetail, setOrderDetail] = useState({})
    const [activeTab, setActiveTab] = useState("");
    const [betSPrice, setBetSPriceState] = useState("");
    const startPriceRef = useRef("");
    const [SsnTableData, setSsnTableData] = useState([]);
    const [betPercentage, setBetPercentage] = useState("");
    const [betCount, setBetCount] = useState(0);
    const [MOtotal, setMOtotal] = useState({
        R1: null,
        R2: null,
        R3: null,
    })

    const isLongPull = useRef(true);
    const isMDPull = useRef(true);
    const isscoreboardpull = useRef(true)
    const btmkt = useRef("");
    const btTb = useRef("2");
    const oldBets = useRef(0);
    const mxbet = useRef("");
    const mnbet = useRef("");
    const isBetLongPull = useRef(true);
    const mkidsArry = useRef([]);
    const dropdwnmkts = useRef([]);
    const mbts = useRef([]);

    const interval = useRef();
    const scoreboardinterval = useRef();
    const Notinterval = useRef();
    const betInterval = useRef();
    const MDInterval = useRef();
    const evtId = useRef();
    const catId = useRef(4);
    const contentWrapper = useRef(null);

    var mktdet = [];
    var MOlive = [];
    var BMlive = [];
    var GLlive = [];
    const navigate = useNavigate();
    // for Show Hide Scorecard
    const [buttonflag, setbuttonflag] = useState(true)
    const [button2flag, setbutton2flag] = useState(true)
    const [button3flag, setbutton3flag] = useState(true)
    const [buttonnumflag, setbuttonnumflag] = useState(3)

    const goalSplide = useRef();
    const livemrktsRef = useRef();

    useEffect(() => {

        return () => {
            loaderInitialized.current = false
        }

    }, [])

    useEffect(() => {
        startPriceRef.current = betSPrice
    }, [betSPrice]);

    useEffect(() => {
        window.scrollTo(0, 0);
        mktdet = []
        MOlive = [];
        BMlive = [];
        localStorage.setItem("reload", "false")

        return () => {
            isLongPull.current = false;
            isMDPull.current = false;
            isBetLongPull.current = false;
            isscoreboardpull.current = false;
            clearTimeout(interval.current);
            clearTimeout(Notinterval.current);
            clearTimeout(betInterval.current);
            clearTimeout(MDInterval.current);
            clearTimeout(scoreboardinterval.current);
        }
    }, []);

    useEffect(() => {
        async function calAyncFunction() {
            var stakebuttondata = {
                getAll: true,
                SingleClicked: true
            }
            await GetStakeButtons(stakebuttondata);
        }
        calAyncFunction();
    }, [loadStake]);

    useEffect(() => {
        window.onpopstate = () => {
            if (location.pathname == "/details") {
                window.location.reload()
            }
        }
    }, [location]);


    useEffect(() => {
        async function calAyncFunction() {
            if (query) {
                var bytes = CryptoJS.AES.decrypt(query, 'key@123');
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                SetsidebarDetails(decryptedData)
                if (decryptedData.isload) {
                    var isreload = localStorage.getItem("reload")

                    if (isreload == "true") {
                        window.location.reload();
                    }
                }
                evtId.current = decryptedData.eventId;
                catId.current = decryptedData.catId;
                setevtDetail(decryptedData)
                isLongPull.current = true;
                isBetLongPull.current = true;
                isMDPull.current = true;
                isscoreboardpull.current = true;
                await getPollMDetails(decryptedData.eventId)
                await getMarketDetails(decryptedData.eventId)
                // await CZScoreboardData()
                var stkrqst = {
                    "singleClicked": true,
                    "getAll": true
                }
                await GetStakeButtons(stkrqst);
                await GetBets();

            } else {
                navigate('/')
            }
        }
        calAyncFunction();

    }, [query]);


    const CZScoreboardData = async () => {
        try {
            await ApiService.CZScoreboard(evtId.current).then(res => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        var rs = res.data.result
                        if (rs != null) {
                            var statusMsg = res.data.result.status
                            var rsltmsg = rs.result
                            if (statusMsg == "NotStarted") {
                                setscoreboardshow(1)
                                setMatchStatusMsg("Event not started yet.")
                            } else if (statusMsg == "Closed") {
                                setMatchStatusMsg(rsltmsg)
                                setscoreboardshow(1)
                            } else if (statusMsg == "NotExist") {
                                setscoreboardshow(0)
                            }
                            else if (statusMsg == "Inplay") {
                                setscoreboardshow(2)
                                if (!loaderInitialized.current) {
                                    loaderInitialized.current = true;
                                    setscoreBoardLoader(true);
                                }

                                teamAname.current = rs.teamA
                                teamBname.current = rs.teamB
                                let battingTeam = ""
                                let bowlingTeam = ""
                                if (rs.eventScores != null) {
                                    var eventScores = rs.eventScores;
                                    var tempEventScores = [];
                                    var battingScore = eventScores.find(s => s.isBattingTeam);
                                    if (battingScore)
                                        tempEventScores.push(battingScore);

                                    var nBattingScore = eventScores.filter(s => !s.isBattingTeam);
                                    if (nBattingScore)
                                        tempEventScores = tempEventScores.concat(nBattingScore);
                                    setTeamScore(tempEventScores)


                                    for (let index = 0; index < rs.eventScores?.length; index++) {
                                        const element = rs.eventScores[index];
                                        if (element.isBattingTeam) {
                                            battingTeam = element.teamName;
                                            batTeamDataRef.current = {
                                                "Wicket": element.teamWicket,
                                                "TeamName": battingTeam,
                                                "Pshipscore": element.teamPartnershipScore,
                                                "TeamScore": element.teamScore,
                                                "MatchOver": element.matchOvers,
                                            }

                                        } else {
                                            bowlingTeam = element.teamName
                                        }
                                    }
                                }

                                // setPlayerRecords(scorePlayers)

                                let battingdata = [];
                                let batplayer = rs.scorePlayers.find(a => a.teamName == battingTeam);
                                if (batplayer) {
                                    currentplayerRef.current = battingdata = batplayer.battingPlayers.filter(a => a.currentyPlaying == true);
                                }

                                setScoreBattingData(battingdata)

                                let currBowler = [];
                                let bowlerData = rs.scorePlayers.find(a => a.teamName == battingTeam);
                                if (bowlerData) {
                                    let bowlers = bowlerData.bowlingPlayers;
                                    currBowler = bowlers.filter(s => s.isCurrentPlayed);

                                }

                                setScoreBowlingData(currBowler);
                                if (rs.teamPlayers != null) {
                                    setteamPlayersData(rs.teamPlayers)
                                }

                                if (rs.eveReview) {
                                    setDrsData(rs.eveReview.eventReviews)
                                }


                                setScoreBoardData(rs.scorePlayers);
                                scoreBoardDataRef.current = rs.scorePlayers

                                teamPlayersDataRef.current = rs.teamPlayers
                                scorePadBatsmensRef.current = rs.scorePlayers
                                SetYetToPlay();

                                //Current Bowl
                                if (rs.eventLastOvers != null) {
                                    setOversData(rs.eventLastOvers)
                                    if (rs.eventLastOvers.length > 0) {
                                        var cbowl = rs.eventLastOvers[0].overHistory;
                                        var splitcbowl = cbowl.trim().split(" ");
                                        var finlbowl = splitcbowl[splitcbowl.length - 1]

                                        setfinalBowl(finlbowl)
                                        const container = document.getElementsByClassName('ballruns');
                                        if (bowlCount.length !== cbowl.trim().length && container.length > 0) {
                                            bowlCount = cbowl.trim()

                                            for (let index = 0; index < container.length; index++) {
                                                const element = container[index];
                                                element.scrollLeft = element.scrollWidth - element.clientWidth;
                                            }
                                        }

                                    }
                                }
                            }
                            setscoreBoardLoader(false);
                        }

                        if (isscoreboardpull.current) {
                            scoreboardinterval.current = setTimeout(async () => {
                                await CZScoreboardData();
                            }, 1000);
                        }
                    }
                    else {
                        error.errorToastify(msg)
                    }
                }
            })
        }
        catch (err) {
            console.log(err);
        }
    }

    const getMarketDetails = async (eventId) => {
        try {
            var rqstData = {
                "EventId": eventId
            }
            await UserService.GetLMarketDetails(rqstData).then(async (res) => {
                setLoader(false)
                if (res && res.data.responseCode) {

                    var msg = res.data.response;
                    if (msg == "Success") {
                        setmarketDetails(res.data.result);
                        mktdet = res.data.result;
                        var alldata = res.data.result;
                        if (alldata.length > 0) {
                            var mrktsArry = [];
                            for (let index = 0; index < alldata.length; index++) {
                                const element = alldata[index].liveMarkets;
                                for (let i = 0; i < element.length; i++) {
                                    const childelement = element[i];
                                    if (childelement.marId)
                                        mrktsArry.push(childelement.marId);
                                }
                            }
                            await GetFLMarkets(mkidsArry.current);
                            await GetMNotifications(mkidsArry.current);
                        }
                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }
    }

    const getPollMDetails = async (eventId) => {
        try {
            var rqstData = {
                "EventId": eventId
            }
            await UserService.GetLMarketDetails(rqstData).then(async (res) => {
                setLoader(false)
                if (res && res.data.responseCode) {

                    var msg = res.data.response;
                    if (msg == "Success") {
                        setmarketDetails(res.data.result);
                        mktdet = res.data.result;
                        var alldata = res.data.result;
                        if (alldata.length > 0) {
                            var mrktsArry = [];
                            for (let index = 0; index < alldata.length; index++) {
                                const element = alldata[index].liveMarkets;
                                for (let i = 0; i < element.length; i++) {
                                    const childelement = element[i];
                                    if (childelement.marId)
                                        mrktsArry.push(childelement.marId);
                                }
                            }
                            mkidsArry.current = mrktsArry;
                        }
                        if (isMDPull.current) {
                            MDInterval.current = setTimeout(async () => {
                                await getPollMDetails(eventId);
                            }, 500);
                        }

                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }
    }

    function kFormatter(num) {
        return Math.abs(num) > 999 && Math.abs(num) < 99999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.abs(num) > 99999 ? Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1)) + 'L' : Math.sign(num) * Math.abs(num)
    }

    const GetFLMarkets = async (data) => {
        try {
            var rqstData = {
                "Markets": data
            }
            await UserService.GetFLMarkets(rqstData).then(async (res) => {
                setLoader(false)
                if (res && res.data.responseCode) {

                    var msg = res.data.response;
                    if (msg == "Success") {
                        var LIvmkst = res.data.result;
                        setLivemrkts(res.data.result);
                        livemrktsRef.current = res.data.result;

                        var moElemt = mktdet.find(s => s.marketHName == "Match Odds");
                        if (moElemt && moElemt.liveMarkets && moElemt.liveMarkets.length > 0)
                            var moid = moElemt.liveMarkets[0].marId
                        var mo = LIvmkst.find(s => s.marketID == moid);

                        if (mo) {
                            setLiveRnrMO(mo)
                            MOlive = mo
                        }

                        var midElemt = mktdet.find(s => s.marketHName == "Bookmaker");
                        if (midElemt && midElemt.liveMarkets && midElemt.liveMarkets.length > 0) {
                            var Bmarray = [];
                            for (let i = 0; i < midElemt.liveMarkets.length; i++) {
                                const element = midElemt.liveMarkets[i];
                                var mid = element.marId
                                var bm = LIvmkst.find(s => s.marketID == mid);
                                if (bm)
                                    Bmarray.push(bm)
                            }
                            setLiveRnrBM(Bmarray)
                            BMlive = Bmarray;
                        }

                        var glElemt = mktdet.find(s => s.marketHName == "Goal Lines");
                        if (glElemt && glElemt.liveMarkets && glElemt.liveMarkets.length > 0) {
                            var Glarray = [];
                            for (let i = 0; i < glElemt.liveMarkets.length; i++) {
                                const element = glElemt.liveMarkets[i];
                                var mid = element.marId
                                var gl = LIvmkst.find(s => s.marketID == mid);
                                if (gl)
                                    Glarray.push(gl)
                            }
                            setLiveRnrGL(Glarray)
                            GLlive = Glarray;
                        }

                        if (isLongPull.current) {
                            interval.current = setTimeout(async () => {

                                await GetFLMarkets(mkidsArry.current);
                            }, 300);
                        }

                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }
    }

    const GetMNotifications = async (data) => {
        try {
            var rqstData = {
                "Markets": data,
                EventId: evtId.current,
            }
            await UserService.GetMNotifications(rqstData).then(async (res) => {
                setLoader(false)
                if (res && res.data.responseCode) {

                    var msg = res.data.response;
                    if (msg == "Success") {
                        var not = res.data.result.mNotifications;
                        setLiveNoti(not);
                        if (isLongPull.current) {
                            Notinterval.current = setTimeout(async () => {
                                await GetMNotifications(mkidsArry.current);
                            }, 700);
                        }

                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }
    }

    const setorderDetails = (maxbet, minbet, mo, rnr, hdr, typ, mktlm, i, nmbr, allmkt = []) => {
        if (hdr == "Bookmaker") {
            maxbet = mo.maxBet
        }
        
        setTimeout(() => {
            inputRef.current?.focus()
        }, 50);

        setInterval(() => {
            inputRefresponsive.current?.focus()
        }, 50);

        if (maxbet)
            mxbet.current = maxbet;
        else
            mxbet.current = "";

        if (minbet)
            mnbet.current = minbet;
        else
            mnbet.current = "";

        var backPrice;
        var layPrice;
        let indx = 0;
        if (nmbr == 1) {
            backPrice = mo.backPrice1;
            layPrice = mo.layPrice1;
        }
        else if (nmbr == 2) {
            backPrice = mo.backPrice2;
            layPrice = mo.layPrice2;
        }
        else if (nmbr == 3) {
            backPrice = mo.backPrice3;
            layPrice = mo.layPrice3;
        }
        else {
            backPrice = mo.backPrice;
            layPrice = mo.layPrice;
        }

        indx = nmbr;

        if (nmbr == undefined) {
            backPrice = mo.backPrice;
            layPrice = mo.layPrice;
            indx = 0;
        }

        if (typ == "B") {
            if (backPrice == 0)

                return

        } else if (typ == "L") {
            if (layPrice == 0)

                return
        }

        setOrderDetail({
            marketHName: hdr,
            price: typ == "B" ? backPrice : layPrice,
            eventName: evtDetail.eventName,
            runnerName: rnr,
            lmMarketrName: rnr,
            type: typ,
            mktye: mktlm,
            runnerId: mo.runnerId,
            marketId: mo.marketId,
            odds: typ == "B" ? mo.backOdds : mo.layOdds,
            rno: i + 1,
            rIndx: indx
        })
        setmoExp(0)
        setBetError("")
        setBetAmount("")

        var tempAr = []

        if (hdr == "Match Odds") {

            for (let i = 0; i < allmkt.length; i++) {
                const el = allmkt[i];
                tempAr.push({ rnr: el.runnerName, moex: 0 })
            }
            setMORnrsExp(tempAr)
        }
        if (showSingleClick) {

            var rqstData = {};
            if (hdr == "Match Odds") {
                rqstData = {
                    EventId: evtId.current,
                    MarketId: mo.marketId,
                    MoRunnerId: mo.runnerId,
                    Price: typ == "B" ? backPrice : layPrice,
                    Odds: typ == "B" ? mo.backOdds : mo.layOdds,
                    Amount: activeTab,
                    Side: typ,
                    MoRunnerIndex: indx
                }
            }
            else {
                rqstData = {
                    EventId: evtId.current,
                    MarketId: mo.marketId,
                    RunnerId: mo.runnerId,
                    Price: typ == "B" ? backPrice : layPrice,
                    Odds: typ == "B" ? mo.backOdds : mo.layOdds,
                    Amount: activeTab,
                    Side: typ,
                    MoRunnerIndex: indx
                }
            }
            SinglePlaceBet(rqstData, 1);
            setBetSection(false)
        } else {
            setBetTab("1")
            setBetSection(true)
        }


        var isDisplayEl = document.getElementById("#tr");
        if (isDisplayEl && !showSingleClick) {
            if (window.getComputedStyle(isDisplayEl).display === "none") {
                isDisplayEl.click()
            }
        }
    }

    const pressSCKey = (event) => {
        if (event.key === "Enter") {
            placeBet();
        }
    };

    const placeBet = async (e, typ) => {
        setclickbetloader(false)
        setbetsliploader(true)
        try {
            e.preventDefault()
            if (betAmount == "") {
                setBetError("Amount is mandatory!")
                setbetsliploader(false)
                return
            }

            var rqstData = {};
            if (orderDetail.marketHName == "Match Odds") {
                rqstData = {
                    EventId: evtId.current,
                    MarketId: orderDetail.marketId,
                    MoRunnerId: orderDetail.runnerId,
                    Price: orderDetail.price,
                    Odds: orderDetail.odds,
                    Amount: betAmount,
                    Side: orderDetail.type,
                    MoRunnerIndex: orderDetail.rIndx
                }
            }
            else {
                rqstData = {
                    EventId: evtId.current,
                    MarketId: orderDetail.marketId,
                    RunnerId: orderDetail.runnerId,
                    Price: orderDetail.price,
                    Odds: orderDetail.odds,
                    Amount: betAmount,
                    Side: orderDetail.type,
                    MoRunnerIndex: orderDetail.rIndx
                }
            }
            setIsLoad(true)
            // setbetsliploader(true)
            await SinglePlaceBet(rqstData, typ)

        } catch (error) {
            setIsLoad(false)
            console.log(error)
        }

    }

    const SinglePlaceBet = async (rqstData, typ) => {

        if (showSingleClick == false) {
            setclickbetloader(false)
            setbetsliploader(true)
        } else if (showSingleClick == true) {
            setclickbetloader(true)
            setbetsliploader(false)
        }
        try {
            setIsLoad(true)
            if (showSingleClick == true) {
                setdisabSec("disabled")
            }
            await UserService.AddBet(rqstData).then(async (res) => {
                if (showSingleClick == true) {
                    setTimeout(() => {
                        setdisabSec("")
                    }, 3000);
                }
                setIsLoad(false)
                setbetsliploader(false)
                setclickbetloader(false)
                setBetError("")
                setBetAmount("")

                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        setclickbetloader(false)
                        setbetsliploader(false)
                        error.successToastify(msg);
                        SetcallApi(!callApi);
                        setBetSection(false)
                        if (typ == 1) {

                            if (orderDetail.marketHName == "Match Odds") {
                                await openMyBet();
                            }
                            else {
                                btmkt.current = rqstData.MarketId

                                if (btTb.current == "2") {
                                    await openMyBet();
                                }
                                else if (btTb.current == "3") {
                                    await openMyBet();
                                    // setTimeout(() => {
                                    //     openBetChart()
                                    // }, 1000);
                                }
                            }
                        } else {
                            const liEelment = document.getElementById('close-modal');
                            if (liEelment)
                                liEelment.click();
                        }
                    }
                    else {
                        error.errorToastify(res.data.response)
                        inputRef.current?.focus()
                        inputRefresponsive.current?.focus()
                        if (res.data.responseCode == 120)
                            setBetAmount(mxbet.current)                        
                        else if (res.data.responseCode == 119)
                            setBetAmount(mnbet.current)                        
                        else if (res.data.responseCode == 123 || res.data.responseCode == 124 || res.data.responseCode == 152)
                            setBetSection(false);                        

                        if(res.data.responseCode != 120 && res.data.responseCode != 119){
                            const liEelment = document.getElementById('close-modal');
                            if (liEelment)
                                liEelment.click();
                        }
                    }

                }
            }).catch(error => {
                console.log(error)
                setIsLoad(false)
                setbetsliploader(false)
                setclickbetloader(false)
                setdisabSec("")
            })

        } catch (error) {
            setIsLoad(false)
            setbetsliploader(false)
            setclickbetloader(false)
            setdisabSec("")
            console.log(error)
        }
    }

    const GetStakeButtons = async (data) => {
        try {
            await UserService.GetStakeButtons(data).then(async (res) => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        var rs = res.data.result
                        if (rs && rs.length > 0) {
                            var stakebtns = rs.filter(s => !s.singleClicked);

                            SetStakebuttons(stakebtns);
                            var singleclickedbtns = rs.filter(s => s.singleClicked);
                            SetSingleClickedBtns(singleclickedbtns);
                            if (singleclickedbtns && singleclickedbtns.length > 0) {
                                var actTab = singleclickedbtns.find(s => s.isSelected);
                                setActiveTab(actTab.stakeValue);
                            }
                        }
                    }
                    else {
                        error.errorToastify(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }

    }

    const GetBets = async () => {
        try {
            var rqstData = {
                EventId: evtId.current,
            }

            await UserService.GetBets(rqstData).then(async (res) => {
                if (res && res.data.responseCode) {

                    var msg = res.data.response;
                    if (msg == "Success") {
                        var rs = res.data.result.betHistories
                        let sts = res.data.result.eventStatus;
                        if (sts == "Closed")
                            navigate("/")

                        var totalcm = 0
                        var tt = []
                        for (var i = 0; i < rs.length; i++) {
                            var ts = rs[i].betHistories;
                            for (var ind = 0; ind < ts.length; ind++) {
                                if (!ts[ind].isCancelled) {
                                    totalcm += ts[ind].commision;
                                }
                                tt.push(ts[ind])
                            }
                        }
                        setBetCount(tt.length)
                        var optArr = [];
                        for (let index = 0; index < mkidsArry.current.length; index++) {
                            const el = mkidsArry.current[index];
                            const found = tt.find(a => a.marketId == el);
                            if (found && !found.isMOMarket) {
                                const isExist = optArr.find(a => a.marId == el);
                                if (isExist == undefined)
                                    optArr.push({ marId: el, marketName: found.marketName, headerType: found.headerType })
                            }

                        }
                        setOptions(optArr)
                        dropdwnmkts.current = optArr;

                        if (!optArr.find(s => s.marId == btmkt.current)) {
                            onHdChange("")
                        } else if (btTb.current == "3") {
                            onHdChange(btmkt.current)
                        }
                        SETCOM(totalcm)
                        if (totalcm == 0) {
                            SETCOM(totalcm)
                        }
                        setMyBets(rs);
                        mbts.current = rs
                        if (btTb.current == "3" && (oldBets.current < tt.length)) {
                            // setBetTab("3")
                            // onHdChange(btmkt.current)
                        }

                        oldBets.current = tt.length
                        if (isBetLongPull.current) {
                            betInterval.current = setTimeout(async () => {
                                GetBets();
                            }, 1000);
                        }
                        GetTotalPl(rs);
                    }
                    else {
                        error.errorToastify(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }

    }

    const GetTotalPl = (data) => {
        var moEl = data.find(s => s.headerName == "Match Odds");
        if (moEl) {
            var tempArray = { R1: 0, R2: 0, R3: 0 };
            for (let i = 0; i < moEl.betHistories.length; i++) {
                const element = moEl.betHistories[i];
                if (element.isCancelled)
                    continue;

                var t1;
                var t2;
                var t3;

                var totalLvEl = liveRnrMO.liveMORunners
                var index;
                if (totalLvEl) {
                    index = totalLvEl.indexOf(totalLvEl.find(s => s.runnerId == element.runnerId));
                } else {
                    index = MOlive.liveMORunners.indexOf(MOlive.liveMORunners.find(s => s.runnerId == element.runnerId));

                }
                if (index != -1) {

                    if (index == 0) {
                        if (element.side == "B") {
                            t1 = ((element.price - 1) * (element.odds / 100) * element.amount)
                            t2 = -element.amount;
                            t3 = -element.amount;

                        } else if (element.side == "L") {
                            t1 = -((element.price - 1) * (element.odds / 100) * element.amount)
                            t2 = element.amount;
                            t3 = element.amount;
                        }
                    }
                    else if (index == 1) {
                        if (element.side == "B") {
                            t1 = -element.amount;
                            t2 = ((element.price - 1) * (element.odds / 100) * element.amount);
                            t3 = -element.amount;

                        } else if (element.side == "L") {
                            t1 = element.amount;
                            t2 = -((element.price - 1) * (element.odds / 100) * element.amount);
                            t3 = element.amount;
                        }
                    }
                    else if (index == 2) {
                        if (element.side == "B") {
                            t1 = -element.amount;
                            t2 = -element.amount;
                            t3 = ((element.price - 1) * (element.odds / 100) * element.amount);

                        } else if (element.side == "L") {
                            t1 = element.amount;
                            t2 = element.amount;
                            t3 = -((element.price - 1) * (element.odds / 100) * element.amount);
                        }
                    }
                    tempArray.R1 += t1;
                    tempArray.R2 += t2;
                    tempArray.R3 += t3;
                }
            }

            // setMOtotal({
            //     R1: prototype.Commas(tempArray.R1),
            //     R2:  prototype.Commas(tempArray.R2),
            //     R3:  prototype.Commas(tempArray.R3)
            // })
            // setMOtotal({
            //     R1: ProtoTypes.Round(tempArray.R1),
            //     R2:   ProtoTypes.Round(tempArray.R2),
            //     R3:   ProtoTypes.Round(tempArray.R3)
            // })

            setMOtotal({
                R1: tempArray.R1,
                R2: tempArray.R2,
                R3: tempArray.R3
            })
        }

        var BmkEl = data.find(s => s.headerName == "Bookmaker");
        if (BmkEl) {
            var TotalArray = [];
            for (let i = 0; i < BmkEl.betHistories.length; i++) {
                var tempobj = {};
                const element = BmkEl.betHistories[i];
                if (element.isCancelled)
                    continue;

                var t1;
                var t2;
                var t3;

                var totalmarket = liveRnrBM
                var market;
                if (totalmarket.length > 0)
                    market = liveRnrBM.find(s => s.marketID == element.marketId)
                else
                    market = BMlive.find(s => s.marketID == element.marketId)

                var index = -1;
                if (market != undefined)
                    index = market.liveMRunners.indexOf(market.liveMRunners.find(s => s.runnerId == element.runnerId));
                if (index != -1) {

                    if (index == 0) {
                        if (element.side == "B") {
                            t1 = ((element.price / 100) * (element.odds / 100) * element.amount)
                            t2 = -element.amount;
                            t3 = -element.amount;

                        } else if (element.side == "L") {
                            t1 = -((element.price / 100) * (element.odds / 100) * element.amount)
                            t2 = element.amount;
                            t3 = element.amount;
                        }
                    }
                    else if (index == 1) {
                        if (element.side == "B") {
                            t1 = -element.amount;
                            t2 = ((element.price / 100) * (element.odds / 100) * element.amount);
                            t3 = -element.amount;

                        } else if (element.side == "L") {
                            t1 = element.amount;
                            t2 = -((element.price / 100) * (element.odds / 100) * element.amount);
                            t3 = element.amount;
                        }
                    }
                    else if (index == 2) {
                        if (element.side == "B") {
                            t1 = -element.amount;
                            t2 = -element.amount;
                            t3 = ((element.price / 100) * (element.odds / 100) * element.amount);

                        } else if (element.side == "L") {
                            t1 = element.amount;
                            t2 = element.amount;
                            t3 = -((element.price / 100) * (element.odds / 100) * element.amount);
                        }
                    }

                    tempobj.R1 = ProtoTypes.Round(t1);
                    tempobj.R2 = ProtoTypes.Round(t2);
                    tempobj.R3 = ProtoTypes.Round(t3);
                    tempobj.marketId = market.marketID

                    var isExist = TotalArray.find(s => s.marketId == market.marketID)
                    if (isExist) {
                        isExist.R1 += ProtoTypes.Round(t1);
                        isExist.R2 += ProtoTypes.Round(t2);
                        isExist.R3 += ProtoTypes.Round(t3);
                    }
                    else {
                        TotalArray.push(tempobj)
                    }
                }
            }
            setBMTotal(TotalArray)
        }

        var NmEl = data.filter(s => s.headerName != "Match Odds" && s.headerName != "Bookmaker");
        if (NmEl) {
            getExposure(NmEl)
        }

    }

    const getExposure = (NmEl) => {
        var tempArray = [];

        for (let i = 0; i < dropdwnmkts.current.length; i++) {

            const el = dropdwnmkts.current[i];
            let orders = [];
            let countRecord = 0
            for (let index = 0; index < NmEl.length; index++) {
                let betHistories = NmEl[index].betHistories.filter(a => a.marketId == el.marId && !a.isCancelled);
                if (betHistories != undefined && betHistories.length > 0) {
                    orders = betHistories;
                    countRecord = betHistories.length
                    break;
                }
            }

            tempArray.push({ marId: el.marId, exposure: calLmExp(el.marId, orders), count: countRecord });

        }

        setExpData(tempArray);
    }

    const calLmExp = (mid, orders) => {
        var exp = 0;
        if (orders.length > 0) {
            let min = orders.reduce((min, p) => p.price < min ? p.price : min, orders[0].price);
            let max = orders.reduce((max, p) => p.price > max ? p.price : max, orders[0].price);
            let tmpArr = []

            if (orders[0].headerType != "Spread") {
                for (let i = 0; i < (max - min) + 2; i++) {
                    let StartRuns = (min - 1) + i
                    let TotalPL = 0.00;
                    let Percentage = 1.0;
                    for (let idxOrder = 0; idxOrder < orders.length; idxOrder++) {
                        let _order = orders[idxOrder];
                        let odds = (parseFloat(_order.odds / 100))
                        if (_order.isCancelled)
                            continue;
                        if (_order.headerType == "Spread") {
                            if (_order.side == "L") {
                                TotalPL += (_order.price - StartRuns) * _order.amount
                            }
                            else if (_order.side == "B") {
                                TotalPL += (StartRuns - _order.price) * _order.amount
                            }
                        } else {

                            if (_order.side == "L") {
                                if (StartRuns < _order.price) {
                                    TotalPL += (_order.amount * Percentage);
                                }
                                else {
                                    TotalPL -= (_order.amount * odds * Percentage);
                                }
                            }
                            else if (_order.side == "B") {
                                if (StartRuns >= _order.price) {
                                    TotalPL += (_order.amount * odds * Percentage);
                                }
                                else {
                                    TotalPL -= (_order.amount * Percentage);
                                }
                            }
                        }

                    }
                    tmpArr.push(TotalPL)
                }

                exp = Math.min(...tmpArr);
            }
            else {
                //Spread market

                let cRuns = -1;
                //Get & Set Market Current Runs
                //From Score
                // cRuns = getCurrentRuns(orders[0].marketName.toLowerCase(), orders[0].marketTName);
                
                const curRuns = livemrktsRef.current?.find(s => (s.marketID).toString() === mid)?.curRuns;
                if(curRuns){
                    cRuns=curRuns;
                };

                //Check for single entry or back only
                let backOnly = orders.filter(a => a.side == "B");
                if (cRuns == -1 && (orders.length == 1 || backOnly.length == orders.length)) {
                    let TotalPL = 0.00;
                    for (let idxOrder = 0; idxOrder < orders.length; idxOrder++) {
                        let _order = orders[idxOrder];
                        //let odds = (parseFloat(_order.odds / 100))

                        if (_order.side == "L") {
                            let temPrice = _order.price > _order.minRange || _order.minRange == 0 ? _order.price : _order.minRange;
                            TotalPL -= _order.amount * temPrice;
                        }
                        else if (_order.side == "B") {
                            let temPrice = _order.price > _order.maxRange || _order.maxRange == 0 ? _order.price : _order.maxRange;
                            TotalPL -= _order.amount * temPrice;
                        }
                    }
                    exp = TotalPL;
                }
                else {
                    //Multiple entries
                    let maxRange = orders[0].minRange == 0 || orders[0].minRange < max ? max : orders[0].minRange;
                    let minRange = cRuns != -1 ? cRuns : 0;
                    if (minRange != 0 && minRange > maxRange)
                        maxRange = minRange;

                    for (let i = minRange; i <= maxRange; i++) {
                        let StartRuns = i;
                        let TotalPL = 0.00;
                        for (let idxOrder = 0; idxOrder < orders.length; idxOrder++) {
                            let _order = orders[idxOrder];

                            if (_order.side == "L") {
                                TotalPL += (_order.price - StartRuns) * _order.amount
                            }
                            else if (_order.side == "B") {
                                TotalPL += (StartRuns - _order.price) * _order.amount
                            }
                        }

                        tmpArr.push(TotalPL)
                    }

                    exp = Math.min(...tmpArr);
                }
            }
        }

        // if (exp > 0)
        //     exp = 0;

        return exp;
        // return ProtoTypes.Commas(exp);
    }

    const getCurrentRuns = (mrktname, mrktTName) => {
        let cRuns = -1;
        if (mrktTName == "Innings Run") {
            if (batTeamDataRef.current.TeamName !== undefined) {
                var batTeamShrt = shortTeamName(batTeamDataRef.current.TeamName.toLowerCase());
                if (mrktname.includes(batTeamDataRef.current.TeamName.toLowerCase()) || mrktname.includes(batTeamShrt)) {
                    return batTeamDataRef.current.teamScore;
                }
            }

        }
        else if (mrktTName == "Players") {
            if (currentplayerRef.current.length > 0) {
                var player = currentplayerRef.current.find(s => mrktname.includes(s.playerName.toLowerCase()));
                // if (!player) {
                //     player = currentplayerRef.current.find(s => s.playerName.toLowerCase() + " runs" == mrktname);
                // }

                if (player) {
                    return player.runs;
                }
            }

        }
        else if (mrktTName == "FOW") {
            if (batTeamDataRef.current.TeamName !== undefined) {
                if (mrktname.includes("fall of") && mrktname.includes(batTeamDataRef.current.TeamName.toLowerCase())) {
                    var currWkt = batTeamDataRef.current.Wicket + 1;
                    currWkt = currWkt == 1 ? "1st" : currWkt;
                    if (mrktname.includes(currWkt))
                        return batTeamDataRef.current.Pshipscore;
                }
            }
        }

        return cRuns;
    }

    const openBetSlip = () => {
        setBetSection(false)
        setBetTab("1")
    }

    const openMyBet = async () => {
        setBetTab("2")
        btTb.current = "2"
    }

    const openBetChart = async () => {
        onHdChange(btmkt.current)
        btTb.current = "3"
        setBetTab("3")
    }

    const checkNumber = (event) => {
        const result = event.target.value.replace(/[^0-9]/g, '');
        setBetAmount(result)
        CalRnnrVal(result)
    }

    const setManualBetAmt = (amnt) => {
        inputRef.current?.focus()
        inputRefresponsive.current?.focus()
        setBetAmount(amnt)
        CalRnnrVal(amnt)
    }

    const CalRnnrVal = (amnt) => {

        var element = {
            side: orderDetail.type,
            price: orderDetail.price,
            odds: orderDetail.odds,
            amount: parseFloat(amnt),
        }
        var t1;
        var t2;
        var t3;

        if (orderDetail.marketHName == "Match Odds") {
            if (orderDetail.rno == 1) {
                if (element.side == "B") {
                    t1 = ((element.price - 1) * (element.odds / 100) * element.amount)
                    t2 = -element.amount;
                    t3 = -element.amount;

                } else if (element.side == "L") {
                    t1 = -((element.price - 1) * (element.odds / 100) * element.amount)
                    t2 = element.amount;
                    t3 = element.amount;
                }
            }
            else if (orderDetail.rno == 2) {
                if (element.side == "B") {
                    t1 = -element.amount;
                    t2 = ((element.price - 1) * (element.odds / 100) * element.amount);
                    t3 = -element.amount;

                } else if (element.side == "L") {
                    t1 = element.amount;
                    t2 = -((element.price - 1) * (element.odds / 100) * element.amount);
                    t3 = element.amount;
                }
            }
            else if (orderDetail.rno == 3) {
                if (element.side == "B") {
                    t1 = -element.amount;
                    t2 = -element.amount;
                    t3 = ((element.price - 1) * (element.odds / 100) * element.amount);

                } else if (element.side == "L") {
                    t1 = element.amount;
                    t2 = element.amount;
                    t3 = -((element.price - 1) * (element.odds / 100) * element.amount);
                }
            }



            var tempAr = []

            for (let index = 0; index < MORnrsExp.length; index++) {
                const el = MORnrsExp[index];
                if (index == 0)
                    tempAr.push({ rnr: el.rnr, moex: t1 ? t1.toFixed(2) : 0 })
                else if (index == 1)
                    tempAr.push({ rnr: el.rnr, moex: t2 ? t2.toFixed(2) : 0 })
                else if (index == 2)
                    tempAr.push({ rnr: el.rnr, moex: t3 ? t3.toFixed(2) : 0 })
            }

            setMORnrsExp(tempAr)

            var array = [t1, t2, t3]

            var maxValue = Math.max(...array);
            setmoExp(maxValue)

        }

        else if (orderDetail.marketHName == "Bookmaker") {

            if (orderDetail.rno == 1) {
                if (element.side == "B") {
                    t1 = ((element.price / 100) * (element.odds / 100) * element.amount)
                    t2 = -element.amount;
                    t3 = -element.amount;

                } else if (element.side == "L") {
                    t1 = -((element.price / 100) * (element.odds / 100) * element.amount)
                    t2 = element.amount;
                    t3 = element.amount;
                }
            }
            else if (orderDetail.rno == 2) {
                if (element.side == "B") {
                    t1 = -element.amount;
                    t2 = ((element.price / 100) * (element.odds / 100) * element.amount);
                    t3 = -element.amount;

                } else if (element.side == "L") {
                    t1 = element.amount;
                    t2 = -((element.price / 100) * (element.odds / 100) * element.amount);
                    t3 = element.amount;
                }
            }
            else if (orderDetail.rno == 3) {
                if (element.side == "B") {
                    t1 = -element.amount;
                    t2 = -element.amount;
                    t3 = ((element.price / 100) * (element.odds / 100) * element.amount);

                } else if (element.side == "L") {
                    t1 = element.amount;
                    t2 = element.amount;
                    t3 = -((element.price / 100) * (element.odds / 100) * element.amount);
                }
            }

            var mktEl = BMTotal.find(s => s.marketId == orderDetail.marketId);

            var array = [t1, t2, t3]

            var maxValue = Math.max(...array);
            setmoExp(maxValue)

        }


    }

    const checkclass = (num) => {
        if (num > 0)
            return "positive"
        else
            return "negative"
    }

    const getBMTotal = (id, index) => {
        var result = "";
        var el = BMTotal.find(s => s.marketId == id);
        if (el) {
            if (index == 0)
                result = el.R1;
            if (index == 1)
                result = el.R2;
            if (index == 2)
                result = el.R3;
        }
        if (result === 0)
            return 0
        else if (result == "")
            return null

        return ProtoTypes.Commas(result)
    }

    const getBMTotalClass = (id, index) => {
        var result = "";
        var el = BMTotal.find(s => s.marketId == id);
        if (el) {
            if (index == 0)
                result = el.R1;
            if (index == 1)
                result = el.R2;
            if (index == 2)
                result = el.R3;

        }

        parseFloat(result)
        if (result > 0)
            return "positive"
        else
            return "negative"
    }

    const setSingleClick = async (val, i) => {

        for (let index = 0; index < singleClickedBtns.length; index++) {
            const element = singleClickedBtns[index];
            if (index == i) {
                singleClickedBtns[index].isSelected = true
            }
            else {
                singleClickedBtns[index].isSelected = false
            }
        }
        //  setActiveTab(val)
        await updateSingleStake(singleClickedBtns)

    }

    const handleInput = (e, index) => {
        var inp = e.target.value
        if (inp != "")
            inp = parseInt(e.target.value)

        let data = [...singleClickedBtns];
        data[index][e.target.name] = inp;
        SetSingleClickedBtns(data);
    }

    const updateSingleStake = async (data) => {
        try {
            var requestData = {
                Buttons: data
            }
            await UserService.UpdateSButtons(requestData).then(async (res) => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;

                    if (msg == "Success") {
                        error.successToastify("Stake Updated");

                        var stkrqst = {
                            "singleClicked": true,
                            "getAll": true
                        }
                        await GetStakeButtons(stkrqst);
                        setIsEdit(false);
                    }
                    else {
                        error.errorToastify(msg)
                    }
                    const liEelment = document.getElementById('close-oneclickmodal');
                    if (liEelment)
                        liEelment.click();
                }
            }).catch(error => {
                console.log(error)
            })

        } catch (error) {
            console.log(error)
        }
    }

    const editStkButtons = () => {
        setIsEdit(true)
    }

    const CancelStake = async () => {
        var stkrqst = {
            "singleClicked": true,
            "getAll": true
        }
        await GetStakeButtons(stkrqst);
        setIsEdit(false);
    }

    const enableSClick = () => {
        setBetSection(false)

        setShowSingleClick(!showSingleClick)
        setIsEdit(false)
        if (showSingleClick) {
            const liEelment = document.getElementById('close-oneclickmodal');
            if (liEelment)
                liEelment.click();
        }


    }

    const keypressHandlerSP = (event) => {
        if (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57)) {

            if (event.target.value.length > 3)
                event.preventDefault()

        } else {
            if (event.key === "Enter") {
                event.target.blur();
            }
            event.preventDefault()
        }
    };

    const CalRunsPL = (StartRuns) => {
        var TotalPL = 0.00;
        var Percentage = 1.0;
        var txtPercentage = betPercentage;
        if (txtPercentage != "") {
            var txtValue = parseFloat(txtPercentage);
            if (txtValue < 100) {
                Percentage = parseFloat(parseFloat(txtValue) / 100);
            }
        }
        if (mBets.length > 0) {
            for (let idxOrder = 0; idxOrder < mBets.length; idxOrder++) {
                let _order = mBets[idxOrder];
                let odds = (parseFloat(_order.odds / 100))
                if (_order.isCancelled)
                    continue;
                if (_order.headerType == "Spread") {
                    if (_order.side == "L") {
                        TotalPL += (_order.price - StartRuns) * _order.amount
                    }
                    else if (_order.side == "B") {
                        TotalPL += (StartRuns - _order.price) * _order.amount
                    }
                } else {

                    if (_order.side == "L") {
                        if (StartRuns < _order.price) {
                            TotalPL += (_order.amount * Percentage);
                        }
                        else {
                            TotalPL -= (_order.amount * odds * Percentage);
                        }
                    }
                    else if (_order.side == "B") {
                        if (StartRuns >= _order.price) {
                            TotalPL += (_order.amount * odds * Percentage);
                        }
                        else {
                            TotalPL -= (_order.amount * Percentage);
                        }
                    }
                }
            }
        }

        return TotalPL;

    }

    const setStartPrice = (sp, refresh) => {

        SetBetSPrice(sp)

        let hdbets = [];
        let tempSp = sp;
        for (let index = 0; index < mbts.current.length; index++) {
            const el = mbts.current[index];
            hdbets = el.betHistories.filter(a => a.marketId == btmkt.current && !a.isCancelled);
            if (hdbets.length > 0)
                break;
        }

        if (hdbets.length > 0) {
            if (hdbets[0].headerType == "Spread") {
                SetBetSPrice(sp)
                tempSp = sp;
            }
            else {
                const min = hdbets.reduce((prev, cur) => (cur.price < prev.price ? cur : prev));
                const max = hdbets.reduce((prev, cur) => (cur.price > prev.price ? cur : prev));
                if (min && max) {
                    if (parseFloat(sp) < min.price - 2) {
                        SetBetSPrice(min.price - 2)
                        tempSp = min.price - 2
                    }
                    else if (parseFloat(sp) > max.price - 2) {
                        SetBetSPrice(max.price - 2)
                        tempSp = max.price - 2
                    }
                }
            }
        }

        if (refresh)
            setSessionTable(tempSp)

    }

    const SetBetSPrice = (sp) => {
        setBetSPriceState(sp)
    }

    const setSessionTable = (betPrice, tr) => {

        var ssndata = [];
        if (betPrice != -1) {
            var StartRuns = betPrice
            var hdbets = [];
            for (let index = 0; index < mbts.current.length; index++) {
                const el = mbts.current[index];
                hdbets = el.betHistories.filter(a => a.marketId == btmkt.current && !a.isCancelled);
                if (hdbets.length > 0)
                    break;
            }

            let lnth = 27

            if (hdbets.length > 0) {
                if (hdbets[0].headerType != "Spread") {

                    const min = hdbets.reduce((prev, cur) => (cur.price < prev.price ? cur : prev));
                    const max = hdbets.reduce((prev, cur) => (cur.price > prev.price ? cur : prev));
                    if (min && max) {

                        if (parseFloat(betPrice) < min.price - 2)
                            return
                        else if (parseFloat(betPrice) > max.price - 2)
                            return
                        // StartRuns = (max.price - 24)
                        // if (parseFloat(betPrice) < min.price) {
                        //     StartRuns = min.price - 1
                        // } else if (tr) {
                        //     StartRuns = betPrice
                        // }
                    }
                }
            }

            //setBetSPrice(StartRuns)
            // betSPrice.current=StartRuns


            for (var i = 0; i < lnth; i++) {
                var plStake = CalRunsPL(parseFloat(StartRuns));
                ssndata.push({
                    price: StartRuns,
                    pl: plStake
                })
                StartRuns++;
            }

            setSsnTableData(ssndata);

        }
        else {
            setSsnTableData([]);
        }

    }

    const onHdChange = (e, ischanged) => {

        var hdbets = [];
        if (ischanged)
            startPriceRef.current = ""

        if (e != "") {

            btmkt.current = e;
            setHdName(e)
            for (let index = 0; index < mbts.current.length; index++) {
                const el = mbts.current[index];
                hdbets = el.betHistories.filter(a => a.marketId == btmkt.current && !a.isCancelled);
                if (hdbets.length > 0)
                    break;
            }
        }

        if (hdbets.length > 0) {


            const min = hdbets.reduce((prev, cur) => (cur.price < prev.price ? cur : prev));
            //const max = hdbets.reduce((prev, cur) => (cur.price > prev.price ? cur : prev));
            //const lastindexprice = hdbets[0]

            mBets = hdbets;
            let startPrice = startPriceRef.current;
            if (startPrice == "") {
                if (hdbets[0].headerType == "Spread")
                    startPrice = 0
                else
                    startPrice = min.price - 2
                SetBetSPrice(startPrice)
            }
            setSessionTable(startPrice)
            setshowprice(true)

        } else {
            SetBetSPrice("")
            setSsnTableData([]);
            setshowprice(false)
        }
    }

    const getnotimsg = (data) => {
        var msg = "";
        let price = "";
        let time = "";

        let ftm = ProtoTypes.setLocalTime2(data.fromDate)
        let ttm = ProtoTypes.setLocalTime2(data.toDate)
        if (data) {
            if (data.preRemarks == "Bulk Cancel") {
                msg = `Bets are deleted due to ${data.remarks}`
                if (ftm != "" && ttm != "")
                    time = `, time ${ftm} to ${ttm}`

                if (data.fromPrice != 0 && data.toPrice != 0)
                    price = `, price ${data.fromPrice} to ${data.toPrice}`


            } else if (data.preRemarks == "Generic") {
                msg = data.remarks
            }

        }

        return msg + price + time;
    }

    const setLadderData = (lvmkt) => {
        setChartDrpdwn(false)
        btmkt.current = lvmkt.marId
        onHdChange(btmkt.current, true)
        setPopBetTab("3")
    }

    const handleClickTv = (eventId) => {
        var tvModal1 = document.getElementById("tv-modal1");
        var txtM1cls = tvModal1.getAttribute("class");
        setTimeout(() => {
            var url = "https://tv.fstlive.video/?eventid=" + eventId;
            const tvModal1 = document.getElementById("tv-modal1");
            let txtM1cls = tvModal1.getAttribute("class");
            if (txtM1cls.includes("show")) {
                setTvUrl(url)
            } else {
                setTvUrl("")
            }
        }, 500);

        setTimeout(() => {
            if (txtM1cls.includes("show")) {
                setshowTvModal(true)
            } else {
                setshowTvModal(false)
            }
        }, 600);

        setTimeout(() => {
            if (txtM1cls.includes("show")) {
                setshowTvModal(true)
                setminiTv(false)
                setshowimage(false)
            } else {
                setshowTvModal(false)
                setminiTv(true)
                setshowimage(true)
            }
        }, 700);
    }

    const ResponsiveClickTv = (eventId) => {
        var url = "https://tv.fstlive.video/?eventid=" + eventId;
        setTvUrl(url)
        var div = document.getElementById("tv-modal2");
        if (div.style.display !== "none") {
            div.style.display = "none";
        }
        else {
            div.style.display = "block";
        }
    }

    const openModal = () => {
        setModalIsOpen(true);
        setminiTv(true)
    }

    const closeModal = () => {
        setModalIsOpen(false);
        miniTvopen()
    }

    const miniTvopen = () => {
        var url = "https://tv.fstlive.video/?eventid=" + evtId.current;
        const tvModal1 = document.getElementById("tv-modal1");
        let txtM1cls = tvModal1.getAttribute("class");
        if (txtM1cls.includes("collapse")) {
            setTvUrl(url)
        } else {
            setTvUrl("")
        }
        setminiTv(true)
        setshowimage(true)
    }

    const myfunctionset = (flag) => {
        if (flag == 0) {
            setbuttonflag(!buttonflag);
            setbutton2flag(false)
            setbutton3flag(false)
            setbuttonnumflag(0)
        } else if (flag == 1) {
            setbuttonflag(false)
            setbutton2flag(!button2flag);
            setbutton3flag(false);
            setbuttonnumflag(1)
        }
        else if (flag == 2) {
            setbuttonflag(false)
            setbutton2flag(false);
            setbutton3flag(!button3flag);
            setbuttonnumflag(2)
        }
    }

    function testFunc() {
        const container = document.getElementById('scrollEnd');
        if (container != null)
            container.scrollLeft = container.scrollWidth - container.clientWidth;
    }

    const OpenMyBetModel = () => {
        setPopBetTab("2")
        let oc = document.getElementById("dvOneClick")
        if (window.getComputedStyle(oc).display !== "none") {
            document.getElementById("myBetModel").click()
        }
    }

    const remainingDiv = (r) => {
        let table = []
        for (let i = 0; i < r; i++) {
            table.push(<span className=''></span>)
        }

        return table
    }

    const playerXIRender = (tma, tmb, players) => {
        let table = []
        let playersA = players.filter(a => a.teamName == tma);
        let playersB = players.filter(a => a.teamName == tmb);
        let range = playersA.length > playersB.length ? playersA.length : playersB.length;

        for (let index = 0; index < range; index++) {
            let pa = playersA[index];
            let pb = playersB[index];
            let pNameA = ""
            let pNameB = ""
            let pBatA = ""
            let pBatB = ""
            if (pa != undefined) {
                pNameA = pa.playerName;
                pBatA = pa.battingStyle;
            }
            if (pb != undefined) {
                pNameB = pb.playerName;
                pBatB = pb.battingStyle;
            }

            table.push(
                <div className='ptableRow d-flex'>
                    <div className='sno'>{index + 1}</div>
                    <div className='playerName'>
                        <span>{pNameA}</span>
                        <span>{pBatA}</span>
                    </div>

                    <div className='playerName'>
                        <span>{pNameB}</span>
                        <span>{pBatB}</span>
                    </div>
                </div>
            )

        }

        return table
    }

    const SetYetToPlay = () => {
        var yetToBats = [];
        for (let i = 0; i < scorePadBatsmensRef.current.length; i++) {
            var teamName = scorePadBatsmensRef.current[i].teamName;
            var yetToBatPlayer = teamPlayersDataRef.current.filter(a => a.teamName == teamName &&
                !scorePadBatsmensRef.current[i].battingPlayers.find(s => s.playerName == a.playerName)
            );
            var yetTT = '';
            for (let index = 0; index < yetToBatPlayer.length; index++) {
                yetTT += yetToBatPlayer[index].playerName + ', ';

            }
            var yetToBat = {};
            yetToBat.TeamName = teamName;
            yetToBat.YetToB = yetTT;
            yetToBats.push(yetToBat);
        }
        setYetToBat(yetToBats);
    }

    const InningsClick = (index) => {
        setcurrentInning(index)
    }

    const shortTeamName = (nam) => {
        var nameSplt = nam.split(' ');
        if (nameSplt.length == 1) {
            if (nam.length > 3)
                return nam.substring(0, 3);
            else
                return nam;
        }
        else {
            var srtTeam = "";
            for (let index = 0; index < nameSplt.length; index++) {
                srtTeam += nameSplt[index].substring(0, 1);

            }
            return srtTeam;
        }
    }

    const getBowls = (over) => {
        var strSplit = over.toString().split('.');

        var countbowls = 0;

        if (strSplit.length == 1) {
            countbowls = parseInt(strSplit[0]) * 6;
        }
        else if (strSplit.length == 2) {
            countbowls = parseInt(strSplit[0]) * 6 + parseInt(strSplit[1]);
        }

        return countbowls;

    }

    const scoreBoardClick = () => {
        if (batTeamDataRef.current && scoreBoardDataRef.current) {
            var crr = scoreBoardDataRef.current.findIndex(s => s.teamName == batTeamDataRef.current.TeamName);
            setcurrentInning(crr)
            console.log(crr)
        }
    }

    function getLiveMarketByindex(live_MarketsList, i, div_typ) {
        const left_index = i * 2;
        const right_index = left_index + 1;
        
        if (div_typ === 'left') {
        return left_index < live_MarketsList.length ? live_MarketsList[left_index] : null;
        }
        else {
        return right_index < live_MarketsList.length ? live_MarketsList[right_index] : null;
        }
    }

    function getGLSliderList(mkts){
        const live_MarketsItem = mkts?.liveMarkets[0];
        const MO_Runners = livemrkts?.find(s => s.marketID == live_MarketsItem?.marId)?.liveMORunners;
        let unique = [...new Map(MO_Runners?.map(item =>
            [item.handicap, item])).values()];

        unique.sort((a, b) => a.handicap - b.handicap);

        unique.forEach((el_,ind_uin) => {
            const el_Arr = (el_.handicap).toString().split('.');
            if(el_Arr?.length > 1){
                if(el_Arr[1] !== '5'){
                    const firstTxt = el_.handicap - .25;
                    const secondTxt = el_.handicap + .25;
                    unique[ind_uin].slideName = firstTxt.toFixed(1) +' & '+ secondTxt.toFixed(1);
                }
                else{
                    unique[ind_uin].slideName =  el_.handicap
                }
            }
            else{
                unique[ind_uin].slideName =  el_.handicap
            }
        });
        return unique;
    }

    function getGoalLineSlider(mkts) {
        const unique = getGLSliderList(mkts);
        
        return (
            (unique?.length > 0) &&
            <>
                <Splide ref={goalSplide} onMoved={(slide, currIndex, preIndex) => { console.log(slide, currIndex, preIndex) }} options={{ type: 'slide', perPage: 3, focus: 'center', pagination: false, start: 0, trimSpace: false, }} aria-label="React Splide Example">
                    {
                        unique?.map((mktslide, k) => (
                            <SplideSlide>
                                {mktslide?.slideName}
                            </SplideSlide>
                        ))}    
                </Splide>
            </>
        )
    }

    function getGoalLineData(mkts) {
        const live_Markets = mkts?.liveMarkets[0];
        const unique = getGLSliderList(mkts);
        const s_A_Index = goalSplide.current?.splide?.Components?.Controller?.getIndex()
        const live_MarketsItem = unique[s_A_Index];

        const l_MarketsData = livemrkts?.find(s => s.marketID == live_Markets?.marId);
        const Runners_list = l_MarketsData?.liveMORunners;
        const runnerArr = Runners_list?.filter(item =>item.handicap ===  live_MarketsItem?.handicap);
        return (
            !live_MarketsItem ? null :
                <>
                    <div class="body-items mb-1">
                        <span></span>
                        <div class="rgtlbsec hideMo">
                        <span className='minsp'>Min: {kFormatter(live_Markets?.minBet)}</span>&nbsp;
                        <span className='maxsp'>Max: {kFormatter(live_Markets?.maxBet)}</span>

                            <div class="nrmltbsec">
                                <span class="">Back</span>
                            </div>
                            <div class="nrmlbsec">
                                <span class="">Lay</span>
                            </div>
                        </div>
                    </div>
                   { runnerArr?.map((nrmllvmkt, k) => (
                        nrmllvmkt.isDisplay && 
                    <div className="col-12 ">
                        <div className='one-itm-new'>
                            <div className=" col-sm-5 col-xs-12  " >
                                <span>{nrmllvmkt?.runnerName}</span> {" "} <span className={expData.find(s => (s.marId).toString() == live_MarketsItem?.marketId) != undefined && expData.find(s => (s.marId).toString() == live_MarketsItem?.marketId).exposure <= 0 ? 'negative' : 'positive'} onClick={() => OpenMyBetModel()} >{expData.find(s => (s.marId).toString() == live_MarketsItem?.marketId) && ProtoTypes.Commas(expData.find(s => (s.marId).toString() == live_MarketsItem?.marketId).exposure)}</span>
                                <span className='Mob-MM'>
                                    {expData.find(s => (s.marId).toString() == live_MarketsItem?.marketId) && expData.find(s => (s.marId).toString() == live_MarketsItem?.marketId).count > 0 && <img onClick={() => setLadderData(live_MarketsItem)} src='assets/img/ico-chart.svg' height={'16px'} width={'16px'} style={{ marginRight: '10px' }} data-bs-toggle="modal" data-bs-target="#betchart-Modal" />}
                                    <span>Min: {kFormatter(live_Markets?.minBet)}</span>&nbsp;
                                    <span>Max: {kFormatter(live_Markets?.maxBet)}</span>
                                </span>
                            </div>
                            <div className='rgtsec col-sm-7 col-xs-12  cursorclass'>
                                {/* <div className='msec mt-1'>
                                    {expData.find(s => (s.marId).toString() == live_MarketsItem?.marketId) && expData.find(s => (s.marId).toString() == live_MarketsItem?.marketId).count > 0 && <img src='assets/img/ico-chart.svg' onClick={() => setLadderData(live_MarketsItem)} height={'16px'} width={'16px'} data-bs-toggle="modal" data-bs-target="#betchart-Modal" />}
                                    <div style={{ marginLeft: '5px' }}>Min: {kFormatter(live_Markets?.minBet)}</div>
                                    <div style={{ marginLeft: '5px' }}>Max: {kFormatter(live_Markets?.maxBet)}</div>
                                </div> */}
                                <div className='mt-sec'>
                                    {live_MarketsItem && live_Markets?.marketStatus !== "Suspend" &&  l_MarketsData?.marketStatus !== "Suspend" && nrmllvmkt?.runnerStatus !== "Suspend" ?
                                            <div className='prcSec ' key={k}>

                                                <div className="num2" onClick={() => setorderDetails(live_Markets?.maxBet, live_Markets?.minBet, nrmllvmkt, live_Markets?.marName + "-" + nrmllvmkt.layOdds, mkts.marketHName, "L", "LM")}>
                                                    <div>   <span className="uprsec">{nrmllvmkt.layPrice1 == "0" ? "" : nrmllvmkt.layPrice1}</span>
                                                    </div>
                                                    <div>
                                                        <span className="dwnsec">{nrmllvmkt.layOdds == "0" ? "" : nrmllvmkt.layOdds}</span>
                                                    </div>
                                                </div>
                                                <div className="num1" onClick={() => setorderDetails(live_Markets?.maxBet, live_Markets?.minBet, nrmllvmkt, live_Markets?.marName + "-" + nrmllvmkt.backOdds, mkts.marketHName, "B", "LM")}>
                                                    <div>      <span className="uprsec">{nrmllvmkt.backPrice1 == "0" ? "" : nrmllvmkt.backPrice1}</span>
                                                    </div> <div>
                                                        <span className="dwnsec">{nrmllvmkt.backOdds == "0" ? "" : nrmllvmkt.backOdds}</span>
                                                    </div>
                                                </div>
                                                {nrmllvmkt.runnerStatus != "" && <div className="ov2"><span>{nrmllvmkt.runnerStatus == "Suspend" ? "Suspended" : nrmllvmkt.runnerStatus}</span></div>}

                                            </div>
                                         :
                                        <>

                                            <div className='prcSec' >

                                                <div className="num2">
                                                    <span className="">{""}</span>

                                                </div>
                                                <div className="num1" >
                                                    <span className="">{""}</span>

                                                </div>
                                                <div className="ov2"><span>{"Suspended"}</span></div>

                                            </div>
                                        </>

                                    }
                                </div>

                            </div>
                        </div>
                        {(liveNoti?.length > 0) && (liveNoti.find(s => (s.marketId).toString() == live_MarketsItem?.marketId)) &&
                            < div className='col-sm-12 one-itm-alert text-base'>
                                {getnotimsg(liveNoti.find(s => (s.marketId).toString() == live_MarketsItem?.marketId))}
                            </div>}

                    </div>
                ))}
                </>
        )
    }

    return (
        <>
            {Loader && <div className="boxes-bg">
                <div className="boxes">
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>}

            {/*loader*/}
            <div className="body-middle">
                <ToastContainer autoClose={2000} />
                {/*Breadcumnd*/}
                <div className="breadcumnd-banner detailBreadcrm">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-8">
                                <ul className="bread-tag">
                                    <li>
                                        {evtDetail.compName}  {evtDetail.compName && ">"}  {evtDetail.eventName}
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="bread-tag1">
                                    <li>
                                        {ProtoTypes.setLocalDate(evtDetail.eventDate)}
                                    </li>
                                </ul>
                                <span className="float-end d-md-none d-sm-block"><img src="./assets/img/ico-tv-white.svg" height="24px" onClick={() => ResponsiveClickTv(evtId.current)} /></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-md-none d-sm-block' style={{ display: "none" }} id="tv-modal2">
                    <iframe style={{ width: "100%", height: "240px" }} id='tv-iframe' src={TvUrl}></iframe>
                </div>


                {/*Banner*/}
                <div className="details-banner pt-20 pb-60 minht-700">
                    <div className="container">
                        {scoreBoardshow != 0 &&
                            <div className='row g-4'>

                                <div className="col-12 ScoreCard d-flex justify-content-center">
                                    {/*Notification to Show - remove class d-none from here put on Main Area div*/}
                                    {scoreBoardshow == 1 && <div className='col-xl-6 col-lg-6 col-md-7 col-xs-12 col-sm-8 d-flex flex-column mtchNoti'>
                                        <div className='d-flex flex-grow-1 justify-content-center align-items-center'>{matchStatusMsg}</div>
                                    </div>}
                                    {/*Notification Ends Here*/}
                                    {/* Main Area Starts Here to Show - remove class d-none from here put on Notification div*/}

                                    {scoreBoardshow == 2 && <div className='col-xl-7 col-lg-6 col-md-7 col-xs-12 col-sm-8'>

                                        {scoreBoardLoader && <div className="scoreLoader" ><img src="assets/img/loader.gif" alt="loader" width={"30"} /></div>}

                                        <div className='col-12 d-flex d-sm-none align-items-end'>
                                            <button type='button' onClick={() => myfunctionset(0)} className={buttonflag && buttonnumflag == 0 ? 'Btactive' : ""}>Player XI</button>
                                            <button type='button' onClick={() => myfunctionset(1)} className={button2flag && buttonnumflag == 1 ? 'Btactive' : ""}>Scorecard</button>
                                            {/* <button  type='button' onClick={() => myfunctionset(2)} className={button3flag && buttonnumflag == 1 ? 'Btactive' : ""}><i className='fa fa-info-circle'></i></button> */}
                                        </div>
                                        <div className='row gx-1 gx-sm-2 d-flex'>

                                            <div className='col-6 col-xs-6 '>
                                                <div className='MatchSumm'>
                                                    <div className='container'>
                                                        {/* Player1 Details Start Here -Note Add "active class on strike player" */}

                                                        {scoreBattingData?.length > 1 ? scoreBattingData?.map((bats, i) => (

                                                            <div className={bats.onStrike ? "row gx-0 bordiv" : "row gx-0"} key={i}>
                                                                <div className={bats.onStrike ? "Player active d-flex" : "Player d-flex"}>
                                                                    <div className='col-8 d-inline-flex '><div className='BatPlayerName'>{bats.playerName}</div><div>{"(" + bats.batsmanType + ")"}</div> </div>
                                                                    <div className='col-4'>
                                                                        {bats.runs}
                                                                        {"(" + bats.bowlFaced + ")"}
                                                                    </div>
                                                                </div>
                                                                <div className='ballruns'>
                                                                    {bats.battingHistory.split(' ')?.map((delv, i) => (
                                                                        delv != "" && <span className={delv == "4" || delv == "6" ? "four6" : delv.length == 2 ? " extra" : delv.length == 3 ? "extraRun" : delv == "w" ? "wkt" : ""}>{delv == "X" ? "0" : delv}</span>

                                                                    ))}

                                                                </div>
                                                                <div className='strikeRate'>{bats.strikeRate}</div>
                                                            </div>
                                                        )) :

                                                            scoreBattingData.length == 1 ?
                                                                scoreBattingData?.map((bats, i) => (
                                                                    <>
                                                                        <div className={bats.onStrike ? "row gx-0 bordiv" : "row gx-0"} key={i}>
                                                                            <div className={bats.onStrike ? "Player active d-flex" : "Player d-flex"}>
                                                                                <div className='col-8 d-inline-flex '><div className='BatPlayerName'>{bats.playerName}</div><div>{"(" + bats.batsmanType + ")"}</div> </div>
                                                                                <div className='col-4'>
                                                                                    {bats.runs}
                                                                                    {"(" + bats.bowlFaced + ")"}
                                                                                </div>
                                                                            </div>
                                                                            <div className='ballruns'>
                                                                                {bats.battingHistory.split(' ')?.map((delv, i) => (
                                                                                    delv != "" && <span className={delv == "4" || delv == "6" ? "four6" : delv.length == 2 ? " extra" : delv.length == 3 ? "extraRun" : delv == "w" ? "wkt" : ""}>{delv == "X" ? "0" : delv}</span>

                                                                                ))}

                                                                            </div>
                                                                            <div className='strikeRate'>{bats.strikeRate}</div>
                                                                        </div>
                                                                        <div className="row gx-0">
                                                                            <div className="Player d-flex">
                                                                                <div className='col-6 PlayerName'>Not selected<span></span> </div>
                                                                                <div className='col-6'>
                                                                                </div>
                                                                            </div>
                                                                            <div className='ballruns'>-
                                                                            </div>
                                                                            <div className='strikeRate'></div>
                                                                        </div>
                                                                    </>
                                                                )) :
                                                                <>
                                                                    <div className="row gx-0">
                                                                        <div className="Player d-flex">
                                                                            <div className='col-6 PlayerName'>Not selected<span></span> </div>
                                                                            <div className='col-6'>
                                                                            </div>
                                                                        </div>
                                                                        <div className='ballruns'>
                                                                        </div>
                                                                        <div className='strikeRate'></div>
                                                                    </div>

                                                                    <div className="row gx-0">
                                                                        <div className="Player d-flex">
                                                                            <div className='col-6 PlayerName'>Not selected<span></span> </div>
                                                                            <div className='col-6'>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-9 ballruns'>
                                                                        </div>
                                                                        <div className='col-3 strikeRate'></div>
                                                                    </div>
                                                                </>
                                                        }

                                                        {/* Current Bowler */}
                                                        <div className='col-12 bolwerStats'>
                                                            <div className='tableHead d-flex '>
                                                                <div className='col-6'>BOWLER</div>
                                                                <div className='col-6 d-flex justify-content-between'>
                                                                    <div className='col-2'>O</div>
                                                                    <div className='col-2'>M</div>
                                                                    <div className='col-2'>R</div>
                                                                    <div className='col-2'>W</div>
                                                                    <div className='col-3 d-none d-sm-block'>Econ</div>
                                                                </div>
                                                            </div>
                                                            {/* Bowler Player Dynamic details Here */}
                                                            <div className='tableRow d-flex'>

                                                                {scoreBowlingData?.length > 0 ? scoreBowlingData?.map((bowl, i) => (

                                                                    <>
                                                                        <div className='col-6 PlayerName'>{bowl.playerName}</div>
                                                                        <div className='col-6 d-flex justify-content-between'>
                                                                            <div className='col-2'>{bowl.totalOvers}</div>
                                                                            <div className='col-2'>{bowl.middenOvers}</div>
                                                                            <div className='col-2'>{bowl.totalRuns}</div>
                                                                            <div className='col-2'>{bowl.totalWicket}</div>
                                                                            <div className='col-3 d-none d-sm-block'>{bowl.strikeRate}</div>
                                                                        </div>
                                                                    </>
                                                                )) :
                                                                    <>
                                                                        <div className='col-6 PlayerName'>No bowler selected</div>
                                                                        <div className='col-6 d-flex justify-content-between'>
                                                                            <div className='col-2'></div>
                                                                            <div className='col-2'></div>
                                                                            <div className='col-2'></div>
                                                                            <div className='col-2'></div>
                                                                            <div className='col-3 d-none d-sm-block'></div>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>

                                                            {/* Bowler Player details Here */}
                                                            {/* Current Bowler */}

                                                            {/* Overs Details Start Here */}

                                                            <div className='col-12 overdetails' id='scrollEnd' >
                                                                {oversData?.map((ovdata, index) =>
                                                                (
                                                                    <>

                                                                        <div className='overs' >
                                                                            {index == 0 ? null : <span className='overLabel'>{ovdata.overNumber}{ovdata.overNumber == 1 ? "st Ov" : ovdata.overNumber == 2 ? "nd Ov" : "th Ov"}</span>}
                                                                            {ovdata.overHistory.split(' ')?.map((ov, i) => (
                                                                                <>
                                                                                    {" "}  {ov != "" && <span className={ov == "4" || ov == "6" ? "four6" : ov.length == 2 ? " extra" : ov.length == 3 ? "extraRun" : ov == "w" ? "wkt" : ""}>{ov == "X" ? "0" : ov}</span>}
                                                                                </>
                                                                            ))}
                                                                        </div>

                                                                    </>
                                                                )
                                                                )}
                                                            </div>
                                                            {/* Overs Details Ends Here */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-3'>
                                                <div className='innsScore'>
                                                    {teamScore && teamScore?.map((team, i) =>
                                                    (
                                                        <div className={team.isBattingTeam ? "Inns1 active" : "Inns1"}>
                                                            <div className='title'>{team.teamName}</div>
                                                            {!team.isBattingTeam && (team.teamScore == 0 || team.teamOver == 0) ? "Yet to bat" :
                                                                <>
                                                                    <div className='score'><span>{team.teamScore} / {team.teamWicket} <span>{"(" + team.teamOver + ")"}</span></span></div>
                                                                </>
                                                            }

                                                        </div>
                                                    )
                                                    )}

                                                    {teamScore.length > 1 && !teamScore[1].isBattingTeam && !(teamScore[1].teamScore == 0 || teamScore[1].teamOver == 0) &&
                                                        <div className='target'>
                                                            <div className='title'>Target</div>
                                                            <div className='score'><span>{teamScore[1].teamScore + 1 - teamScore[0].teamScore} in {getBowls(teamScore[0].matchOvers) - getBowls(teamScore[0].teamOver)} Balls</span> <strong>RRR:</strong> {((teamScore[1].teamScore + 1 - teamScore[0].teamScore) / (getBowls(teamScore[0].matchOvers) - getBowls(teamScore[0].teamOver)) * 6).toFixed(2)}</div>
                                                        </div>
                                                    }
                                                </div>


                                            </div>

                                            {/* Third Column Start Here */}

                                            <div className='col-2 col-xs-3'>

                                                <div className=' MatchInfo'>
                                                    <div className={finalBowl == "4" ? "curBall six4" : "curBall" && finalBowl == "6" ? "curBall six4" : "curBall" && finalBowl == "w" ? "curBall wkt" : "curBall" && finalBowl?.length == 2 ? "curBall exRun" : "curBall" && finalBowl?.length == 3 ? "curBall exRun" : "curBall"}>
                                                        {finalBowl == "X" ? "0" : finalBowl}</div>
                                                    {teamScore?.map((partnrship, index) =>
                                                    (
                                                        <>
                                                            {partnrship.isBattingTeam &&
                                                                <div className='partnerShip'><span>PartnerShip</span>{partnrship.teamPartnershipScore} {"(" + partnrship.teamPartnershipBowls + ")"}</div>}
                                                        </>
                                                    )
                                                    )}


                                                    <div className='drsRev'><span>DRS Review</span>
                                                        <div className='bTeams'>
                                                            {drsData?.map((data, index) =>
                                                            (
                                                                <>
                                                                    <div className='team'>
                                                                        <div className='teamName'>{shortTeamName(data.teamName)}<span className='remReview'>{data.reviewRemaining}</span></div>
                                                                        <div className='revstats'>
                                                                            {data.teamReview?.map((rv, i) =>
                                                                            (
                                                                                <>
                                                                                    {rv ? <span className='usedY'></span> : <span className='usedN'></span>}
                                                                                </>
                                                                            )
                                                                            )}
                                                                        </div>


                                                                    </div>
                                                                </>
                                                            )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Third Column Ends Here */}

                                            <div className='col-1 d-none d-sm-block justify-content-between'>
                                                <button type='button' onClick={() => myfunctionset(0)} className={buttonflag && buttonnumflag == 0 ? 'Btactive' : ""}>Player XI</button><br />
                                                <button type='button' onClick={() => { myfunctionset(1); scoreBoardClick() }} className={button2flag && buttonnumflag == 1 ? 'Btactive' : ""}>Scorecard</button>
                                                <button style={{ visibility: "hidden" }} type='button' onClick={() => myfunctionset(2)} className={button3flag && buttonnumflag == 2 ? 'Btactive' : ""}><i className='fa fa-info-circle'></i></button>
                                            </div>

                                            {/* Player XIArea Starts Here */}

                                            {buttonflag && buttonnumflag == 0 ? <div id="player-XI" className='col-11 col-xs-12 overlayBox'>
                                                <div className='PlayerXI'>
                                                    <i onClick={() => myfunctionset(0)} className='fa fa-window-close'></i>
                                                    <div className='ptableHead d-flex'>
                                                        <div className='sno'></div>
                                                        <div className='teamName'>{teamAname.current}</div>
                                                        <div className='teamName'>{teamBname.current}</div>
                                                    </div>

                                                    <div className='tableBody'>
                                                        {playerXIRender(teamAname.current, teamBname.current, teamPlayersData)}
                                                    </div>

                                                </div>

                                            </div> : null}
                                            {/* Player XIArea Ends Here */}

                                            {/* ScoreCard Area Start Here */}

                                            {button2flag && buttonnumflag == 1 ? <div className='col-11 col-xs-12 overlayBox'>
                                                <div className='matchCard'>
                                                    <i onClick={() => { myfunctionset(1) }} className='fa fa-window-close'></i>
                                                    <div className='TeamSelector'>
                                                        {scoreBoardData?.map((team, i) => (
                                                            <a className={currentInning == i ? "active" : ""} onClick={() => InningsClick(i)}>{team.teamName}</a>
                                                        ))}
                                                    </div>
                                                    {scoreBoardData && scoreBoardData?.map((batPlayers, j) => (
                                                        currentInning == j && <div className='scoreCardDetails'>
                                                            <div className='BattingDetails'>
                                                                <div className='bTableHead d-flex'>
                                                                    <div className='col-7'>Batting</div>
                                                                    <div className='col-1'>R</div>
                                                                    <div className='col-1'>B</div>
                                                                    <div className='col-1'>4s</div>
                                                                    <div className='col-1'>6s</div>
                                                                    <div className='col-1'>SR</div>
                                                                </div>

                                                                {batPlayers && batPlayers.battingPlayers?.map((bats, i) => (
                                                                    <div className={bats.currentyPlaying ? 'bTableRow pactive d-flex' : 'bTableRow d-flex'}>
                                                                        <div className='col-7'>{bats.playerName}
                                                                            {/* <span>b Fatima Sana</span> */}
                                                                        </div>
                                                                        <div className='col-1'>{bats.runs}</div>
                                                                        <div className='col-1'>{bats.bowlFaced}</div>
                                                                        <div className='col-1'>{bats.fours}</div>
                                                                        <div className='col-1'>{bats.sixes}</div>
                                                                        <div className='col-1'>{bats.strikeRate}</div>
                                                                    </div>
                                                                ))
                                                                }

                                                                <div className='bTableRow extra d-flex'>
                                                                    {/* <div className='col-3'>Extras</div>
                                                                    <div className='col-4'>(LB 2, w 9)</div>
                                                                    <div className='col-1'>11</div>
                                                                    <div className='col-1'></div> */}
                                                                </div>
                                                                {teamScore?.map((score, i) => (
                                                                    <>
                                                                        {
                                                                            score.teamName == batPlayers.teamName &&
                                                                            <div className='bTableRow total d-flex'>
                                                                                <div className='col-3'>Total</div>
                                                                                <div className='col-4'>{score.teamOver} Ov (RR: {score.teamRunRate})</div>
                                                                                <div className='col-1'>{score.teamScore}/{score.teamWicket}</div>
                                                                                <div className='col-1'></div>
                                                                            </div>
                                                                        }
                                                                    </>

                                                                ))
                                                                }

                                                                <div className='bTableRow yet2bat d-flex'>
                                                                    <div className='col-12'><strong>Yet to bat:</strong>{" "}
                                                                        {yetToBat?.map((ytplayer, i) => (
                                                                            <>
                                                                                {
                                                                                    ytplayer.TeamName == batPlayers.teamName &&
                                                                                    <>
                                                                                        {ytplayer.YetToB}
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        ))}
                                                                        <br />
                                                                        <br />
                                                                        <strong>Fall of wickets:</strong>{" "}
                                                                        {batPlayers && batPlayers.lastWickets?.map((lastwicket, i) => (
                                                                            <>
                                                                                {i == 0 ? '' : ', '}
                                                                                {lastwicket.wicket + "-" + lastwicket.score} {"(" + lastwicket.playerName + ", " + (lastwicket.overs + " " + "ov") + ")"}
                                                                            </>
                                                                        ))}

                                                                    </div>
                                                                </div>

                                                                <div className='bTableHead mt-1 d-flex'>
                                                                    <div className='col-3'>Bowling</div>
                                                                    <div className='col-1'>O</div>
                                                                    <div className='col-1'>M</div>
                                                                    <div className='col-1'>R</div>
                                                                    <div className='col-1'>W</div>
                                                                    <div className='col-1'>ECON</div>
                                                                    {/* <div className='col-1'>4s</div>
                                                                    <div className='col-1'>6s</div> */}
                                                                    <div className='col-1'>WD</div>
                                                                    <div className='col-1 text-center'>NB</div>
                                                                </div>

                                                                {/* Bowling Player Loop Start Here */}
                                                                {scoreBoardData[j] && scoreBoardData[j].bowlingPlayers?.map((bowl, i) =>
                                                                (
                                                                    <div className='bTableRow d-flex'>
                                                                        <div className='col-3'>{bowl.playerName}</div>
                                                                        <div className='col-1'>{bowl.totalOvers}</div>
                                                                        <div className='col-1'>{bowl.middenOvers}</div>
                                                                        <div className='col-1'>{bowl.totalRuns}</div>
                                                                        <div className='col-1'>{bowl.totalWicket}</div>
                                                                        <div className='col-1'>{bowl.strikeRate}</div>
                                                                        {/* <div className='col-1'>{bowl.fours}</div>
                                                                        <div className='col-1'>{bowl.sixes}</div> */}
                                                                        <div className='col-1'>{bowl.totalWD}</div>
                                                                        <div className='col-1 text-center'>{bowl.totalNB}</div>
                                                                    </div>
                                                                ))}

                                                                {/* Bowling Player Loop Ends Here */}
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div> : null}
                                            {/* ScoreCard Area Ends Here */}

                                            {/* Match Info Area Starts Here */}

                                            {button3flag && buttonnumflag == 2 ? <div className='col-11 col-xs-12 overlayBox'>
                                                <div className='mtInfo'>
                                                    <i onClick={() => myfunctionset(2)} className='fa fa-window-close'></i>
                                                    <h1>Match Details</h1>
                                                    <div className='mtdetails'>
                                                        <div className='container'>
                                                            {/* Details Loop Start Here */}
                                                            <div className='row'>
                                                                <div className='col-3'>Ground</div>
                                                                <div className='col-9'>County Ground, Chelmsford</div>
                                                            </div>
                                                            {/* Details Loop Ends Here */}
                                                            <div className='row'>
                                                                <div className='col-3'>Toss</div>
                                                                <div className='col-9'>England Women, elected to bat first</div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-3'>Series</div>
                                                                <div className='col-9'>Pakistan Women tour of England, ICC Women's Championship</div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-3'>Match days</div>
                                                                <div className='col-9'>29 May 2024 - daynight (50-over match)</div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-3'>Umpires</div>
                                                                <div className='col-9'>Jasmine Naeem, Kim Cotton</div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-3'>Reserve Umpires</div>
                                                                <div className='col-9'>Anna Harris</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div> : null}
                                            {/* Match Info Area Ends Here */}
                                        </div>
                                    </div>}
                                    {/* Main Area Ends Here to Hide/Show - to Hide add class d-none */}
                                </div>
                            </div>
                        }

                        <div className="tab-content " id="d-wrap">
                            <div className="row g-4">
                                <div className="col-lg-12">
                                    <div className={`detail-match-winner ${disabSec}`}>
                                        <div className="details-match-wrap">
                                            <div className="accordion" id="details-accordionscore">
                                                <div className="accordion-item">
                                                    {marketDetails?.map((mkts, index) => (

                                                        (mkts.marketHName == "Match Odds" && liveRnrMO.marketStatus == "Hide") ? null :

                                                            <div className={`accordion-header  dtlAccrhd ${(mkts.marketHName == "Match Odds" || mkts.marketHName == "Bookmaker") ? (mkts.marketHName).replace(/ +/g, "") : (catId.current === 1 ||catId.current === 2) ? "Bookmaker" : "otherOdds"}`} key={index} >

                                                                <div className='accordion-button ' type="button" data-bs-toggle="collapse" data-bs-target={"#detaislaccor" + index} aria-expanded="false" aria-controls="detaislaccor50">
                                                                    <span >{mkts.marketHName}</span>
                                                                    {(mkts.marketHName == "Match Odds" || (mkts.marketHName == "Bookmaker" && mkts.liveMarkets.length == 1)) && <span className='Mob-MM' >
                                                                        <span className='minsp'>Min: {mkts.liveMarkets && mkts.liveMarkets[0] && kFormatter(mkts.liveMarkets[0].minBet)}</span>
                                                                        <span className='maxsp'>Max: {mkts.liveMarkets && mkts.liveMarkets[0] && kFormatter(mkts.liveMarkets[0].maxBet)}</span>
                                                                    </span>}
                                                                </div>

                                                                <div id={"detaislaccor" + index} className={mkts.marketHName == "Bookmaker" && mkts.liveMarkets.length == 1 ? "accordion-collapse collapse show Bsingle" : "accordion-collapse collapse show"}>
                                                                    <div className="accordion-body score-body-items-two">
                                                                        <div className="row">

                                                                            {mkts.marketHName != "Match Odds" && mkts.marketHName != "Bookmaker" && mkts.marketHName != "Goal Lines" && catId.current !== 1 && catId.current !== 2 &&
                                                                                <>
                                                                                    <div className="col col-sm-6 hideMo mb-1" >
                                                                                        <div className="nrmltbsec"><span className="num">Yes</span></div>
                                                                                        <div className="nrmlbsec"><span className="num">No</span></div>
                                                                                    </div>
                                                                                    <div className="col col-sm-6 hideMo">
                                                                                        {mkts.liveMarkets.length > 1 &&

                                                                                            <><div className="nrmltbsec"><span className="num">Yes</span></div>
                                                                                                <div className="nrmlbsec"><span className="num">No</span></div>
                                                                                            </>
                                                                                        }
                                                                                    </div>

                                                                                </>

                                                                            }

                                                                            {mkts.marketHName == "Match Odds" ?
                                                                                mkts.liveMarkets && mkts.liveMarkets?.map((lvmkt, indx) => (
                                                                                    <>
                                                                                        <div className='cardBg ' key={indx}>
                                                                                            <div className="body-items mb-1"  >
                                                                                                <div className='dpflx col-sm-4  col-xs-12'>

                                                                                                    <span></span>
                                                                                                </div>
                                                                                                <div className='col-sm-8  col-xs-12' style={{ textAlign: 'right' }}>
                                                                                                    <div className='rgtlbsec' >
                                                                                                        <span className='minsp'>Min: {kFormatter(lvmkt.minBet)}</span>
                                                                                                        <span className='maxsp'>Max: {kFormatter(lvmkt.maxBet)}</span>
                                                                                                        <div className='tbsec'>
                                                                                                            <span className="num">Back</span>
                                                                                                        </div>

                                                                                                        <div className='tlsec'>
                                                                                                            <span className="num">Lay</span>
                                                                                                        </div>
                                                                                                        <div className='tlsec hdn'>
                                                                                                            <span className="num"></span>
                                                                                                        </div>
                                                                                                        <div className='tlsec hdn'>
                                                                                                            <span className="num"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>


                                                                                            {

                                                                                                (liveRnrMO && liveRnrMO.liveMORunners && liveRnrMO.liveMORunners?.length > 0) ? liveRnrMO.liveMORunners?.map((molive, i) => (

                                                                                                    <div className="body-items mb-1 paddR0" key={i}>

                                                                                                        <div className='dpflx col-sm-4   col-xs-12 padd0'>
                                                                                                            <span className='rnr-nm'> {molive.runnerName}  &nbsp;<span onClick={() => OpenMyBetModel()} style={{ marginLeft: "0px" }} className={checkclass([i] == 0 ? MOtotal.R1 : [i] == 1 ? MOtotal.R2 : [i] == 2 ? MOtotal.R3 : "")} >{([i] == 0 && MOtotal.R1 ? ProtoTypes.Commas(MOtotal.R1) : [i] == 1 && MOtotal.R2 ? ProtoTypes.Commas(MOtotal.R2) : [i] == 2 && MOtotal.R3 ? ProtoTypes.Commas(MOtotal.R3) : "")}</span></span>

                                                                                                        </div>
                                                                                                        <div className='col-sm-8  col-xs-12 padd0' style={{ textAlign: 'right' }}>


                                                                                                            <div className='rgtlbsec cursorclass'>

                                                                                                                <div className="bsec" onClick={() => setorderDetails(lvmkt.maxBet, lvmkt.minBet, molive, molive.runnerName, mkts.marketHName, "B", "MO", i, 3, liveRnrMO.liveMORunners)}>

                                                                                                                    <span className="num">{molive.backPrice3 == "0" ? "" : molive.backPrice3 > 19.5 ? molive.backPrice3 : ProtoTypes.Commas(molive.backPrice3)}</span>


                                                                                                                    <span className=" btm-num">{molive.backPrice3 == "0" ? " " : kFormatter(molive.backSize3)}</span>

                                                                                                                </div>
                                                                                                                <div className="bsec" onClick={() => setorderDetails(lvmkt.maxBet, lvmkt.minBet, molive, molive.runnerName, mkts.marketHName, "B", "MO", i, 2, liveRnrMO.liveMORunners,)}>
                                                                                                                    <span className="num">{molive.backPrice2 == "0" ? "" : molive.backPrice2 > 19.5 ? molive.backPrice2 : ProtoTypes.Commas(molive.backPrice2)}</span>

                                                                                                                    <span className=" btm-num">{molive.backPrice2 == "0" ? " " : kFormatter(molive.backSize2)}</span>

                                                                                                                </div>
                                                                                                                <div className="bsec" onClick={() => setorderDetails(lvmkt.maxBet, lvmkt.minBet, molive, molive.runnerName, mkts.marketHName, "B", "MO", i, 1, liveRnrMO.liveMORunners,)}>
                                                                                                                    <span className="num">{molive.backPrice1 == "0" ? "" : molive.backPrice1 > 19.5 ? molive.backPrice1 : ProtoTypes.Commas(molive.backPrice1)}</span>
                                                                                                                    <span className=" btm-num">{molive.backPrice1 == "0" ? " " : kFormatter(molive.backSize1)}</span>

                                                                                                                </div>
                                                                                                                <div className="lsec" onClick={() => setorderDetails(lvmkt.maxBet, lvmkt.minBet, molive, molive.runnerName, mkts.marketHName, "L", "MO", i, 1, liveRnrMO.liveMORunners,)} >
                                                                                                                    <span className="num">{molive.layPrice1 == "0" ? " " : molive.layPrice1 > 19.5 ? molive.layPrice1 : ProtoTypes.Commas(molive.layPrice1)}</span>

                                                                                                                    <span className="btm-num">{molive.layPrice1 == "0" ? " " : kFormatter(molive.laySize1)}</span>
                                                                                                                </div>
                                                                                                                <div className="lsec" onClick={() => setorderDetails(lvmkt.maxBet, lvmkt.minBet, molive, molive.runnerName, mkts.marketHName, "L", "MO", i, 2, liveRnrMO.liveMORunners,)} >
                                                                                                                    <span className="num">{molive.layPrice2 == "0" ? " " : molive.layPrice2 > 19.5 ? molive.layPrice2 : ProtoTypes.Commas(molive.layPrice2)}</span>

                                                                                                                    <span className="btm-num">{molive.layPrice2 == "0" ? " " : kFormatter(molive.laySize2)}</span>
                                                                                                                </div>
                                                                                                                <div className="lsec" onClick={() => setorderDetails(lvmkt.maxBet, lvmkt.minBet, molive, molive.runnerName, mkts.marketHName, "L", "MO", i, 3, liveRnrMO.liveMORunners,)} >
                                                                                                                    <span className="num">{molive.layPrice3 == "0" ? " " : molive.layPrice3 > 19.5 ? molive.layPrice3 : ProtoTypes.Commas(molive.layPrice3)}</span>

                                                                                                                    <span className="btm-num">{molive.layPrice3 == "0" ? " " : kFormatter(molive.laySize3)}</span>
                                                                                                                </div>

                                                                                                                {molive.runnerStatus != "" && <div className="ov"><span>{molive.runnerStatus == "Suspend" ? "Suspended" : molive.runnerStatus}</span></div>}

                                                                                                                {liveRnrMO.marketStatus != "" && <div className="ov"><span>{liveRnrMO.marketStatus}</span></div>}

                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                ))
                                                                                                    :

                                                                                                    <>
                                                                                                        <div className="body-items mb-1" >

                                                                                                            <div className='  col-sm-6 col-xs-12'>
                                                                                                                <span>{evtDetail.eventName.split(' v ')[0]}</span>

                                                                                                            </div>
                                                                                                            <div className='col-sm-6 col-xs-12 ratelb justify-content-end'>

                                                                                                                <div className='rgtlbsec cursorclass'>

                                                                                                                    <div className="bsec ">
                                                                                                                        <span className="num">{""}</span>
                                                                                                                        <span className=" btm-num">{" "}</span>
                                                                                                                    </div>
                                                                                                                    <div className="bsec ">
                                                                                                                        <span className="num">{""}</span>
                                                                                                                        <span className=" btm-num">{" "}</span>
                                                                                                                    </div>
                                                                                                                    <div className="bsec ">
                                                                                                                        <span className="num">{""}</span>
                                                                                                                        <span className=" btm-num">{" "}</span>
                                                                                                                    </div>
                                                                                                                    <div className="lsec" >
                                                                                                                        <span className="num">{" "}</span>
                                                                                                                        <span className="btm-num">{" "}</span></div>
                                                                                                                    <div className="lsec" >
                                                                                                                        <span className="num">{" "}</span>
                                                                                                                        <span className="btm-num">{" "}</span></div>
                                                                                                                    <div className="lsec" >
                                                                                                                        <span className="num">{" "}</span>
                                                                                                                        <span className="btm-num">{" "}</span></div>
                                                                                                                    <div className="ov"><span>{"Suspended"}</span></div>

                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </div>
                                                                                                        <div className="body-items" >

                                                                                                            <div className=' col-sm-6 col-xs-12'>
                                                                                                                <span > <span>{evtDetail.eventName.split(' v ')[1]}</span></span>

                                                                                                            </div>
                                                                                                            <div className='col-sm-6 col-xs-12 ratelb justify-content-end'>

                                                                                                                <div className='rgtlbsec cursorclass'>

                                                                                                                    <div className="bsec ">
                                                                                                                        <span className="num">{""}</span>
                                                                                                                        <span className=" btm-num">{" "}</span>
                                                                                                                    </div>
                                                                                                                    <div className="bsec ">
                                                                                                                        <span className="num">{""}</span>
                                                                                                                        <span className=" btm-num">{" "}</span>
                                                                                                                    </div>
                                                                                                                    <div className="bsec ">
                                                                                                                        <span className="num">{""}</span>
                                                                                                                        <span className=" btm-num">{" "}</span>
                                                                                                                    </div>
                                                                                                                    <div className="lsec" >
                                                                                                                        <span className="num">{" "}</span>
                                                                                                                        <span className="btm-num">{" "}</span></div>
                                                                                                                    <div className="lsec" >
                                                                                                                        <span className="num">{" "}</span>
                                                                                                                        <span className="btm-num">{" "}</span></div>
                                                                                                                    <div className="lsec" >
                                                                                                                        <span className="num">{" "}</span>
                                                                                                                        <span className="btm-num">{" "}</span></div>
                                                                                                                    <div className="ov"><span>{"Suspended"}</span></div>

                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </div>

                                                                                                    </>
                                                                                            }
                                                                                            {mkts.liveMarkets && mkts.liveMarkets[0] && (liveNoti.length > 0) && (liveNoti.find(s => s.marketId == mkts.liveMarkets[0].marId)) &&
                                                                                                < div className='col-sm-12 one-itm-alert text-base'>
                                                                                                    {getnotimsg(liveNoti.find(s => s.marketId == mkts.liveMarkets[0].marId))}
                                                                                                </div>}
                                                                                        </div>

                                                                                    </>
                                                                                ))
                                                                                :
                                                                                <>
                                                                                    <div className='left-div'>
                                                                                        {mkts.liveMarkets && mkts.marketHName !== "Goal Lines" && [...Array(Math.ceil(mkts?.liveMarkets?.length / 2))]?.map((e, i) =>
                                                                                            mkts.marketHName == "Bookmaker" || catId.current === 1 || catId.current === 2 ?
                                                                                                <div className='col-12 mb-3 '>

                                                                                                    {mkts.liveMarkets?.length != 1 && <span className='mknmcl'>{getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marName}</span>}
                                                                                                    <div className='cardBg'>
                                                                                                        {<span className='Mob-MM'>
                                                                                                            <span className='minsp'>Min: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.minBet)}</span>&nbsp;
                                                                                                            <span className='maxsp'>Max: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.maxBet)}</span>
                                                                                                        </span>}
                                                                                                        <div className="body-items mb-1" key={i} >

                                                                                                            <span></span>
                                                                                                            <div className='rgtlbsec cursorclass'>
                                                                                                                <span className='minsp'>Min: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.minBet)}</span>&nbsp;
                                                                                                                <span className='maxsp'>Max: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.maxBet)}</span>

                                                                                                                <div className='tbsec'>
                                                                                                                    <span className="num">Back</span>
                                                                                                                </div>
                                                                                                                <div className='tlsec'>
                                                                                                                    <span className="num">Lay</span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>

                                                                                                        {(livemrkts?.length > 0) && (livemrkts.find(s => (s.marketID).toString() === getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId)) && (livemrkts.find(s => (s.marketID).toString() === getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId)[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'liveMORunners' :'liveMRunners']?.length > 0) ?

                                                                                                            livemrkts.find(s => (s.marketID).toString() === getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId)[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker" ? 'liveMORunners' :'liveMRunners']?.map((bmlive, j) => (

                                                                                                                <div className="body-items mb-1" key={j}>

                                                                                                                    <div className='dpflx'>
                                                                                                                        <span className="rnr-nm" >{bmlive.runnerName} &nbsp;<span onClick={() => OpenMyBetModel()} className={getBMTotalClass(bmlive.marketId, j)} style={{ marginLeft: "10px" }} >{getBMTotal(bmlive.marketId, j)}</span></span>

                                                                                                                    </div>
                                                                                                                    <div className='dpflx'>


                                                                                                                        <div className='rgtlbsec cursorclass '>

                                                                                                                            <div className="bsec" onClick={() => setorderDetails(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.maxBet, getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.minBet, bmlive, bmlive.runnerName, mkts.marketHName, "B", "BM", j)} >

                                                                                                                                {bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'backPrice1' :'backPrice'] == "0" ? <div className='num pt7'>-</div> : <>
                                                                                                                                    <span className="num">{bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'backPrice1' :'backPrice'] == "0" ? "" : bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'backPrice1' :'backPrice']}</span>
                                                                                                                                    <span className="btm-num">{bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker" ? 'backPrice1' :'backPrice'] == "0" ? "" : kFormatter(bmlive?.maxBet)}</span>
                                                                                                                                </>}

                                                                                                                            </div>
                                                                                                                            <div className="lsec" onClick={() => setorderDetails(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.maxBet, getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.minBet, bmlive, bmlive.runnerName, mkts.marketHName, "L", "BM", j)}>
                                                                                                                                {bmlive[(catId.current === 1 || catId.current === 2)  && mkts.marketHName !== "Bookmaker" ? 'layPrice1' :'layPrice'] == "0" ? <div className='num pt7'>-</div> : <>

                                                                                                                                    <span className="num">{bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'layPrice1' :'layPrice'] == "0" ? "" : bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'layPrice1' :'layPrice']}</span>
                                                                                                                                    <span className="btm-num">{bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'layPrice1' :'layPrice'] == "0" ? "" : kFormatter(bmlive?.maxBet)}</span>
                                                                                                                                </>}
                                                                                                                            </div>

                                                                                                                            {bmlive.runnerStatus != "" && <div className="ov"><span>{bmlive.runnerStatus == "Suspend" ? "Suspended" : bmlive.runnerStatus}</span></div>}

                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>

                                                                                                            )) :
                                                                                                            <>
                                                                                                                <div className="body-items mb-1" >

                                                                                                                    <div className='dpflx'>
                                                                                                                        <span>{evtDetail.eventName.split(' v ')[0]}</span>

                                                                                                                    </div>
                                                                                                                    <div className='dpflx'>

                                                                                                                        <div className='rgtlbsec cursorclass'>

                                                                                                                            <div className="bsec ">
                                                                                                                                <span className="num">{""}</span>
                                                                                                                                <span className=" btm-num">{" "}</span>

                                                                                                                            </div>
                                                                                                                            <div className="lsec" >
                                                                                                                                <span className="num">{" "}</span>
                                                                                                                                <span className="btm-num">{" "}</span></div>
                                                                                                                            <div className="ov"><span>{"Suspended"}</span></div>


                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>
                                                                                                                <div className="body-items" >

                                                                                                                    <div className='dpflx'>
                                                                                                                        <span > <span>{evtDetail.eventName.split(' v ')[1]}</span></span>

                                                                                                                    </div>
                                                                                                                    <div className='dpflx'>

                                                                                                                        <div className='rgtlbsec cursorclass'>

                                                                                                                            <div className="bsec ">
                                                                                                                                <span className="num">{""}</span>
                                                                                                                                <span className=" btm-num">{" "}</span>

                                                                                                                            </div>
                                                                                                                            <div className="lsec" >
                                                                                                                                <span className="num">{" "}</span>
                                                                                                                                <span className="btm-num">{" "}</span></div>
                                                                                                                            <div className="ov"><span>{"Suspended"}</span></div>

                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>

                                                                                                            </>

                                                                                                        }
                                                                                                        {(liveNoti?.length > 0) && (liveNoti.find(s => s.marketId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId)) &&
                                                                                                            < div className='col-sm-12 one-itm-alert text-base'>
                                                                                                                {getnotimsg(liveNoti.find(s => s.marketId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId))}

                                                                                                            </div>}
                                                                                                    </div>
                                                                                                </div> :
                                                                                                <>
                                                                                                    {(livemrkts?.length > 0) && (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId)) && (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId).liveMRunners?.length == 0) ? null :
                                                                                                        <div className="col-12 cardBg mb-1">
                                                                                                            <div className='one-itm-new'>
                                                                                                                <div className=" col-sm-5 col-xs-12  " >
                                                                                                                    <span>{getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marName}</span> {" "} <span className={expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId) != undefined && expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId).exposure <= 0 ? 'negative' : 'positive'} onClick={() => OpenMyBetModel()} >{expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId) && ProtoTypes.Commas(expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId).exposure)}</span>
                                                                                                                    <span className='Mob-MM'>
                                                                                                                        {expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId) && expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId).count > 0 && <img onClick={() => setLadderData(getLiveMarketByindex(mkts?.liveMarkets, i, 'left'))} src='assets/img/ico-chart.svg' height={'16px'} width={'16px'} style={{ marginRight: '10px' }} data-bs-toggle="modal" data-bs-target="#betchart-Modal" />}
                                                                                                                        <span>Min: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.minBet)}</span>&nbsp;
                                                                                                                        <span>Max: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.maxBet)}</span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className='rgtsec col-sm-7 col-xs-12  cursorclass'>
                                                                                                                    <div className='msec mt-1'>
                                                                                                                        {expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId) && expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId).count > 0 && <img src='assets/img/ico-chart.svg' onClick={() => setLadderData(getLiveMarketByindex(mkts?.liveMarkets, i, 'left'))} height={'16px'} width={'16px'} data-bs-toggle="modal" data-bs-target="#betchart-Modal" />}
                                                                                                                        <div style={{ marginLeft: '5px' }}>Min: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.minBet)}</div>
                                                                                                                        <div style={{ marginLeft: '5px' }}>Max: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.maxBet)}</div>
                                                                                                                    </div>
                                                                                                                    <div className='mt-sec  mt-1'>
                                                                                                                        {(livemrkts?.length > 0) && (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId)) ?

                                                                                                                            (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId).liveMRunners)?.map((nrmllvmkt, k) => (
                                                                                                                                nrmllvmkt.isDisplay && <div className='prcSec mb-1' key={k}>

                                                                                                                                    <div className="num2" onClick={() => setorderDetails(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.maxBet, getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.minBet, nrmllvmkt, getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marName + "-" + nrmllvmkt.layOdds, mkts.marketHName, "L", "LM")}>
                                                                                                                                        <div>   <span className="uprsec">{nrmllvmkt.layPrice == "0" ? "" : nrmllvmkt.layPrice}</span>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <span className="dwnsec">{nrmllvmkt.layOdds == "0" ? "" : nrmllvmkt.layOdds}</span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="num1" onClick={() => setorderDetails(getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.maxBet, getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.minBet, nrmllvmkt, getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marName + "-" + nrmllvmkt.backOdds, mkts.marketHName, "B", "LM")}>
                                                                                                                                        <div>      <span className="uprsec">{nrmllvmkt.backPrice == "0" ? "" : nrmllvmkt.backPrice}</span>
                                                                                                                                        </div> <div>
                                                                                                                                            <span className="dwnsec">{nrmllvmkt.backOdds == "0" ? "" : nrmllvmkt.backOdds}</span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    {nrmllvmkt.runnerStatus != "" && <div className="ov2"><span>{nrmllvmkt.runnerStatus == "Suspend" ? "Suspended" : nrmllvmkt.runnerStatus}</span></div>}

                                                                                                                                </div>
                                                                                                                            )) :
                                                                                                                            <>

                                                                                                                                <div className='prcSec  ' >

                                                                                                                                    <div className="num2">
                                                                                                                                        <span className="">{""}</span>

                                                                                                                                    </div>
                                                                                                                                    <div className="num1" >
                                                                                                                                        <span className="">{""}</span>

                                                                                                                                    </div>
                                                                                                                                    <div className="ov2"><span>{"Suspended"}</span></div>

                                                                                                                                </div>
                                                                                                                            </>

                                                                                                                        }
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {(liveNoti?.length > 0) && (liveNoti.find(s => s.marketId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId)) &&
                                                                                                                < div className='col-sm-12 one-itm-alert text-base'>
                                                                                                                    {getnotimsg(liveNoti.find(s => s.marketId == getLiveMarketByindex(mkts?.liveMarkets, i, 'left')?.marId))}

                                                                                                                </div>}

                                                                                                        </div>
                                                                                                    }
                                                                                                </>
                                                                                        )}
                                                                                        {
                                                                                            mkts?.liveMarkets && mkts?.marketHName === "Goal Lines" && mkts?.liveMarkets?.length > 0 &&
                                                                                            <div className="col-12 cardBg">
                                                                                                {
                                                                                                    getGoalLineSlider(mkts)
                                                                                                }

                                                                                                {
                                                                                                    getGoalLineData(mkts)
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='right-div'>
                                                                                        {mkts.liveMarkets && mkts.marketHName !== "Goal Lines" && [...Array(Math.ceil(mkts?.liveMarkets?.length / 2))]?.map((e, i) =>
                                                                                            getLiveMarketByindex(mkts?.liveMarkets, i, 'right') ? mkts.marketHName == "Bookmaker"  || (catId.current === 1 || catId.current === 2) ?
                                                                                                <div className='col-12 mb-3'>
                                                                                                    {mkts.liveMarkets?.length != 1 && <span className='mknmcl'>{getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marName}</span>}
                                                                                                    <div className='cardBg'>
                                                                                                        {<span className='Mob-MM'>
                                                                                                            <span className='minsp'>Min: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.minBet)}</span>&nbsp;
                                                                                                            <span className='maxsp'>Max: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.maxBet)}</span>
                                                                                                        </span>}
                                                                                                        <div className="body-items mb-1" key={i} >

                                                                                                            <span></span>
                                                                                                            <div className='rgtlbsec cursorclass'>
                                                                                                                <span className='minsp'>Min: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.minBet)}</span>&nbsp;
                                                                                                                <span className='maxsp'>Max: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.maxBet)}</span>

                                                                                                                <div className='tbsec'>
                                                                                                                    <span className="num">Back</span>
                                                                                                                </div>
                                                                                                                <div className='tlsec'>
                                                                                                                    <span className="num">Lay</span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>

                                                                                                        {(livemrkts?.length > 0) && (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId)) && (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId)[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'liveMORunners' :'liveMRunners']?.length > 0) ?

                                                                                                            livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId)[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'liveMORunners' :'liveMRunners']?.map((bmlive, j) => (

                                                                                                                <div className="body-items mb-1" key={j}>

                                                                                                                    <div className='dpflx'>
                                                                                                                        <span className="rnr-nm" >{bmlive.runnerName} &nbsp;<span onClick={() => OpenMyBetModel()} className={getBMTotalClass(bmlive.marketId, j)} style={{ marginLeft: "10px" }} >{getBMTotal(bmlive.marketId, j)}</span></span>

                                                                                                                    </div>
                                                                                                                    <div className='dpflx'>


                                                                                                                        <div className='rgtlbsec cursorclass '>

                                                                                                                            <div className="bsec" onClick={() => setorderDetails(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.maxBet, getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.minBet, bmlive, bmlive.runnerName, mkts.marketHName, "B", "BM", j)} >

                                                                                                                                {bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'backPrice1' :'backPrice'] == "0" ? <div className='num pt7'>-</div> : <>
                                                                                                                                    <span className="num">{bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'backPrice1' :'backPrice'] == "0" ? "" : bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'backPrice1' :'backPrice']}</span>
                                                                                                                                    <span className="btm-num">{bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'backPrice1' :'backPrice'] == "0" ? "" : kFormatter(bmlive?.maxBet)}</span>
                                                                                                                                </>}

                                                                                                                            </div>
                                                                                                                            <div className="lsec" onClick={() => setorderDetails(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.maxBet, getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.minBet, bmlive, bmlive.runnerName, mkts.marketHName, "L", "BM", j)}>
                                                                                                                                {bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'layPrice1' :'layPrice'] == "0" ? <div className='num pt7'>-</div> : <>

                                                                                                                                    <span className="num">{bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'layPrice1' :'layPrice'] == "0" ? "" : bmlive[(catId.current === 1 || catId.current === 2) && mkts.marketHName !== "Bookmaker"  ? 'layPrice1' :'layPrice']}</span>
                                                                                                                                    <span className="btm-num">{bmlive[(catId.current === 1 || catId.current === 2)  && mkts.marketHName !== "Bookmaker" ? 'layPrice1' :'layPrice'] == "0" ? "" : kFormatter(bmlive?.maxBet)}</span>
                                                                                                                                </>}
                                                                                                                            </div>

                                                                                                                            {bmlive.runnerStatus != "" && <div className="ov"><span>{bmlive.runnerStatus == "Suspend" ? "Suspended" : bmlive.runnerStatus}</span></div>}

                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>

                                                                                                            )) :
                                                                                                            <>
                                                                                                                <div className="body-items mb-1" >

                                                                                                                    <div className='dpflx'>
                                                                                                                        <span>{evtDetail.eventName.split(' v ')[0]}</span>

                                                                                                                    </div>
                                                                                                                    <div className='dpflx'>

                                                                                                                        <div className='rgtlbsec cursorclass'>

                                                                                                                            <div className="bsec ">
                                                                                                                                <span className="num">{""}</span>
                                                                                                                                <span className=" btm-num">{" "}</span>

                                                                                                                            </div>
                                                                                                                            <div className="lsec" >
                                                                                                                                <span className="num">{" "}</span>
                                                                                                                                <span className="btm-num">{" "}</span></div>
                                                                                                                            <div className="ov"><span>{"Suspended"}</span></div>


                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>
                                                                                                                <div className="body-items" >

                                                                                                                    <div className='dpflx'>
                                                                                                                        <span > <span>{evtDetail.eventName.split(' v ')[1]}</span></span>

                                                                                                                    </div>
                                                                                                                    <div className='dpflx'>

                                                                                                                        <div className='rgtlbsec cursorclass'>

                                                                                                                            <div className="bsec ">
                                                                                                                                <span className="num">{""}</span>
                                                                                                                                <span className=" btm-num">{" "}</span>

                                                                                                                            </div>
                                                                                                                            <div className="lsec" >
                                                                                                                                <span className="num">{" "}</span>
                                                                                                                                <span className="btm-num">{" "}</span></div>
                                                                                                                            <div className="ov"><span>{"Suspended"}</span></div>

                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>

                                                                                                            </>

                                                                                                        }
                                                                                                        {(liveNoti?.length > 0) && (liveNoti.find(s => s.marketId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId)) &&
                                                                                                            < div className='col-sm-12 one-itm-alert text-base'>
                                                                                                                {getnotimsg(liveNoti.find(s => s.marketId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId))}

                                                                                                            </div>}
                                                                                                    </div>
                                                                                                </div> :
                                                                                                <>
                                                                                                    {(livemrkts?.length > 0) && (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId)) && (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId).liveMRunners?.length == 0) ? null :
                                                                                                        <div className="col-12 cardBg mb-1">
                                                                                                            <div className='one-itm-new'>
                                                                                                                <div className=" col-sm-5 col-xs-12  " >
                                                                                                                    <span>{getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marName}</span> {" "} <span className={expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId) != undefined && expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId).exposure <= 0 ? 'negative' : 'positive'} onClick={() => OpenMyBetModel()} >{expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId) && ProtoTypes.Commas(expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId).exposure)}</span>
                                                                                                                    <span className='Mob-MM'>
                                                                                                                        {expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId) && expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId).count > 0 && <img onClick={() => setLadderData(getLiveMarketByindex(mkts?.liveMarkets, i, 'right'))} src='assets/img/ico-chart.svg' height={'16px'} width={'16px'} style={{ marginRight: '10px' }} data-bs-toggle="modal" data-bs-target="#betchart-Modal" />}
                                                                                                                        <span>Min: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.minBet)}</span>&nbsp;
                                                                                                                        <span>Max: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.maxBet)}</span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className='rgtsec col-sm-7 col-xs-12  cursorclass'>
                                                                                                                    <div className='msec mt-1'>
                                                                                                                        {expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId) && expData.find(s => s.marId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId).count > 0 && <img src='assets/img/ico-chart.svg' onClick={() => setLadderData(getLiveMarketByindex(mkts?.liveMarkets, i, 'right'))} height={'16px'} width={'16px'} data-bs-toggle="modal" data-bs-target="#betchart-Modal" />}
                                                                                                                        <div style={{ marginLeft: '5px' }}>Min: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.minBet)}</div>
                                                                                                                        <div style={{ marginLeft: '5px' }}>Max: {kFormatter(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.maxBet)}</div>
                                                                                                                    </div>
                                                                                                                    <div className='mt-sec  mt-1'>
                                                                                                                        {(livemrkts?.length > 0) && (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId)) ?

                                                                                                                            (livemrkts.find(s => s.marketID == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId).liveMRunners)?.map((nrmllvmkt, k) => (
                                                                                                                                nrmllvmkt.isDisplay && <div className='prcSec' key={k}>

                                                                                                                                    <div className="num2" onClick={() => setorderDetails(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.maxBet, getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.minBet, nrmllvmkt, getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marName + "-" + nrmllvmkt.layOdds, mkts.marketHName, "L", "LM")}>
                                                                                                                                        <div>   <span className="uprsec">{nrmllvmkt.layPrice == "0" ? "" : nrmllvmkt.layPrice}</span>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <span className="dwnsec">{nrmllvmkt.layOdds == "0" ? "" : nrmllvmkt.layOdds}</span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="num1" onClick={() => setorderDetails(getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.maxBet, getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.minBet, nrmllvmkt, getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marName + "-" + nrmllvmkt.backOdds, mkts.marketHName, "B", "LM")}>
                                                                                                                                        <div>      <span className="uprsec">{nrmllvmkt.backPrice == "0" ? "" : nrmllvmkt.backPrice}</span>
                                                                                                                                        </div> <div>
                                                                                                                                            <span className="dwnsec">{nrmllvmkt.backOdds == "0" ? "" : nrmllvmkt.backOdds}</span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    {nrmllvmkt.runnerStatus != "" && <div className="ov2"><span>{nrmllvmkt.runnerStatus == "Suspend" ? "Suspended" : nrmllvmkt.runnerStatus}</span></div>}

                                                                                                                                </div>
                                                                                                                            )) :
                                                                                                                            <>

                                                                                                                                <div className='prcSec' >

                                                                                                                                    <div className="num2">
                                                                                                                                        <span className="">{""}</span>

                                                                                                                                    </div>
                                                                                                                                    <div className="num1" >
                                                                                                                                        <span className="">{""}</span>

                                                                                                                                    </div>
                                                                                                                                    <div className="ov2"><span>{"Suspended"}</span></div>

                                                                                                                                </div>
                                                                                                                            </>

                                                                                                                        }
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {(liveNoti?.length > 0) && (liveNoti.find(s => s.marketId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId)) &&
                                                                                                                < div className='col-sm-12 one-itm-alert text-base'>
                                                                                                                    {getnotimsg(liveNoti.find(s => s.marketId == getLiveMarketByindex(mkts?.liveMarkets, i, 'right')?.marId))}

                                                                                                                </div>}

                                                                                                        </div >
                                                                                                    }
                                                                                                </> : null
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div >
                                                                    </div>
                                                                </div>

                                                            </div>


                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/*Banner*/}


                <div className='bottomBar'>
                    <div className='OneClick' id="dvOneClick">
                        {showSingleClick ? <span className='float-start pt-2 cursorclass'><strong data-bs-toggle="modal" data-bs-target="#one-click-Modal" >1-Click Bet</strong></span> :
                            <span className='float-start pt-2'><strong  >1-Click Bet</strong></span>}
                        <label className="switch mt-0">
                            <input type="checkbox" checked={showSingleClick} onChange={() => enableSClick()} />
                            <span data-on="On" data-off="Off" className="slider"></span>
                        </label>
                        {showSingleClick && <button className={disabSec == "disabled" ? "actbtn one-clck-disable" : 'actbtn'} data-bs-toggle="modal" data-bs-target="#one-click-Modal" >{activeTab}</button>}

                    </div>
                    <a href="#0" className="count-btn" onClick={() => setChartDrpdwn(true)} id="myBetModel" title="My Bets" data-bs-toggle="modal" data-bs-target="#betchart-Modal">
                        {betCount}
                    </a>
                    <a href="#0" id="#tr" style={{ visibility: "hidden" }} className="click-bt" title="My Bets" data-bs-toggle="modal" data-bs-target="#betDetails-Modal">
                        -
                    </a>
                </div>

            </div >

            {/*Middle Body*/}
            {/*Right Box Chat*/}

            {showTvModal &&
                <ReactModal
                    initWidth={420}
                    Width={420}
                    intiHeight={280}
                    left={12}
                    top={70}
                    minHeight={280}
                    height={280}
                    isOpen={modalIsOpen}
                    disableResize={false}>
                    {TvUrl !== "" ? <iframe style={{ width: "420px", height: "280px" }} src={TvUrl}>Sand</iframe> : ""}
                    <button id='resizetv' className="videoClose" onClick={() => closeModal()}>X</button>
                </ReactModal>
            }
            <div className="right-site-menu">
                <div className="right-box" style={{ width: "310px" }}>
                    <div className="right-wrapper-area minht-700">
                        <div className="combo-box">
                            <div className="detail-match-winner" >
                                <div className="details-match-wrap" >
                                    <div className="accordion ">
                                        <div className="accordion-item">
                                            <div className="accordion-header" onClick={() => handleClickTv(evtId.current)}>
                                                <button className="accordion-button collapsed btn-tv" type="button" data-bs-toggle="collapse" data-bs-target="#tv-modal1" aria-expanded="false" aria-controls="tv-modal1">
                                                    <span className='float-start pt-1' style={{ cursor: "pointer" }}><img src='./assets/img/ico-tv2.svg' height={"24px"} /></span>
                                                </button>
                                                <div id="tv-modal1" className="accordion-collapse">
                                                    <div className="accordion-body ">
                                                        {showimage && <span><img onClick={() => openModal()} className='fullscreen' src='assets/img/ico-fullscreen.svg' height={"16px"} style={{ cursor: "pointer" }}></img></span>}
                                                        {miniTv && <>
                                                            {TvUrl !== "" ? <iframe style={{ width: "270px", height: "180px" }} id='tv-iframe' src={TvUrl}></iframe> : ""}  </>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="combo-box">
                            <div>
                                <div className={clickbetloader ? "loaderClick" : "loaderClick2"} ><img src="assets/img/loader.gif" alt="loader" width={"60"} /></div>
                                <div>
                                    <span className='float-start pt-2'><strong>1-Click Bet</strong></span>
                                    <label className="switch float-end mt-0">
                                        <input type="checkbox" checked={showSingleClick} onChange={() => enableSClick()} />
                                        <span data-on="On" data-off="Off" className="slider"></span>
                                    </label>

                                </div>
                                <div style={{ clear: "both", height: "10px" }}></div>

                                {showSingleClick && !isEdit && <div className='row fxprc-sec p-0'>
                                    <div className='col-md-10 me-0 pe-0'>
                                        {singleClickedBtns && singleClickedBtns?.map((stk, i) => (
                                            <>
                                                <span key={i} className={activeTab == stk.stakeValue && disabSec != "disabled" ? 'cursorclass st-btn actbtn' : activeTab == stk.stakeValue && disabSec == "disabled" ? "st-btn actbtn one-clck-disable" : "cursorclass st-btn"} onClick={() => setSingleClick(stk.stakeValue, i)} >
                                                    <span className="banner-feature-contentt">
                                                        {stk.stakeValue}
                                                    </span>
                                                </span>
                                            </>
                                        ))}
                                    </div>
                                    <div className="col-md-2 ps-0" > <span className="cursorclass ed-btn" onClick={() => { editStkButtons() }}>
                                        <i className='fas fa-edit'></i>
                                    </span></div>

                                </div>
                                }


                                {showSingleClick && isEdit && <div className='row  editinpt'>

                                    <div className='col-md-10 me-0 pe-0 ps-0'>
                                        {singleClickedBtns && singleClickedBtns?.map((stk, i) => (
                                            <div key={i} className="st-btn" >

                                                <input value={stk.stakeValue} type="number" name='stakeValue' className='clkbet' onChange={(e) => { handleInput(e, i) }} />


                                            </div>

                                        ))}
                                    </div>
                                    <div className='col-md-2'>
                                        <div className="" style={{ paddingTop: "8px", margin: "0px 0px" }} ><span className="cursorclass" onClick={() => updateSingleStake(singleClickedBtns)}><i className='fas fa-check'></i></span></div>
                                        <div className="" style={{ paddingTop: "4px", }} ><span className="cursorclass" onClick={() => CancelStake(false)}>
                                            <i className='fas fa-times close'></i>
                                        </span></div>
                                    </div>


                                </div>}
                            </div>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className={betTab == "1" ? "nav-link link-secondary active " : "nav-link link-secondary"} onClick={() => openBetSlip()} id="combo-tab" data-bs-toggle="tab" data-bs-target="#coombo" href="#"><span>Bet Slip</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className={betTab == "2" ? "nav-link link-secondary active " : "nav-link link-secondary"} onClick={() => openMyBet()} id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>My Bets ({betCount})</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className={betTab == "3" ? "nav-link link-secondary active " : "nav-link link-secondary"} onClick={() => openBetChart()} id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>Chart</span></a>
                                </li>
                            </ul>
                            <div className="tab-content" id="tabContentboxes">
                                <div className={betTab == "1" ? "tab-pane fade show active " : "tab-pane fade"} id="1" role="tabpanel">
                                    <div className="combo-wrapper">
                                        <div className={betsliploader ? "loader " : "loader2"} ><img src="assets/img/loader.gif" alt="loader" width={"80"} /></div>
                                        {betSection && <div className={orderDetail.type == "L" ? "combo-wrapper betNo" : orderDetail.type == "B" ? "combo-wrapper betYes": "combo-wrapper"}>
                                            <div className="close-box">
                                                <div className="close-items">
                                                    <div className="close-head">
                                                        <span>{orderDetail.eventName}</span>
                                                        <span className="close" onClick={() => setBetSection(false)}>
                                                            <i className="fas fa-xmark" />
                                                        </span>
                                                    </div>
                                                    <div className="match-fixing">
                                                        <div className="match-items">

                                                            <div className="match-items-left">

                                                                <div className="cont">
                                                                    <span>{orderDetail.marketHName}</span>
                                                                    <span className="winner tm-winner">{orderDetail.mktye == "LM" ? orderDetail.lmMarketrName : orderDetail.runnerName}</span>
                                                                </div>
                                                            </div>
                                                            <div className="match-items-right">
                                                                <div> <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />{" "}{orderDetail.price && orderDetail.price}</div>
                                                                <div className='moexp'>
                                                                    <span className='positive'> {(orderDetail.marketHName == "Bookmaker" || orderDetail.marketHName == "Match Odds") && moExp != 0 && betAmount != "" && orderDetail && ProtoTypes.Commas(moExp)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div  className="ammount-items">
                                                <form action="#">
                                                    <input type="text" value={betAmount} ref={inputRef} onChange={(e) => checkNumber(e)} placeholder="Bet Amount" style={{ border: orderDetail.type == "B" ? "#72bbef 2px solid" : orderDetail.type == "L" ? "#f994ba 2px solid" : "" }} />
                                                    {betError && <div className='errorb'>{betError}</div>}
                                                    <div className='row fxprc-sec'>
                                                        {stakebuttons && stakebuttons?.map((stk, i) => (
                                                            <div key={i} className="col-lg-3 "><span onClick={() => setManualBetAmt(stk.stakeValue)} className='cursorclass prc-btn'>{stk.label}</span></div>
                                                        ))}
                                                    </div>
                                                    <div className={isLoad ? "combo-footer disabled " : "combo-footer cursorclass"} >
                                                        <button className="cmn--btn pbtn" type='submit' onKeyPress={(e) => pressSCKey(e)} onClick={(e) => placeBet(e, 1)}>
                                                            <span> Place Bet</span>  &nbsp;
                                                            <i className="fa fa-spinner fa-spin" style={isLoad ? { "visibility": "visible" } : { "visibility": "hidden" }}></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>

                                <div className={betTab == "2" ? "tab-pane fade show active " : "tab-pane fade"} id="2" role="tabpanel">
                                    <div className='leftt-blancee'>
                                        Total Commission: <span>{COM && ProtoTypes.Commas(COM)}</span>
                                    </div>

                                    <>
                                        <div className="detail-match-winner" >
                                            <div className="details-match-wrap" >
                                                <div className="accordion" id="details-accordionscore">

                                                    {mybets && mybets?.map((bets, indx) => (
                                                        <div className="accordion-item">
                                                            <div className="popular-bets"  >
                                                                <div className="accordion-header" key={indx}>

                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#detddaislaccor" + indx} aria-expanded="false" aria-controls="detaislaccor50">
                                                                        <span>{bets.headerName} {bets.betHistories && <span>({bets.betHistories?.length})</span>}</span>
                                                                    </button>

                                                                    <div id={"detddaislaccor" + indx} className="accordion-collapse collapse show">
                                                                        <div className="accordion-body bets-according">
                                                                            <ul className="popoular-body">
                                                                                {bets.betHistories && bets.betHistories?.map((childbets, i) => (

                                                                                    childbets.isCancelled ? <del>
                                                                                        <li className="popular-items b-brdr" key={i} title='Bet Cancelled'>
                                                                                            <label className={childbets.side == "L" ? "popular-itmes-left lay-border" : "popular-itmes-left back-border"}>

                                                                                                <span className="cont">
                                                                                                    <span className="text2">
                                                                                                        {childbets.marketName}
                                                                                                    </span>
                                                                                                    <span className="text3" title='Price | Odds'>
                                                                                                        {childbets.price} |   {bets.headerName == "Match Odds" || bets.headerName == "Bookmaker" ? childbets.runnerName : childbets.odds}
                                                                                                    </span>
                                                                                                </span>

                                                                                            </label>

                                                                                            <div className="popular-itmes-right">
                                                                                                <span title='Amount'>
                                                                                                    {childbets.amount}
                                                                                                </span>
                                                                                                <div style={{ textAlign: "right", fontSize: "14px" }} title='Commision'>{childbets.commision > 0 ? (childbets.commision) : ""}</div>
                                                                                            </div>

                                                                                        </li >

                                                                                    </del> :
                                                                                        <li className="popular-items" key={i}>
                                                                                            <label className={childbets.side == "L" ? "popular-itmes-left lay-border" : "popular-itmes-left back-border"}>

                                                                                                <span className="cont">
                                                                                                    {/* <span className="text1" title='Odds'>
                                                                                                                                                                       </span> */}
                                                                                                    <span className="text2">
                                                                                                        {childbets.marketName}
                                                                                                    </span>
                                                                                                    <span className="text3" title='Price | Odds'>
                                                                                                        {childbets.price} |   {bets.headerName == "Match Odds" || bets.headerName == "Bookmaker" ? childbets.runnerName : childbets.odds}
                                                                                                    </span>

                                                                                                </span>
                                                                                            </label>

                                                                                            <div className="popular-itmes-right">
                                                                                                <span title='Amount'>
                                                                                                    {childbets.amount}
                                                                                                </span>
                                                                                                <div style={{ textAlign: "right", fontSize: "14px" }} title='Commision'>{childbets.commision > 0 ? (childbets.commision) : ""}</div>
                                                                                            </div>

                                                                                        </li >

                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}


                                                </div>
                                            </div>
                                        </div>
                                    </>

                                </div>

                                <div className={betTab == "3" ? "tab-pane fade show active " : "tab-pane fade"} id="3" role="tabpanel">
                                    <div className="combo-wrapper">

                                        <select id="betselect" value={hdName} onChange={(e) => onHdChange(e.target.value, true)}  >
                                            <option value="">Select</option>
                                            {options && options?.map((bets, indx) => (
                                                <>
                                                    <option key={indx} value={bets.marId}>{bets.marketName}</option>
                                                </>
                                            )
                                            )}
                                        </select>

                                        {showprice && <div className='mt-2'>
                                            <label htmlFor="start" className='me-2'>Start Price</label>
                                            <input //InputProps={{ inputProps: { min: 0, max: 1999 } }}
                                                value={betSPrice}
                                                className="numtext Sprice"
                                                onChange={e => { SetBetSPrice(e.target.value) }}
                                                //onChange={e => { betSPrice.current=e.target.value }}
                                                type="number"
                                                label='Start Price'
                                                onBlur={(e) => { setStartPrice(e.target.value, true) }}
                                                onFocus={(e) => { e.target.select() }}
                                                onKeyPress={(e) => { keypressHandlerSP(e) }}
                                            />
                                        </div>}

                                        <div className="ssntbdiv SesTable-Scroll" >

                                            <table aria-label='simple table' className="ssnChartb">

                                                <tbody>
                                                    {SsnTableData && SsnTableData?.map((ssn, ind) =>
                                                        ind < 13 && <tr key={ind}>
                                                            <td className="ssnprcls" >{ssn.price && ssn.price}</td>
                                                            <td className={ssn.pl ? ssn.pl < 0 ? "prc-rd ssnplcls" : "prc-grn ssnplcls" : "ssnplcls"}>{ssn.pl && ProtoTypes.Commas(ssn.pl)}</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>

                                            <table aria-label='simple table' className="ssnChartb">

                                                <tbody>
                                                    {SsnTableData && SsnTableData?.map((ssn, ind) =>
                                                        ind > 12 && ind < 26 && <tr key={ind}>
                                                            <td className="ssnprcls" >{ssn.price && ssn.price}</td>
                                                            <td className={ssn.pl ? ssn.pl < 0 ? "prc-rd ssnplcls" : "prc-grn ssnplcls" : "ssnplcls"}>{ssn.pl && ProtoTypes.Commas(ssn.pl)}</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </div >

            {/* Bet Slip PopUp */}
            <div className="modal mylogin fade custde" id="betDetails-Modal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-top">
                    <div className="modal-content ">
                        <div className="modal-header">
                            Bet Slip - <span>{orderDetail.eventName}</span>
                            <button type="button" className="close" id="close-modal" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                        </div>

                        <div className="modal-body">
                            <div className="right-site-menu modalRight">
                                <div className="right-box" >
                                    <div className="combo-box">
                                        <div className="tab-content" id="tabContentboxes">
                                            <div className={betTab == "1" ? "tab-pane fade show active " : "tab-pane fade"} id="1" role="tabpanel">

                                                <div className="combo-wrapper">
                                                    <div className={betsliploader ? "loader " : "loader2"} ><img src="assets/img/loader.gif" alt="loader" width={"80"} /></div>
                                                    <div  className={orderDetail.type == "L" ? "combo-wrapper betNo" : orderDetail.type == "B" ? "combo-wrapper betYes": "combo-wrapper"}>

                                                        <div className="close-box">
                                                            <div className="close-items">
                                                                <div className="match-fixing">
                                                                    <div className="match-items">

                                                                        <div className="cont">
                                                                            <span>{orderDetail.marketHName}</span>
                                                                        </div>
                                                                        <div> <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />{" "}{orderDetail.price && orderDetail.price}</div>

                                                                    </div>
                                                                    <div className="match-items">
                                                                        <div className="cont">
                                                                            <span className="winner tm-winner">{orderDetail.mktye == "LM" ? orderDetail.lmMarketrName : orderDetail.runnerName}</span>
                                                                        </div>
                                                                        <div className='moexp'>
                                                                            <span className='positive'> {(orderDetail.marketHName == "Bookmaker" || orderDetail.marketHName == "Match Odds") && moExp != 0 && betAmount != "" && orderDetail && ProtoTypes.Commas(moExp)}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="ammount-items">
                                                            <form action="#">
                                                                <input type="text" value={betAmount} ref={inputRefresponsive} onChange={(e) => checkNumber(e)} placeholder="Bet Amount" style={{ border: orderDetail.type == "B" ? "#72bbef 2px solid" : orderDetail.type == "L" ? "#f994ba 2px solid" : "" }} />
                                                                {betError && <div className='errorb'>{betError}</div>}
                                                                <div className='row fxprc-sec'>
                                                                    {stakebuttons && stakebuttons?.map((stk, i) => (
                                                                        <div key={i} className="col-3"><span onClick={() => setManualBetAmt(stk.stakeValue)} className='cursorclass prc-btn'>{stk.label}</span></div>

                                                                    ))}
                                                                </div>

                                                                <div className={isLoad ? "combo-footer disabled " : "combo-footer cursorclass"} >
                                                                    <button className="cmn--btn pbtn" type='submit' onKeyPress={(e) => pressSCKey(e)} onClick={(e) => placeBet(e)}>
                                                                        <span> Place Bet</span>  &nbsp;
                                                                        <i className="fa fa-spinner fa-spin" style={isLoad ? { "visibility": "visible" } : { "visibility": "hidden" }}></i>
                                                                    </button>

                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="match-fixing">
                                                {MORnrsExp?.map((val, i) => (
                                                    <div className="match-items">
                                                        <div className="cont">
                                                            <span className="winner tm-winner">{val.rnr}</span>
                                                        </div>
                                                        <div className='moexp'>
                                                            <span className={val.moex < 0 ? 'negative' : "positive"}> {val.moex != 0 && ProtoTypes.Commas(val.moex)}</span>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
            </div>

            {/* Chart PopUp */}
            <div className="modal mylogin fade custde" id="betchart-Modal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-top">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                        </div>

                        <div className="modal-body">
                            <div className="right-site-menu modalRight" style={{ marginTop: "0px" }}>
                                <div className="right-box" >
                                    <div className=" ">
                                        <div className="combo-box">
                                            {showchartDrpdwn && <ul className="nav">
                                                <li className="nav-item">
                                                    <a className={popBetTab == "2" ? "nav-link link-secondary active " : "nav-link link-secondary"} onClick={() => setPopBetTab("2")} id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>My Bets ({betCount})</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={popBetTab == "3" ? "nav-link link-secondary active " : "nav-link link-secondary"} onClick={() => setPopBetTab("3")} id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>Chart</span></a>
                                                </li>
                                            </ul>}
                                            <div className="tab-content" id="tabContentboxes">


                                                <div className={popBetTab == "2" ? "tab-pane fade show active " : "tab-pane fade"} id="2" role="tabpanel">
                                                    <div className='leftt-blancee'>
                                                        Total Commission: <span>{COM && ProtoTypes.Commas(COM)}</span>
                                                    </div>

                                                    <>
                                                        <div className="detail-match-winner" >
                                                            <div className="details-match-wrap" >
                                                                <div className="accordion" id="details-accordionscore">

                                                                    {mybets && mybets?.map((bets, indx) => (
                                                                        <div className="accordion-item" key={indx}>
                                                                            <div className="popular-bets"  >
                                                                                <div className="accordion-header" key={indx}>

                                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#detddaislaccor" + indx} aria-expanded="false" aria-controls="detaislaccor50">
                                                                                        <span>{bets.headerName}</span>
                                                                                    </button>


                                                                                    <div id={"detddaislaccor" + indx} className="accordion-collapse collapse show">
                                                                                        <div className="accordion-body bets-according">
                                                                                            <ul className="popoular-body">
                                                                                                {bets.betHistories && bets.betHistories?.map((childbets, i) => (

                                                                                                    childbets.isCancelled ? <del>
                                                                                                        <li className="popular-items b-brdr" key={i} title='Bet Cancelled'>
                                                                                                            <label className={childbets.side == "L" ? "popular-itmes-left lay-border" : "popular-itmes-left back-border"}>

                                                                                                                <span className="cont">
                                                                                                                    <span className="text2">
                                                                                                                        {childbets.marketName}
                                                                                                                    </span>
                                                                                                                    <span className="text3" title='Price | Odds'>
                                                                                                                        {childbets.price} |   {bets.headerName == "Match Odds" || bets.headerName == "Bookmaker" ? childbets.runnerName : childbets.odds}
                                                                                                                    </span>
                                                                                                                </span>

                                                                                                            </label>

                                                                                                            <div className="popular-itmes-right">
                                                                                                                <span title='Amount'>
                                                                                                                    {childbets.amount}
                                                                                                                </span>
                                                                                                                <div style={{ textAlign: "right", fontSize: "14px" }} title='Commision'>{childbets.commision > 0 ? (childbets.commision) : ""}</div>
                                                                                                            </div>

                                                                                                        </li >

                                                                                                    </del> :
                                                                                                        <li className="popular-items" key={i}>
                                                                                                            <label className={childbets.side == "L" ? "popular-itmes-left lay-border" : "popular-itmes-left back-border"}>
                                                                                                                <span className="cont">
                                                                                                                    <span className="text2">
                                                                                                                        {childbets.marketName}
                                                                                                                    </span>
                                                                                                                    <span className="text3" title='Price | Odds'>
                                                                                                                        {childbets.price} |   {bets.headerName == "Match Odds" || bets.headerName == "Bookmaker" ? childbets.runnerName : childbets.odds}
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </label>

                                                                                                            <div className="popular-itmes-right">
                                                                                                                <span title='Amount'>
                                                                                                                    {childbets.amount}
                                                                                                                </span>
                                                                                                                <div style={{ textAlign: "right", fontSize: "14px" }} title='Commision'>{childbets.commision > 0 ? (childbets.commision) : ""}</div>
                                                                                                            </div>

                                                                                                        </li >

                                                                                                ))}
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>

                                                </div>

                                                <div className={popBetTab == "3" ? "tab-pane fade show active " : "tab-pane fade"} id="3" role="tabpanel">
                                                    <div className="combo-wrapper">

                                                        {showchartDrpdwn && <select id="betselect" value={hdName} onChange={(e) => onHdChange(e.target.value, true)}  >
                                                            <option value="">Select</option>
                                                            {options && options?.map((bets, indx) => (
                                                                <>
                                                                    <option key={indx} value={bets.marId}>{bets.marketName}</option>
                                                                </>
                                                            )
                                                            )}
                                                        </select>}

                                                        {showprice && <div className='mt-2'>
                                                            <label htmlFor="start" className='me-2'>Start Price</label>
                                                            <input //InputProps={{ inputProps: { min: 0, max: 1999 } }}
                                                                value={betSPrice}
                                                                className="numtext Sprice"
                                                                onChange={e => { SetBetSPrice(e.target.value) }}
                                                                // onChange={e => { betSPrice.current=e.target.value }}
                                                                type="number"
                                                                label='Start Price'
                                                                onBlur={(e) => { setStartPrice(e.target.value, true) }}
                                                                //onBlur={(e) => { setSessionTable(e.target.value) }}
                                                                onFocus={(e) => { e.target.select() }}
                                                                onKeyPress={(e) => { keypressHandlerSP(e) }}
                                                            />
                                                        </div>}
                                                        <div className="ssntbdiv SesTable-Scroll" ref={contentWrapper} >
                                                            <table aria-label='simple table' className="ssnChartb">
                                                                <tbody>
                                                                    {SsnTableData && SsnTableData?.map((ssn, ind) =>
                                                                        ind < 13 && <tr key={ind}>
                                                                            <td className="ssnprcls" >{ssn.price && ssn.price}</td>
                                                                            <td className={ssn.pl ? ssn.pl < 0 ? "prc-rd ssnplcls" : "prc-grn ssnplcls" : "ssnplcls"}>{ssn.pl && ProtoTypes.Commas(ssn.pl)}</td>
                                                                        </tr>
                                                                    )}

                                                                </tbody>
                                                            </table>

                                                            <table aria-label='simple table' className="ssnChartb">
                                                                <tbody>
                                                                    {SsnTableData && SsnTableData?.map((ssn, ind) =>
                                                                        (ind > 12 && ind < 26) && <tr key={ind}>
                                                                            <td className="ssnprcls" >{ssn.price && ssn.price}</td>
                                                                            <td className={ssn.pl ? ssn.pl < 0 ? "prc-rd ssnplcls" : "prc-grn ssnplcls" : "ssnplcls"}>{ssn.pl && ProtoTypes.Commas(ssn.pl)}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
            </div>

            {/* 1-click PopUp */}
            <div className="modal mylogin fade custde" id="one-click-Modal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-top">
                    <div className="modal-content ">
                        <div className="modal-header p-0 m-0">
                            <button type="button" className="close" id="close-oneclickmodal" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                        </div>
                        <div className="modal-body">

                            <div>
                                <span className='float-start  pt-2'><strong >1-Click Bet</strong></span>
                                <label className="switch  mt-0">
                                    <input type="checkbox" checked={showSingleClick} onChange={() => enableSClick()} />
                                    <span data-on="On" data-off="Off" className="slider"></span>
                                </label>

                            </div>
                            <div style={{ clear: "both", height: "10px" }}></div>
                            {!isEdit && <div className='row fxprc-sec p-0'>
                                <div className='col-xs-10 col-sm-10 col-md-10 me-0 pe-0'>
                                    {singleClickedBtns && singleClickedBtns?.map((stk, i) => (
                                        <>
                                            <span key={i} className={activeTab == stk.stakeValue ? 'cursorclass st-btn actbtn' : "cursorclass st-btn"} onClick={() => setSingleClick(stk.stakeValue, i)} >
                                                <span className="banner-feature-contentt">
                                                    {stk.stakeValue}
                                                </span>
                                            </span>
                                        </>
                                    ))}
                                </div>
                                <div className="col-xs-2 col-sm-2 col-md-2 ps-0" > {showSingleClick && <span className="cursorclass ed-btn" onClick={() => { editStkButtons() }}>
                                    <i className='fas fa-edit'></i>
                                </span>}</div>

                            </div>
                            }


                            {showSingleClick && isEdit && <div className='row  editinpt'>

                                <div className='col-xs-10 col-sm-10 col-md-10 me-0 pe-0 ps-0'>
                                    {singleClickedBtns && singleClickedBtns?.map((stk, i) => (
                                        <div key={i} className="st-btn" >
                                            <input value={stk.stakeValue} type="number" name='stakeValue' className='clkbet' onChange={(e) => { handleInput(e, i) }} />
                                        </div>
                                    ))}
                                </div>
                                <div className='col-xs-2 col-sm-2 col-md-2'>
                                    <div className="" style={{ paddingTop: "8px", margin: "0px 0px" }} ><span className="cursorclass" onClick={() => updateSingleStake(singleClickedBtns)}><i className='fas fa-check'></i></span></div>
                                    <div className="" style={{ paddingTop: "4px", }} ><span className="cursorclass" onClick={() => CancelStake(false)}>
                                        <i className='fas fa-times close'></i>
                                    </span></div>
                                </div>

                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Details