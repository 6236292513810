import React from 'react'
import Footer from '../components/Footer'

const Tnc = () => {

	const onButtonClick = () => {
        const pdfUrl = `${process.env.PUBLIC_URL}/assets/img/Terms-and-condition.pdf`;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Terms-and-condition.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  return (
    <>
        <div className="body-middle pt-2">
		<div class="print-page">
			<div class="d-inline-block me-3">
				<p class="mb-0">Date: 01/01/2024</p>
				<p class="mb-0">Version: 1.0</p></div>
				<a onClick={onButtonClick} style={{cursor:"pointer"}}><i class="fas fa-download"></i></a> 
				<a href="javascript:window.print();"><i class="fas fa-print ms-3"></i></a>
				</div>
            <div className="profile-section pb-60">
                <div className="container">
                    <div className="tab-content ob-content">
                    <div className="tnc-container">
	<h1>Terms And Conditions</h1>
	<h2>General Rules</h2>
		<ul>
		<li>If a match is cancelled before it starts, all bets are void unless replayed within 48 hours.</li>
		<li>If there's a reserve day, bets will stand and be settled based on events, including the reserve day.</li>
		<li>If the venue changes but the home team stays the same, bets stand. If teams are switched, bets are void.</li>	
		<li>If weather shortens a match, bets will be settled based on the official result, including Duckworth-Lewis for limited overs.</li>	
		<li>If a bowl-off or coin toss decides the match, all bets will be void except those with a confirmed result.</li>	
		</ul>
		
	<h2>Test Matches</h2>
		<ul>
		<li>If a match starts but is abandoned for reasons other than weather (like unsafe conditions, pitch damage, protests, or other issues), We can void all bets, except for those with a clear result.</li>
		<li>If the match isn't finished within five days of the original end date, all bets will be void, except for those with a confirmed result.</li>
		</ul>
		
		<h2>Limited Over Matches</h2>
		<ul>
		<li>If a match is declared "No Result," all bets will be void except those with a clear result or where the minimum overs required were bowled.</li>
		<li>If a new toss happens on a reserve day, all bets placed after 30 minutes before the original start time will be void, except for those with a confirmed result (like win the toss markets).</li>
		</ul>
	

<h2>Settlement Rules</h2>
		<ul>
		<li>All tournament and match results will be settled based on the official scores available on the ICC website. If the official result isn't available there, then the website https://www.espncricinfo.com/ will be used.</li>
		<li>If a batsman retires during an innings, it won't count as a wicket for settlement.</li>
		<li>"Innings reaching its natural conclusion" means an innings that ends naturally, without interruptions like rain or bad light. If a team is bowled out or declares, it is considered a natural conclusion.</li>
		<li>For all bets, if the required number of overs hasn't been reached, but the innings ends naturally, the bets will still be valid.</li>
		<li>"First Class matches" refer to matches lasting three or more days between two teams of eleven players, including Test Matches.</li>
		</ul>	

<h2>Player Runs (Batter Runs/Fours/Sixes)</h2>
<span><strong>Description:</strong> How many runs will the named batter score?</span>
<h3>Rules:</h3>
		<ul>
		<li>If the batter is not out at the end of the innings, their final score will count.</li>
		<li>If the batter does not bat, the bet is void.</li>
		<li>If the batter retires hurt but returns later, the total runs scored by that batter will count. If they don't return, the score when they retired will count.</li>
		<li>In limited overs matches, bets are void if less than 80% of the scheduled overs are completed due to external factors like bad weather, unless the result is already determined.</li>
		<li>Runs scored in a super over do not count.</li>
		</ul>

<h2>Next Over: Over/Under Runs/Run Margins</h2>
		<ul>
		<li>Market is based on total runs in the over, including extras.</li>
		<li>If the over isn't completed, all bets are void, unless the innings ends naturally.</li>
		</ul>	

<h2>Fall of Wicket</h2>
<span><strong>Description:</strong> How many runs will the named batter score?</span>
<h3>Rules:</h3>
		<ul>
		<li>If the batter isn't out at the end, their final score counts.</li>
		<li>If the batter doesn't bat, the bet is void.</li>
		<li>If the batter retires hurt and returns, total runs count. If not, the score when retired counts.</li>
		<li>In limited overs, bets are void if less than 80% of scheduled overs are completed due to external factors unless the result is already determined.</li>
		<li>Runs in a super over don't count.</li>
		</ul>

<h2>Match Betting</h2>
<span><strong>Description:</strong> Who will win the match?</span>
		<ul>
		<li>All match betting will be settled based on official competition rules. If weather affects the match, bets will follow the official result.</li>
		<li>In First Class Matches, if the result is a tie, bets will be settled as a dead-heat between both teams. Bets on a draw will lose.</li>
		<li>If a match is abandoned due to external factors, bets will be void unless a winner is declared by official rules.</li>
		<li>If a match is cancelled, all bets will be void unless it is replayed or restarted within 48 hours of the scheduled start time.</li>
		</ul>
		

<h2>Runs off Delivery</h2>
<span><strong>Description:</strong> How many runs will be scored off the specified delivery?</span>
		<ul>
		<li>The result is based on runs added to the team's total from that delivery.</li>
		<li>All illegal balls count as deliveries. For example, if an over starts with a wide, it counts as 1 run, and the next ball is considered the second delivery.</li>
		<li>Runs from extra deliveries due to free hits or illegal deliveries do not count.</li>
		<li>All runs, whether from the bat or extras, are included. For example, a wide with three extra runs counts as 4 runs.</li>
		</ul>
		
		
<h2>Runs in Over</h2>
<span><strong>Description:</strong> How many runs will be scored in the specified over?</span>
		
		<ul>
		<li>The over must be completed for bets to stand, unless the result is already clear.</li>
		<li>If an innings ends during the over, it counts as complete unless stopped by external factors like bad weather, in which case all bets are void unless already settled.</li>
		<li>If the over doesn't start for any reason, all bets are void. </li>
		<li>Extras and penalty runs in the over count towards the result.</li>
		</ul>

<h2>Runs in Session</h2>
<span><strong>Description:</strong> How many runs will be scored in the specified session?</span>
		
		<ul>
		<li>The result is based on the total runs scored by the mentioned team in that session.</li>
		<li>Extras and penalty runs will be included for settlement purposes.</li>
		</ul>


<h2>Innings Runs</h2>
<span><strong>Description:</strong> How many runs will a team score in a specified innings?</span>
		<ul>
		<li>In limited overs matches, bets are void if the allocated overs at the beginning of innings are not completed due to factors like bad weather, unless the result was already decided before the reduction.</li>
		<li>Extras and penalty runs will be included for settlement purposes.</li>
		</ul>


		<h2>Completed Match</h2>
		<ul>
		<li>This market will be settled based on whether the match ends with a result or is abandoned/no result.</li>
		<li>If the match has an official result (not abandoned or no result), "yes" wins in this market.</li>
		<li>If the match is abandoned or declared no result, "no" wins in this market.</li>
		<li>This market will carry over to any reserve day and settle based on the official result. If the match is postponed or abandoned for reasons other than weather (like unsafe conditions, protests, or other issues), We can void all bets on this market.</li>
		</ul>
	

	</div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>
  )
}

export default Tnc