import React from 'react'
import Footer from '../components/Footer'

const Boxing = () => {
    return (
        <>
                <div className="body-middle">
  {/*Breadcumnd*/}
  <div className="breadcumnd-banner">
    <div className="container">
      <div className="breadcumd-content">
        <h1>
          Boxing
        </h1>
        <ul className="bread-tag">
          <li>
            <a href="#0">
              Home
            </a>
          </li>
          <li>
            <i className="fas fa-arrow-right" />
          </li>
          <li>
            Boxing
          </li>
        </ul>
      </div>
    </div>
  </div>
  {/*Breadcumnd*/}
  {/*Main-body-tabing*/}
  <div className="main-body-tabbing">
    <div className="container">
      <div className="main-tabbing">
        <div className="tab-content" id="tabContentmain">
          {/*all tab start*/}
          <div className="tab-pane fade show active" id="betsall" role="tabpanel">
            <div className="match-table">
              {/*table five*/}
              <div className="match-table-head mt-span-7 mb-3">
                <h3>
                  Tomorrow
                </h3>
              </div>
              <div className="table-wrap mb-5">
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Islam Kanat</span>
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/argentina.png" alt="img" />
                          </span>
                          <span>Maciel Javier Francisco</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Francis Cesar</span>
                          <span className="icon">
                            <img src="assets/img/table/cesarus.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                          <span>Armenta Francisco</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Xu Can</span>
                          <span className="icon">
                            <img src="assets/img/table/boxings.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                          <span>Maciel Benitez Brandon Leon</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Campbell Tywarna</span>
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                          <span>Adams Jessica</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Boot Ella</span>
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                          <span>Putorak Zoe</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Mitchell Jayde</span>
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                          <span>Lucas Mark</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Beekin Cody</span>
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                          <span>Higgins Lachlan</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Mannix Bailey</span>
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/murray.png" alt="img" />
                          </span>
                          <span>Murray Dan</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Fawcett Jason</span>
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/argentina.png" alt="img" />
                          </span>
                          <span>Riddle Jayden</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Light David</span>
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/j-man.png" alt="img" />
                          </span>
                          <span>Singh Vikas</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Goodman Sam</span>
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                          <span>Cooper Jason</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*table five*/}
              {/*table five*/}
              <div className="match-table-head mt-span-7 mb-3">
                <h3>
                  Prematch 09.10.2022
                </h3>
              </div>
              <div className="table-wrap mb-5">
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing Women
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Romero Mary</span>
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                          <span>Scotney Ellie</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Cash Felix</span>
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <i className="icon-boxing" />
                          </span>
                          <span>Coyle Connor</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Eubank Jr Chris</span>
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                          <span>Benn Conor</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Mould Reece</span>
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/mairena.png" alt="img" />
                          </span>
                          <span>Benn Conor</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/boxings.png" alt="img" />
                      </span>
                      <span>
                        Boxing
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 8 -05:00
                      </li>
                      <li>
                        Winner. Full match
                      </li>
                      <li>
                        Total. Full match
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Fundora Sebastian</span>
                          <span className="icon">
                            <img src="assets/img/table/armenta.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/carlos.png" alt="img" />
                          </span>
                          <span>Ocampo Carlos</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>1.02</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>2.25</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>5.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>3.04</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>5.39</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+2</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*table five*/}
              {/*table five*/}
              <div className="match-table-head mt-span-7 mb-3">
                <h3>
                  Prematch 11.10.2022
                </h3>
              </div>
              <div className="table-wrap mb-5">
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/nfl.png" alt="img" />
                      </span>
                      <span>
                        USA. NFL
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 10:02:05
                      </li>
                      <li>
                        Winner, Half 1
                      </li>
                      <li>
                        Total. Half 1
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Kansas City Chiefs</span>
                          <span className="icon">
                            <img src="assets/img/table/carolina.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/sfsan.png" alt="img" />
                          </span>
                          <span>Las Vegas Raiders</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>8.58</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>84.2</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>1.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>1.84</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>1.61</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+66</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*table five*/}
              {/*table five*/}
              <div className="match-table-head mt-span-7 mb-3">
                <h3>
                  Prematch 14.10.2022
                </h3>
              </div>
              <div className="table-wrap mb-5">
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/nfl.png" alt="img" />
                      </span>
                      <span>
                        USA. NFL
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 10:02:05
                      </li>
                      <li>
                        Winner, Half 1
                      </li>
                      <li>
                        Total. Half 1
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Chicago Bears</span>
                          <span className="icon">
                            <img src="assets/img/table/pepe.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/w.png" alt="img" />
                          </span>
                          <span>Washington Commanders</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>02.54</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">X</span>
                        <span>8.65</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>1.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>1.84</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>1.61</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+66</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*table five*/}
              {/*table five*/}
              <div className="match-table-head mt-span-7 mb-3">
                <h3>
                  Prematch 16.10.2022
                </h3>
              </div>
              <div className="table-wrap">
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/nfl.png" alt="img" />
                      </span>
                      <span>
                        USA. NFL
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 10:02:05
                      </li>
                      <li>
                        Winner, Half 1
                      </li>
                      <li>
                        Total. Half 1
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Miami Dolphins</span>
                          <span className="icon">
                            <img src="assets/img/table/dolphin.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/minners.png" alt="img" />
                          </span>
                          <span>Minnesota Vikings</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>2.5</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>1.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>1.84</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>1.61</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+66</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-inner">
                  <div className="table-head">
                    <a href="details.html" className="left-compo">
                      <span>
                        <img src="assets/img/table/nfl.png" alt="img" />
                      </span>
                      <span>
                        USA. NFL
                      </span>
                    </a>
                    <ul className="right-compo">
                      <li>
                        Oct 10:02:05
                      </li>
                      <li>
                        Winner, Half 1
                      </li>
                      <li>
                        Total. Half 1
                      </li>
                    </ul>
                  </div>
                  <div className="table-body">
                    <ul className="table-body-left">
                      <li>
                        <a href="#0">
                          <span>Pittsburgh Steelers</span>
                          <span className="icon">
                            <img src="assets/img/table/steelers.png" alt="img" />
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#0" className="vs">
                          VS
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <span className="icon">
                            <img src="assets/img/table/buccanee.png" alt="img" />
                          </span>
                          <span>Tampa Bay Buccaneers</span>
                        </a>
                      </li>
                    </ul>
                    <div className="table-body-right">
                      <a href="#0" className="table-pointing-box">
                        <span className="list">1</span>
                        <span>9.12</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">2</span>
                        <span>1.19</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">O 53.5</span>
                        <span>1.84</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="list">U 53.5</span>
                        <span>1.61</span>
                      </a>
                      <a href="#0" className="table-pointing-box">
                        <span className="last-digit">+66</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/*table five*/}
            </div>
          </div>
          {/*all tab End*/}
        </div>
      </div>
    </div>
  </div>
  {/*Main-body-tabing*/}

                    <Footer />

                    {/*Footer Section*/}
                    <a href="#0" className="click-btn" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                        <i className="icon-basketball" />
                    </a>
                </div>
                {/*Middle Body*/}
                {/*Right Box Chat*/}
                <div className="right-site-menu">
                    <div className="right-box">
                        <div className="right-wrapper-area">
                            <div className="blance-items">
                                <div className="left-blance">
                                    <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                                    <span className="text-blabce">Balance</span>
                                </div>
                                <span className="blance">
                                    $300
                                </span>
                            </div>
                            <div className="betslip-wrapper">
                                <a href="#0" className="left-betslip">
                                    <span className="bed2"><img src="assets/img/header/right-icon/bed2.png" alt="icon" /></span>
                                    <span className="bed1"><img src="assets/img/header/right-icon/bed.svg" alt="icon" /></span>
                                    <span className="text-bets">Bet Slip</span>
                                </a>
                                <a href="#0" className="left-betslip">
                                    <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                                    <span className="text-bets">My Bets</span>
                                </a>
                            </div>
                            <div className="combo-box">
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a className="nav-link link-secondary" id="combo-tab" data-bs-toggle="tab" data-bs-target="#coombo" href="#"><span>Single bets</span></a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link link-secondary active" id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>Combo</span></a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="tabContentboxes">
                                    <div className="tab-pane fade" id="coombo" role="tabpanel">
                                        <div className="combo-wrapper">
                                            <div className="combo-wrapper">
                                                <div className="close-box">
                                                    <div className="close-items">
                                                        <div className="close-head">
                                                            <span>Latvia vs Moldova</span>
                                                            <span className="close">
                                                                <i className="fas fa-xmark" />
                                                            </span>
                                                        </div>
                                                        <div className="match-fixing">
                                                            <div className="match-items">
                                                                <div className="match-items-left">
                                                                    <div className="icon">
                                                                        <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                    </div>
                                                                    <div className="cont">
                                                                        <span>Moldova</span>
                                                                        <span className="winner">Match Winner</span>
                                                                    </div>
                                                                </div>
                                                                <div className="match-items-right">
                                                                    <div className="icon">
                                                                        <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                    </div>
                                                                    <span>5.20</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="combo-switch">
                                                    <span>
                                                        Accept all odds changes
                                                    </span>
                                                    <label className="switch-com">
                                                        <input type="checkbox" className="checkbox" hidden />
                                                        <span className="checkbox-label">
                                                            <span className="ball" />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="ammount-items">
                                                    <form action="#">
                                                        <input type="number" placeholder="Bet Amount" />
                                                        <button type="submit">
                                                            Max
                                                        </button>
                                                    </form>
                                                </div>
                                                <div className="possible-win">
                                                    <span>Possible win</span>
                                                    <span className="amount">$300</span>
                                                </div>
                                                <div className="combo-footer">
                                                    <a href="#0" className="cmn--btn">
                                                        <span> Place Bet $300</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show active" id="bbets" role="tabpanel">
                                        <div className="combo-wrapper">
                                            <div className="close-box">
                                                <div className="close-items">
                                                    <div className="close-head">
                                                        <span>Scotland vs Ukraine</span>
                                                        <span className="close">
                                                            <i className="fas fa-xmark" />
                                                        </span>
                                                    </div>
                                                    <div className="match-fixing">
                                                        <div className="match-items">
                                                            <div className="match-items-left">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                </div>
                                                                <div className="cont">
                                                                    <span>Scotland</span>
                                                                    <span className="winner">Match Winner</span>
                                                                </div>
                                                            </div>
                                                            <div className="match-items-right">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                </div>
                                                                <span>3.20</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items">
                                                            <div className="match-items-left">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                </div>
                                                                <div className="cont">
                                                                    <span>Draw</span>
                                                                    <span className="winner">Match Winner</span>
                                                                </div>
                                                            </div>
                                                            <div className="match-items-right">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                </div>
                                                                <span>4.60</span>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            Add 2 more outcome(s) with odds greater than 1.4 to get x1.08
                                                        </p>
                                                        <div className="match-items match-progress">
                                                            <div className="match-items-left">
                                                                <div className="prabox-wrap">
                                                                    <div className="terminal-bar">
                                                                        <span />
                                                                        <span />
                                                                        <span />
                                                                        <span />
                                                                        <span />
                                                                        <span />
                                                                        <span />
                                                                        <span />
                                                                        <span />
                                                                    </div>
                                                                    <div className="bar-count">
                                                                        <p>x1.08</p>
                                                                        <p>x1,15</p>
                                                                        <p>x1.20</p>
                                                                        <p>x1.50</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="match-items-right">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                </div>
                                                                <span>1.00</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items match-odds">
                                                            <div className="match-items-left">
                                                                <div className="cont">
                                                                    <span>Total odds</span>
                                                                </div>
                                                            </div>
                                                            <div className="match-items-right">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                </div>
                                                                <span>12.19</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="combo-switch">
                                                <span>
                                                    Accept all odds changes
                                                </span>
                                                <label className="switch-com">
                                                    <input type="checkbox" className="checkbox" hidden />
                                                    <span className="checkbox-label">
                                                        <span className="ball" />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="ammount-items">
                                                <form action="#">
                                                    <input type="number" placeholder="Bet Amount" />
                                                    <button type="submit">
                                                        Max
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="possible-win">
                                                <span>Possible win</span>
                                                <span className="amount">$300</span>
                                            </div>
                                            <div className="combo-footer">
                                                <a href="#0" className="cmn--btn">
                                                    <span> Place Bet $300</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-box">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <span className="icon"><i className="fa-sharp fa-solid fa-gear" /></span>
                                                <span>Settings</span>
                                            </button>
                                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div className="select-wrap">
                                                        <div className="select-one">
                                                            <select className="select" name="select" id="n-select">
                                                                <option value={1}>English</option>
                                                                <option value={1}>Spanish</option>
                                                            </select>
                                                            <span className="add">Lunguage</span>
                                                        </div>
                                                        <div className="select-two">
                                                            <select className="select" name="select" id="s-select">
                                                                <option value={1}>Decimal</option>
                                                                <option value={1}>Decimal Odds</option>
                                                            </select>
                                                            <span className="add">Odds</span>
                                                        </div>
                                                    </div>
                                                    <a href="#0" className="condition-box">
                                                        Terms &amp; Conditions
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popular-bets">
                            <div className="head">
                                <h6>
                                    Popular bets
                                </h6>
                            </div>
                            <ul className="popoular-body">
                                <li className="popular-items">
                                    <label className="popular-itmes-left">
                                        <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault1" />
                                        <span className="icon">
                                            <i className="icon-football" />
                                        </span>
                                        <span className="cont">
                                            <span className="text1">
                                                1x2
                                            </span>
                                            <span className="text2">
                                                Prance
                                            </span>
                                            <span className="text3">
                                                France - Austria
                                            </span>
                                        </span>
                                    </label>
                                    <div className="popular-itmes-right">
                                        <span>
                                            2.37
                                        </span>
                                    </div>
                                </li>
                                <li className="popular-items">
                                    <label className="popular-itmes-left">
                                        <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault2" />
                                        <span className="icon">
                                            <i className="icon-football" />
                                        </span>
                                        <span className="cont">
                                            <span className="text1">
                                                1x2
                                            </span>
                                            <span className="text2">
                                                Turkey
                                            </span>
                                            <span className="text3">
                                                Turkey - Luxembourg
                                            </span>
                                        </span>
                                    </label>
                                    <div className="popular-itmes-right">
                                        <span>
                                            1.37
                                        </span>
                                    </div>
                                </li>
                                <li className="popular-items">
                                    <label className="popular-itmes-left">
                                        <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault3" />
                                        <span className="icon">
                                            <i className="icon-football" />
                                        </span>
                                        <span className="cont">
                                            <span className="text1">
                                                1x2
                                            </span>
                                            <span className="text2">
                                                Alkmaar
                                            </span>
                                            <span className="text3">
                                                Alkmaar - Apollon
                                            </span>
                                        </span>
                                    </label>
                                    <div className="popular-itmes-right">
                                        <span>
                                            3.47
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            <div className="modal right-menu-modal fade" id="exampleModal3" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <button type="button" className="btn-close cross-btn" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="right-site-menu">
                                <div className="right-box">
                                    <div className="right-wrapper-area">
                                        <div className="blance-items">
                                            <div className="left-blance">
                                                <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                                                <span className="text-blabce">Balance</span>
                                            </div>
                                            <span className="blance">
                                                $300
                                            </span>
                                        </div>
                                        <div className="betslip-wrapper">
                                            <a href="#0" className="left-betslip">
                                                <span><img src="assets/img/header/right-icon/bed.svg" alt="icon" /></span>
                                                <span className="text-bets">Bet Slip</span>
                                            </a>
                                            <a href="#0" className="left-betslip">
                                                <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                                                <span className="text-bets">My Bets</span>
                                            </a>
                                        </div>
                                        <div className="combo-box">
                                            <ul className="nav">
                                                <li className="nav-item">
                                                    <a className="nav-link link-secondary" data-bs-toggle="tab" data-bs-target="#coombomobile" href="#"><span>Single bets</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link link-secondary active" data-bs-toggle="tab" data-bs-target="#bbetsmobile" href="#"><span>Combo</span></a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="tabContentboxesmobile">
                                                <div className="tab-pane fade" id="coombomobile" role="tabpanel">
                                                    <div className="combo-wrapper">
                                                        <div className="combo-wrapper">
                                                            <div className="close-box">
                                                                <div className="close-items">
                                                                    <div className="close-head">
                                                                        <span>Latvia vs Moldova</span>
                                                                        <span className="close">
                                                                            <i className="fas fa-xmark" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="match-fixing">
                                                                        <div className="match-items">
                                                                            <div className="match-items-left">
                                                                                <div className="icon">
                                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                                </div>
                                                                                <div className="cont">
                                                                                    <span>Moldova</span>
                                                                                    <span className="winner">Match Winner</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="match-items-right">
                                                                                <div className="icon">
                                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                                </div>
                                                                                <span>5.20</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="combo-switch">
                                                                <span>
                                                                    Accept all odds changes
                                                                </span>
                                                                <label className="switch-com">
                                                                    <input type="checkbox" className="checkbox" hidden />
                                                                    <span className="checkbox-label">
                                                                        <span className="ball" />
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="ammount-items">
                                                                <form action="#">
                                                                    <input type="number" placeholder="Bet Amount" />
                                                                    <button type="submit">
                                                                        Max
                                                                    </button>
                                                                </form>
                                                            </div>
                                                            <div className="possible-win">
                                                                <span>Possible win</span>
                                                                <span className="amount">$300</span>
                                                            </div>
                                                            <div className="combo-footer">
                                                                <a href="#0" className="cmn--btn">
                                                                    <span> Place Bet $300</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade show active" id="bbetsmobile" role="tabpanel">
                                                    <div className="combo-wrapper">
                                                        <div className="close-box">
                                                            <div className="close-items">
                                                                <div className="close-head">
                                                                    <span>Scotland vs Ukraine</span>
                                                                    <span className="close">
                                                                        <i className="fas fa-xmark" />
                                                                    </span>
                                                                </div>
                                                                <div className="match-fixing">
                                                                    <div className="match-items">
                                                                        <div className="match-items-left">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                            </div>
                                                                            <div className="cont">
                                                                                <span>Scotland</span>
                                                                                <span className="winner">Match Winner</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>3.20</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="match-items">
                                                                        <div className="match-items-left">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                            </div>
                                                                            <div className="cont">
                                                                                <span>Draw</span>
                                                                                <span className="winner">Match Winner</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>4.60</span>
                                                                        </div>
                                                                    </div>
                                                                    <p>
                                                                        Add 2 more outcome(s) with odds greater than 1.4 to get x1.08
                                                                    </p>
                                                                    <div className="match-items match-progress">
                                                                        <div className="match-items-left">
                                                                            <div className="prabox-wrap">
                                                                                <div className="terminal-bar">
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                </div>
                                                                                <div className="bar-count">
                                                                                    <p>x1.08</p>
                                                                                    <p>x1,15</p>
                                                                                    <p>x1.20</p>
                                                                                    <p>x1.50</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>1.00</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="match-items match-odds">
                                                                        <div className="match-items-left">
                                                                            <div className="cont">
                                                                                <span>Total odds</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>12.19</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="combo-switch">
                                                            <span>
                                                                Accept all odds changes
                                                            </span>
                                                            <label className="switch-com">
                                                                <input type="checkbox" className="checkbox" hidden />
                                                                <span className="checkbox-label">
                                                                    <span className="ball" />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="ammount-items">
                                                            <form action="#">
                                                                <input type="number" placeholder="Bet Amount" />
                                                                <button type="submit">
                                                                    Max
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="possible-win">
                                                            <span>Possible win</span>
                                                            <span className="amount">$300</span>
                                                        </div>
                                                        <div className="combo-footer">
                                                            <a href="#0" className="cmn--btn">
                                                                <span> Place Bet $300</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-box">
                                            <div className="accordion" id="accordionExamplemy">
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingThree03">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            <span className="icon"><i className="fa-sharp fa-solid fa-gear" /></span>
                                                            <span>Settings</span>
                                                        </button>
                                                        <div id="collapseThreethree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <a href="#0" className="condition-box">
                                                                    Terms &amp; Conditions
                                                                </a>
                                                                <div className="select-wrap">
                                                                    <div className="select-one">
                                                                        <select className="select" name="select" id="n-select2">
                                                                            <option value={1}>Lunguage</option>
                                                                            <option value={1}>English</option>
                                                                            <option value={1}>Spanish</option>
                                                                        </select>
                                                                        <span className="add">English</span>
                                                                    </div>
                                                                    <div className="select-two">
                                                                        <select className="select" name="select" id="s-select2">
                                                                            <option value={1}>Odds</option>
                                                                            <option value={1}>Decimal</option>
                                                                            <option value={1}>Decimal Odds</option>
                                                                        </select>
                                                                        <span className="add">Decimal</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popular-bets">
                                        <div className="head">
                                            <h6>
                                                Popular bets
                                            </h6>
                                        </div>
                                        <ul className="popoular-body">
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault4" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Prance
                                                        </span>
                                                        <span className="text3">
                                                            France - Austria
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        2.37
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault5" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Turkey
                                                        </span>
                                                        <span className="text3">
                                                            Turkey - Luxembourg
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        1.37
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault6" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Alkmaar
                                                        </span>
                                                        <span className="text3">
                                                            Alkmaar - Apollon
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        3.47
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Boxing