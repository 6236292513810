import axios from "axios";
// import { Navigate } from 'react-router-dom'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_UP_ENDPOINT}/api`,
    headers: { "Content-Type": "application/json" },
});

const LoginInstance = axios.create({
    baseURL: `${process.env.REACT_APP_UP_ENDPOINT}/`,
    headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(function (config) {
    let token = localStorage.getItem('up_token')
    if (config.url === "user/UpdateUPasswordBLogin") {
        token = localStorage.getItem('up_cp_token')
    }

    Object.assign(config.headers, { 'Authorization': 'Bearer ' + token })
    return config;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    

    if (error.config.url !== "StakeButton/GetStakeButtons")
        if (error.response.status === 403 || error.response.status === 401) {
            localStorage.removeItem('up_token');
        const replaceUrl = process.env.REACT_APP_UP_BaseName == "/stgup" ? `${process.env.REACT_APP_UP_BaseName}/` : process.env.REACT_APP_UP_BaseName ;
        window.location.replace(`${replaceUrl}`);
            //<Navigate to='/login' />
        }
    return error;
});

LoginInstance.interceptors.request.use(function (config) {
    let token = localStorage.getItem('up_token')
    if (config.url === "user/UpdateUPasswordBLogin") {
        token = localStorage.getItem('up_cp_token')
    }
    Object.assign(config.headers, { 'Authorization': 'Bearer ' + token })
    return config;
}, function (error) {
    return Promise.reject(error);
});

LoginInstance.interceptors.response.use(response => {
    return response;
}, error => {

    if (error.response.status === 403 || error.response.status === 401) {
        localStorage.removeItem('up_token');
        const replaceUrl = process.env.REACT_APP_UP_BaseName == "/stgup" ? `${process.env.REACT_APP_UP_BaseName}/` : process.env.REACT_APP_UP_BaseName ;
        window.location.replace(`${replaceUrl}`)
    }
    return error;
});


export default { instance, LoginInstance };