import api from "../apis/api"

const UpdateUPassword=(User)=>{
    return api.instance.post("/user/UpdateUPassword",User)
}
const  UpdateUPasswordBLogin=(user)=>{
    return api.instance.post("user/UpdateUPasswordBLogin",user)
}
const GetBalances=()=>{
    return api.instance.post("Transaction/GetBalances")
}
const GetExpoSummary=()=>{
    return api.instance.post("bet/GetExpoSummary")
}
const GetAllUserBets=(data)=>{
    return api.instance.post("bet/GetAllUserBets",data)
}
const GetAccountHistoryDetails=(history)=>{
    return api.instance.post("Transaction/GetUTransHistory",history)
}
const GetMarketUserBets=(marketdata)=>{
    return api.instance.post("Bet/GetMarketUserBets",marketdata)
}
const GetBanners=()=>{
    return api.instance.post("Banner/GetBanners")
}
const UserHistory=(data)=>{
    return api.instance.post("User/UserHistory",data)
}
const GetStakeButtons=(data)=>{
    return api.instance.post("StakeButton/GetStakeButtons",data)
}
const UpdateSButtons=(data)=>{
    return api.instance.post("StakeButton/UpdateSButtons",data)
}
const GetDevices = (UserId) => {
    return api.instance.post("GoogleAuth/GetDevices", { UserId })
}
const GetAuthCode = () => {
    return api.instance.post("GoogleAuth/GetAuthCode")
}
const AddGoogleAuth = (data) => {
    return api.instance.post("GoogleAuth/AddGoogleAuth", data)
}
const UpdateAuthStatus = (data) => {
    return api.instance.post("GoogleAuth/UpdateAuthStatus", data)
}
const GetG2FAStatus = (UserId) => {
    return api.instance.post("GoogleAuth/GetG2FAStatus", { UserId })
}
const DeleteGAuthDevice = (AuthId) => {
    return api.instance.post("GoogleAuth/DeleteGAuthDevice", { AuthId })
}
const CZScoreboard=(EventId)=>{
    return api.instance.post("Game/GetCZScoreboard", { EventId }) 
}

const GetEventTimeline = data => {
    return api.instance.post("/EventTimeline/GetEventTimeline", data);
};

export default {UpdateUPassword,UpdateUPasswordBLogin,GetBalances,GetExpoSummary,GetAllUserBets,
GetAccountHistoryDetails,GetMarketUserBets,GetBanners,UserHistory,GetStakeButtons,UpdateSButtons,
GetDevices, GetAuthCode, AddGoogleAuth, UpdateAuthStatus,GetG2FAStatus, DeleteGAuthDevice,CZScoreboard, GetEventTimeline};