import RoutePages from "./RoutePages";
import {HashRouter } from "react-router-dom";
import { createHashHistory } from 'history';
import {useEffect} from 'react';
import "./fonts/dfont.ttf";
import "./fonts/dfont.eot";
import "./fonts/dfont.woff";
import './fonts.css';
import 'react-toastify/dist/ReactToastify.css';
import {disableReactDevTools } from '@fvilers/disable-react-devtools' ;
import usePreventZoom from "./pages/PrZoom";


if(process.env.REACT_APP_NODE_ENV==='Production'){
  disableReactDevTools();
}

const App = () => {
  const history = createHashHistory({
    basename: process.env.REACT_APP_UP_BaseName,
  });
  
  usePreventZoom();

  useEffect(() => {
    clearCacheData()

  }, []);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };


  return (
    <HashRouter history={history}  >
      <RoutePages /> 
    </HashRouter >
  );
}

export default App;
