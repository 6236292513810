import { useEffect, useState } from "react"
import UserService from "../apis/UserService";
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

var subitemarr = []

const SidebarItem=({ item, data, loginButton})=> {
    const [subopenIndex, setsubopenIndex] = useState(null)
    const [subitems, setSubItems] = useState([])
    const navigate = useNavigate();
   
    const checkSubopen = (indx, id) => {

        setsubopenIndex(indx);
        const elements = document.querySelectorAll('*');
        elements.forEach((element) => {
            element.classList.remove('c_actvclss');
        });
        var el = document.getElementById("c_" + id);
        if (el)
            el.classList.add("c_actvclss")

    }

    useEffect(() => {

        async function calAyncFunction() {
            subitemarr = [];
            if (data != null) {
                await GetFEventsByGCat(data.catId, 1)
                var subit = [];

                if (subitems.length == 0)
                    subit = subitemarr
                else
                    subit = subitems

                var indx = subit.findIndex(a => a.compId == data.compId)
                if (indx != -1) {

                    setTimeout(() => {
                        checkSubopen(indx, data.compId);
                        var el = document.getElementById("s_" + data.eventId);
                        if (el)
                            el.classList.add("s_actvclss")
                            

                    }, 300);
                }
            }
            else{
                //hardcode for category 4
                await GetFEventsByGCat(4, 1)
            }
        }

        calAyncFunction();
    }, [data]);

    const OpenDetails = (data) => {
        
        if(!loginButton){
            const elements = document.querySelectorAll('*');
            elements.forEach((element) => {
                element.classList.remove('s_actvclss');
               
            });
    
            var el = document.getElementById("s_" + data.eventId);
            if (el)
                el.classList.add("s_actvclss")
                
            var row = {
                compId: data.compId,
                compName: data.compName,
                eventDate: data.eventDate,
                eventId: data.eventId,
                eventName: data.eventName,
                isload: true,
                catId: item.id
            }
    
            var rqstdata = CryptoJS.AES.encrypt(JSON.stringify(row), 'key@123').toString();
            localStorage.setItem("reload", "true")
            navigate('/details', {
                state: { query: rqstdata },
            })

        }else{
            const btnLoginHome = document.getElementById('btnLoginHome');
            if (btnLoginHome)
                btnLoginHome.click();
          }
        }


    const adjustClasses = (id) => {

        const elements = document.querySelectorAll('.tstel');
        elements.forEach((element) => {
            element.classList.remove('open');

        });

        const allelements = document.querySelectorAll('*');
        allelements.forEach((element) => {
            element.classList.remove('actvclss',"open");
            element.classList.remove('c_actvclss');
            element.classList.remove('s_actvclss');
        });

        var el = document.getElementById("p_" + id);
        if (el) {
            el.classList.add("actvclss")
            el.classList.add("open")
        }

        var el2 = document.getElementById("pid_" + id);
        if (el2) {
            var iexist = el2.classList.contains("opened");
            if (!iexist) {

                const allelements = document.querySelectorAll('*');
                allelements.forEach((element) => {
                    element.classList.remove('opened');
                });

                el2.classList.add("open")
                el2.classList.add("opened")
                el2.classList.add("san")
            }
            else {
                el2.classList.remove('opened');
                var el = document.getElementById("p_" + id);
                if (el) {
                    el.classList.remove("actvclss","open")
                }
            }
        }
    }

    const newAdjustClasses = (id) => {
        var el2 = document.getElementById("pid_" + id);
        if (el2)
            el2.classList.add("open")
    
        var el = document.getElementById("p_" + id);
        if (el) {
            el.classList.add("open")
            el.classList.add("actvclss")
        }else{
            el.classList.remove("open") 
              
        }
    }

    const GetFEventsByGCat = async (id, typ) => {
        try {
            setsubopenIndex(null)
            if (typ == 1)
                newAdjustClasses(id);
            else
                adjustClasses(id);

            var rqstData = {
                "GCatId": id
            }
            await UserService.GetFEventsByGCat(rqstData).then(res => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {

                        var alldata = res.data.result;
                        var CompsArray = [];
                        for (let index = 0; index < alldata.length; index++) {
                            var element = alldata[index];
                            const found = CompsArray.some(el => el.compId === element.compId);
                            if (!found)
                                CompsArray.push({ compId: element.compId, compName: element.compName })
                        }
                        for (let index = 0; index < CompsArray.length; index++) {
                            var element = CompsArray[index];
                            const AllEventsElements = alldata.filter(el => el.compId === element.compId);
                            if (AllEventsElements && AllEventsElements.length > 0) {
                                CompsArray[index].children = AllEventsElements
                            }
                        }
                        setSubItems(CompsArray)
                        subitemarr = CompsArray

                    }
                    else {
                        console.log(msg)
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    if (item.eventCount > 0) {
        return (
            <div id={"pid_" + item.id} className="sidebar-item tstel">
                {/* <div  className={parentClass == "p_" + item.id ? "actvclss sidebar-title" : "sidebar-title"}> */}
                <div id={"p_" + item.id} className="sidebar-title" onClick={() => GetFEventsByGCat(item.id)}>
                    <span>
                        {<i className={item.icon}></i>}
                        {item.name + " (" + item.eventCount + ")"}
                    </span>
                    <i className="fas fa-angle-down toggle-btn" ></i>
                </div>
                <div className="sidebar-content">
                    {subitems.map((child, index) => {

                        return (
                            <>
                                <div className={subopenIndex == index ? "sidebar-item open" : "sidebar-item"}>
                                    {child.compName != null && <div id={"c_" + child.compId} className="submenu-title" onClick={() => { checkSubopen(index, child.compId) }}>
                                        {child.compName != null && <span>
                                            {child.compName} {child.children.length > 0 && "(" + child.children.length + ")"}
                                        </span>
                                        }
                                    </div>}

                                    <div className={child.compName != null ? "sidebar-content" : ""}>
                                        {child.children && child.children.map((subchild, i) => (
                                            <>
                                                <div id={"s_" + subchild.eventId} className="sidebar-item" onClick={() => { OpenDetails(subchild) }}>
                                                    <div className="submenu-title">
                                                        <span>
                                                            {" "}
                                                            {subchild.eventName}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div >
                                </div>
                            </>
                        )
                    })}
                </div >
            </div >
        )
    } else {
        return (
            <NavLink to="javascript:void(0)" activeclassname='link-active' className="sidebar-item plain">
                {<i className={item.icon}></i>}
                {item.name}

            </NavLink>
        )
    }
}

export default SidebarItem