
import { toast } from 'react-toastify';

export const errorToastify = (error) => {
    return (
        toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            theme: 'colored',
            autoClose: 2000 ,
            
        })
    )
}
export const successToastify = (error) => {
    return (
        toast.success(error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            theme: 'colored',
            autoClose: 2000 ,
            
        })
    )
}

export default {errorToastify, successToastify}
