import React, {useState,} from "react";
import { Outlet } from 'react-router';
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import NoInternetConnection from '../utilties/NoInternetConnection';
import { Route, Link, Routes, useLocation } from 'react-router-dom';

const Layout = () => {


  const location = useLocation();
  const { hash, pathname, search } = location;
  const [callApi, SetcallApi] = useState(false)
  const [loadStake, SetLoadStake] = useState(false)
  const [sidebarDetails, SetsidebarDetails] = useState({})
  const [loginButton, SetloginButton] = useState(false)
  const [balInfo,setBalInfo]=useState(false)




  return (
    <>
      <NoInternetConnection>
    
    
    
  {pathname !="/tnc" &&   <Topbar data={callApi} stakeFunction={SetLoadStake} st={loadStake} loginFunction={SetloginButton} bal={setBalInfo}/>}
        <div className="main-body">
        {pathname !="/tnc" &&  <Sidebar data={sidebarDetails} loginButton={loginButton} />}
          <Outlet context={{ callApi, SetcallApi, sidebarDetails, SetsidebarDetails, loadStake, loginButton, SetloginButton,balInfo,setBalInfo}} />
        </div>
      </NoInternetConnection >
    </>
  )
}

export default Layout