import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import ApiService from "../apis/ApiService";

const ConfirmPassword = () => {
  const navigate = useNavigate();

  const [userPasswordData, setUserPasswordData] = useState({
    oldpassword: "",
    password: "",
    confirmpassword: "",
  });
  const [Loader, setLoader] = useState(false)

  const [errors, setErrors] = useState({
    oldPasswordError: "",
    newpasswordError: "",
    confirmPasswordError: "",
    matcherror: "",
    resError: "",
    sucessfullmsg: ""
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserPasswordData({
      ...userPasswordData,
      [name]: value,
    });
  };

  const validate = () => {
    if (userPasswordData.oldpassword === "") {
      setErrors({ oldPasswordError: "Old Password is required!" });
    } else if (userPasswordData.password === "") {
      setErrors({ newpasswordError: "New Password is required!" });
    }
    else if (userPasswordData.confirmpassword === "") {
      setErrors({ confirmPasswordError: "Confirm Password is required!" });
    }
    else if (userPasswordData.oldpassword == userPasswordData.password) {
      setErrors({ newpasswordError: "Old Password and New Password cannot be same!" });
    }
    else if (userPasswordData.password !== userPasswordData.confirmpassword) {
      setErrors({ matcherror: "Password does not match!" });
    }
    else {
      setErrors([])
      return true;
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (validate()) {
        setLoader(true)
        var obj = {
          Password: userPasswordData.password
        }
        await ApiService.UpdateUPasswordBLogin(obj).then(async (res) => {
          setLoader(false)
          if (res && res.data.responseCode) {
            var msg = res.data.response;
            if (msg == "Success") {
              setUserPasswordData(res.data.response);
              localStorage.removeItem("up_cp_token")
              const liEelment = document.getElementsByClassName('sPopup');
              if (liEelment) {
                liEelment[0].click();
              }
              const timer = setTimeout(() => {
                navigate("/")
              }, 1500);
              return () => clearTimeout(timer);
              
            } else {
              console.log(msg)
              setErrors({ resError: res.data.response });
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/*loader*/}

      {Loader && <div className="boxes-bg">
        <div className="boxes">
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="box">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>}


{/* Password Modal */}
      <div className="lgn-modal mylogin signup-popup fade show" tabIndex={-1} >
        <div className="modal-dialog modal-dialog-centered nsigin">
          <div className="modal-content">
            <div className="modal-header">
              <div className="head">
                <h5>
                  Change Your Password?
                </h5>
              </div>
            </div>
            <div className="modal-body">
              <div className="register-from">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="items">
                    <div className="form-input">
                      <label htmlFor="email3" className="form-label">Old Password</label>
                      <input type="password"
                        name="oldpassword"
                        value={userPasswordData.oldpassword}
                        onChange={handleInput}
                        placeholder="OldPassword" />
                      <div className="redone">
                        <span>{errors.oldPasswordError}</span>
                      </div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="form-input">
                      <label htmlFor="password-field" className="form-label">New Password</label>
                      <div className="form-group">
                        <input
                          value={userPasswordData.password}
                          onChange={handleInput}
                          id="password-field"
                          type="password" className="form-control" placeholder="Your Password" name="password" />
                      </div>
                      <div className="redone">
                        <span>{errors.resError}</span>
                        <span>{errors.newpasswordError}</span>
                      </div>
                    </div>
                  </div>

                  <div className="items">
                    <div className="form-input">
                      <label htmlFor="password-field" className="form-label">Confirm Password</label>
                      <div className="form-group">
                        <input
                          value={userPasswordData.confirmpassword}
                          onChange={handleInput}
                          id="password-field"
                          type="password" className="form-control" placeholder="Your Password" name="confirmpassword" />
                      </div>
                      <div className="redone">
                        <span>{errors.resError}</span>
                        <span>{errors.confirmPasswordError}</span>
                        <span>{errors.matcherror}</span>
                      </div>
                      <span style={{ color: "white" }}>{errors.sucessfullmsg}</span>
                    </div>
                  </div>

                  <div className="items">
                    <div className="remember d-flex align-items-center justify-content-between">
                      <div className="form-check">
                      </div>
                    </div>
                  </div>
                  <div className="items text-center">
                    <button type="submit" className="cmn--btn cd-popup-close repopup sPopup" data-bs-dismiss="modal" aria-label="Close">
                      <span>Change Password</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default ConfirmPassword