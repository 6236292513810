import React, { useEffect, useState, } from 'react'
import Footer from '../components/Footer'
import ApiService from "../apis/ApiService";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import ProtoTypes from '../utilties/ProtoTypes';

const LoginHistory = () => {
    var username = ""
    var usrn = document.getElementById("usernm")
    if (usrn)
        username = usrn.innerHTML;
    const [userhistory, setuserhistory] = useState([]);
    const [Loader, setLoader] = useState(false);
    const [showtableError, settableError] = useState(false);
    const [paginationData, setPaginationData] = useState({
        PageNum: 1,
        RowsCount: "100",
        PageCount: '',
        FilterId: 1
    });

    const setpage1 = () => {
        const liEelment = document.querySelector('[aria-label="Page 1"]');
        if (liEelment) {
            liEelment.click();
        }
        else {
            const liEelment2 = document.querySelector('[aria-label="Go to page 1"]');
            if (liEelment2) {
                liEelment2.click();
            }
        }
    }

    useEffect(() => {
        async function callAyncFunction() {
            await onSearch();
        }
        callAyncFunction();
    }, [paginationData.PageNum, paginationData.FilterId, paginationData.RowsCount])


    var lastweekDate = new Date();
    lastweekDate.setDate(lastweekDate.getDate() - 15);
    var upcomingweek = new Date()
    upcomingweek.setDate(upcomingweek.getDate() + 15);

    const [filterData, setFilterData] = useState({
        FromDate: lastweekDate.toJSON().split('T')[0],
        ToDate: upcomingweek.toJSON().split('T')[0],
    })

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFilterData({ ...filterData, [name]: value, });
    }

    const handleMatched = (e) => {
        if (e.target.value) {
            var matchdata = e.target.value;
            var convertdata = JSON.parse(matchdata)
            if (convertdata == 1) {
                setPaginationData({ ...paginationData, FilterId: convertdata, PageNum: 1 });
                setTimeout(() => {
                    setpage1()
                }, 500);

            } else {
                setPaginationData({ ...paginationData, FilterId: convertdata, PageNum: 1 });
            }
            if (convertdata == 2) {
                setPaginationData({ ...paginationData, FilterId: convertdata, PageNum: 1 });
                setTimeout(() => {
                    setpage1()
                }, 500);

            } else {
                setPaginationData({ ...paginationData, FilterId: convertdata, PageNum: 1 });
            }
        }

    }

    const onSearch = async (e) => {
        setLoader(true)
        try {
            var accountdata = {
                Page: paginationData.PageNum,
                Limit: paginationData.RowsCount,
                FromDate: filterData.FromDate,
                ToDate: filterData.ToDate,
                FilterId: paginationData.FilterId
            }
            await ApiService.UserHistory(accountdata).then(res => {
                if (res) {
                    setLoader(false)
                    const record = res.data.result.histories
                    setuserhistory(record)
                    var recorddata = (res.data.result.records / parseInt(paginationData.RowsCount))
                    setPaginationData({ ...paginationData, PageCount: Math.ceil(recorddata) })
                } if (res.data.result.histories.length == 0) {
                    settableError(true)
                } else {
                    settableError(false)
                }
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handlePageChange = (e) => {
        setPaginationData({ ...paginationData, PageNum: e.selected + 1 })
    };

    const options = [
        { value: '100', label: '100' },
        { value: '200', label: '200' },
        { value: '300', label: '300' },
        { value: '500', label: '500' },
    ]

    const RowSizeHandler = (e) => {
        const liEelment = document.querySelector('[aria-label="Page 1"]');
        if (liEelment) {
            liEelment.click();
        }
        setPaginationData({ ...paginationData, RowsCount: e.value, PageNum: 1 })
    }

    const passReportData = () => {
        window.createLoginHistoryReport(userhistory, username, filterData)
    }

    return (
        <>
        {/* Loader */}
            {Loader && <div className="boxes-bg">
                <div className="boxes">
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>}

            <div className="body-middle">
                {/*Breadcumnd*/}
                <div className="breadcumnd-banner">
                    <div className="container">
                        <div className="breadcumd-content">
                            <h1>
                                Login History
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="profile-section pb-60">
                    <div className="container">
                        <div className="tab-content ob-content">
                            <div className="row">
                                {/* Date Range Filter */}
                                <div className="col-sm-12 filterrecord form-grp d-sm-flex">
                                    <div className="col-sm-5 DT">
                                        <div className="col-sm-6 col-xs-6 gift-card fm pe-2">
                                            <input
                                                className='filtrdate'
                                                type="date"
                                                name="FromDate"
                                                value={filterData.FromDate}
                                                onChange={handleInput} />
                                        </div>

                                        <div className='col-sm-6 col-xs-6  gift-card to pe-2 '>
                                            <input
                                                className='filtrdate'
                                                type="date"
                                                name="ToDate"
                                                value={filterData.ToDate}
                                                onChange={handleInput} />
                                        </div>
                                    </div>
{/* Radio Button */}
                                    <div className='logfield col-sm-5 col-md-3 wallet-tab-wrap pagg'>
                                        <div className='gift-card passection'>
                                            <label><input type="radio" style={{ cursor: "pointer" }} name="fav_language" value="1" defaultChecked onChange={e => handleMatched(e)} />Login History</label>
                                            <label><input type="radio" style={{ cursor: "pointer" }} name="fav_language" value="2" onChange={e => handleMatched(e)} />Password History</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <a className="cmn--btn" onClick={e => onSearch()} style={{ cursor: "pointer" }}>
                                            <span >Search</span>
                                        </a>
                                    </div>
                                </div>

                                 {/* Pagination */}
                                <div className="row flex mt-3 pe-0">
                                    <div className='col-sm-2 col-md-2 col-lg-1   pe-0 accStat'>
                                        <Select defaultValue={options[1]} onChange={e => RowSizeHandler(e)} options={options}></Select>
                                    </div>
                                    <div className="col-sm-6 col-md-7 col-lg-8 text-center xsmy-2">
                                        <ReactPaginate
                                            previousLabel={"Prev"}
                                            nextLabel={"Next"}
                                            breakLabel={".."}
                                            breakClassName={"break-me"}
                                            pageCount={paginationData.PageCount}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={(e) => handlePageChange(e)}
                                            containerClassName={"pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>

                                    <div className='col-sm-3 col-md-3 text-md-end  pe-0'>
                                        <a onClick={passReportData} className="cmn--btn" style={{ cursor: "pointer" }}>
                                            <span style={{whiteSpace:"nowrap"}}>Download Report</span>
                                        </a>
                                    </div>
                                </div>

                                {/* Main Table */}
                                <div className="tab-content mt-height">
                                    <div className="description-table ob-table padTable8">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th style={{ textAlign: 'left' }}>Date</th>
                                                    <th style={{ textAlign: "left" }}>UserName</th>
                                                    <th style={{ textAlign: "left" }}>City</th>
                                                    <th style={{ textAlign: "left" }}>Country</th>
                                                    <th style={{ textAlign: "right" }}>IpAddress</th>
                                                </tr>
                                                {userhistory && userhistory.map((history, indexx) => {
                                                    return (
                                                        <tr key={indexx} className="tb1">
                                                            <td style={{ textAlign: "left" }}>{ProtoTypes.setLocalDate(history.updatedOn)}</td>
                                                            <td style={{ textAlign: "left" }}>{username}</td>
                                                            <td style={{ textAlign: "left" }}>{history.city}</td>
                                                            <td style={{ textAlign: "left" }}>{history.country}</td>
                                                            <td title={history.browser} style={{ textAlign: "right" }}>{history.ipAddress}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {showtableError && (
                                            <div className="errorshow">
                                                <span>No Data Found!</span>
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default LoginHistory
