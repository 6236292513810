import React, { useEffect, useState, useRef } from 'react'
import Footer from '../components/Footer'
import ApiService from "../apis/ApiService";
import { toast } from "react-toastify";
import error from '../utilties/error';

Number.prototype.round = function (decimals) {
    return Number((Math.round(this + "e" + decimals) + "e-" + decimals));
}

const Auth2fa = () => {
    const AUTHID = useRef(0)
    const crStatus = useRef(false)
    const [showimage, setshowimage] = useState(false)
    const [delId, setDelId] = useState()
    const [showSubmitLoader, setShowSubmitLoader] = useState(false)
    const [spinnerLoader, setSpinnerLoader] = useState(true)
    const [showdevicelist, setshowdevicelist] = useState(false)
    const [resMsg, setresMsg] = useState("")
    const [showResError, setShowResError] = useState(false)
    const UKEY = useRef()
    const [loading, setLoading] = useState(false)
    const [barCode, setBarCode] = useState();
    const [manualCode, setmanualCode] = useState();;
    const [googleAuthData, setGoogleAuthData] = useState()
    const [deviceList, setDeviceList] = useState([]);
    const [Loader, setLoader] = useState(false);
    const [FAstatuslist, set2FAstatuslist] = useState()
    const [enable, setenable] = useState()
    const [device, setDevice] = useState({
        UserUniqueKey: "",
        DeviceName: "",
        Token: "",
        IsGoogle2FA: false
    });

    const [errors, setErrors] = useState({
        newdeviceError: "",
        passcodeError: "",
        resError: "",
    });

    const validate = () => {

        if (device.DeviceName === "") {
            setErrors({ newdeviceError: "Please Enter Device Name" });
        } else if (device.Token === "") {
            setErrors({ passcodeError: "Please Enter Passcode" });
        } else {
            setErrors({
                newdeviceError: "",
                passcodeError: "",
            });

            return true;
        }
    };

    useEffect(() => {
        GetDevices()
        GetG2FAStatus()
    }, [])

    const handleInputDevice = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDevice({ ...device, [name]: value })
    }

    const GetDevices = async () => {
        try {
            await ApiService.GetDevices("0").then(res => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        setDeviceList(res.data.result)
                    }
                    else {
                        error.error(msg)
                    }
                    if (res.data.result.length == 0) {
                        setshowdevicelist(true)
                    } else {
                        setshowdevicelist(false)
                    }
                }
            })
        }
        catch (err) {
            console.log(err);
            toast.error("Error in getting Devices")
        }
    }

    const GetG2FAStatus = async () => {
        try {
            await ApiService.GetG2FAStatus("0").then(res => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        set2FAstatuslist(res.data.result)
                        setenable(res.data.result)
                    }
                    else {
                        toast.error(msg)
                    }

                }
            })
        }
        catch (err) {
            console.log(err);
            toast.error("Error in getting Devices")
        }
    }

    const GetAuthCode = async () => {
        try {
            await ApiService.GetAuthCode().then(res => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        setBarCode(res.data.result.barcodeImageUrl)
                        setmanualCode(res.data.result.setupCode)
                        UKEY.current = res.data.result.userUniqueKey   
                    }
                    else {
                        toast.error(msg)
                    }
                    if (res.data.result.barcodeImageUrl) {
                        setTimeout(() => {
                            setSpinnerLoader(false)
                        }, 1000);
                    }
                    if (res.data.result.barcodeImageUrl) {
                        setshowimage(true)
                    } else {
                        setshowimage(false)
                    }
                }
            }).catch(err => {
                console.log(err);
                toast.error("Error in getting AuthCode")
            })
        } catch (err) {
            console.log(err);
            toast.error("Error in getting AuthCode")
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (validate()) {
                var rqstData = {
                    UserUniqueKey: UKEY.current,
                    DeviceName: device.DeviceName,
                    Token: device.Token

                }
                setShowSubmitLoader(true)
                await ApiService.AddGoogleAuth(rqstData).then(res => {
                 
                    if (res && res.data.responseCode) {
                        var msg = res.data.response;
                        if (msg == "Success") {
                            setGoogleAuthData(res.data.result)
                            const liEelment = document.getElementsByClassName('ga');
                            if (liEelment) {
                                liEelment[0].click();
                            }
                            setShowSubmitLoader(false)
                            if (!crStatus.current) {
                                crStatus.current = true
                                setenable(true)
                                set2FAstatuslist(true)
                                UpdateAuthStatus()
                            }
                            GetDevices()
                            error.successToastify("Two Factor Authentication Device Added! ");
                            setShowResError(false)
                            setShowSubmitLoader(false)
                        } else {
                            setresMsg(msg)
                            setShowSubmitLoader(false)
                            setShowResError(true)
                        }

                    }
                }).catch(error => {
                    console.log(error)
                    error.error("Something Wrong in Add Device")
                   
                })
            }
        } catch (error) {
            console.log(error)
            toast.error("Something Wrong in Add Device")
        }

    }

    const handleToggle = (event) => {
        var crval = event.target.checked
        crStatus.current = crval
        setenable(crStatus.current)
        set2FAstatuslist(crval)
        UpdateAuthStatus()
    }

    const UpdateAuthStatus = async () => {
        try {
            var crdata = crStatus.current

            var rqstData = {
                UserId: "0",
                IsGoogle2FA: crdata
            }
          
            await ApiService.UpdateAuthStatus(rqstData).then(res => {
                

                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        error.successToastify("Two Factor Device Authentication Updated!");

                    } else {
                        toast.error(msg)
                        setenable(false)
                        set2FAstatuslist(false)
                    }
                }
            }).catch(error => {
                console.log(error)
                error.error("Something Wrong in Add Auth Status")
               
            })
        } catch (error) {
            console.log(error)
            error.error("Something Wrong in Add Auth Status")
        }
    }

    const deleteDeviceData = async () => {
        try {
            var id = AUTHID.current;
            setLoading(true)
            await ApiService.DeleteGAuthDevice(id).then(res => {
                if (res && res.data.responseCode) {
                    var msg = res.data.response;
                    if (msg == "Success") {
                        setLoading(false)
                        error.successToastify('Device Deleted Successfully!')
                        GetDevices();
                    } else 
                        toast.error(msg)

                    const liEelment = document.getElementsByClassName('da');
                    if (liEelment)
                        liEelment[0].click();

                }
            }).catch(error => {
                console.log(error)
                toast.error("Something Wrong!")
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
        }

    }

    const passdeleteId = (id) => {
        AUTHID.current = id;
        setDelId(id)
    }

    const resetModal = () => {
        setSpinnerLoader(true)
        setshowimage(false)
        setShowSubmitLoader(false)
        setShowResError(false)
        setBarCode()
        setmanualCode()
        GetAuthCode()
        setErrors([])
        setresMsg("")
        setDevice({ ...device, DeviceName: "", Token: "" })
        GetAuthCode()
    }

    return (
        <>
        {/* Loader */}
            {Loader && <div className="boxes-bg">
                <div className="boxes">
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className="box">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>}

            <div className="body-middle">
                {/*Breadcumnd*/}
                <div className="breadcumnd-banner">
                    <div className="container">
                        <div className="breadcumd-content">
                            <h1>
                                2FA Auth
                            </h1>

                        </div>

                    </div>
                </div>
{/* Toggle Button */}
                <div className="profile-section pb-60">
                    <div className="container">
                        <div className="tab-content ob-content col-sm-6 col-xs-12">
                            <div className="row ">
                                <div className="row flex mt-3 pe-0">
                                    <div className="col-xs-6 col-sm-6 pe-0 ">

                                        <div style={{ display: "inline-flex" }}>

                                            <label className="switch float-end mt-0">

                                                <input type="checkbox" checked={FAstatuslist} onChange={(e) => handleToggle(e)} />

                                                <span data-on="On" data-off="Off" className="slider"></span>
                                            </label>
                                            <span className='mt-2'>{enable ? "Enabled" : "Disabled"}</span>
                                        </div>

                                    </div>

                                    <div className="col-xs-6 col-sm-6 pe-0">
                                        <a id="form_id" href="#0" className="cmn--btn float-end" data-target="#exampleModal6" data-bs-toggle="modal" data-bs-target="#exampleModal6" onClick={resetModal}>
                                            <span>Add Device</span>
                                        </a>
                                    </div>
                                </div>
                                {/* Main Table */}

                                <div className="tab-content mt-height">
                                    <div className="description-table ob-table">
                                        <table style={{ width: "100%" }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{ textAlign: "center", width: "10%" }}>SrNo.</th>
                                                    <th style={{ textAlign: "left", width: "80%" }}>Device Name</th>
                                                    <th style={{ textAlign: "center", width: "10%" }}>Action</th>
                                                </tr>
                                                {deviceList.map((history, index) => {
                                                    return (
                                                        <tr key={index} className="tb1" style={{ cursor: "pointer" }}>
                                                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                            <td style={{ textAlign: "left" }}>{(history.deviceName)}</td>
                                                            <td style={{ textAlign: "center" }} id="form_id" href="#0" onClick={e => passdeleteId(history.authId)} data-target="#exampleModal7" data-bs-toggle="modal" data-bs-target="#exampleModal7"><i className="bi bi-trash"></i></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {showdevicelist && (
                                            <div className="errorshow">
                                                <span>No Data Found!</span>
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Google Modal */}

                <div className="modal mylogin fade" id="exampleModal6" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered lg nsigin">
                        <div className="modal-content" style={{ height: "578px" }}>
                            <div className="modal-header" style={{ marginBottom: "0px" }}>
                                <div className="head">

                                    <h5>
                                        Google Authenticator
                                    </h5>
                                </div>
                                <button type="button" className="close ga" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                            </div>

                            <div className="modal-body">
                                <div className="register-from">
                                    <form onSubmit={(e) => handleSubmit(e)} id="yourFormId">
                                        <div className="items" style={{ textAlign: 'center' }}>
                                            {spinnerLoader && <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>}
                                            {showimage && <img src={barCode} className="barcodesize"></img>}
                                            <br></br>
                                            <div style={{ textAlign: 'left', marginTop: "0.4rem" }}>
                                                <label className="form-label" >Manaul Setup Code: </label>{" "}
                                                {manualCode}
                                            </div>

                                            <div className="form-input" style={{ textAlign: 'left' }}>
                                                <label className="form-label" style={{ display: "none" }}>Device Name</label>
                                                <input type="text"
                                                    name="DeviceName"
                                                    value={device.DeviceName}
                                                    onChange={handleInputDevice}
                                                    placeholder="Device Name" />
                                                <div className="redone">
                                                    <span>{errors.newdeviceError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="items">
                                            <div className="form-input">
                                                <label className="form-label" style={{ display: "none" }}>Passcode</label>
                                                <div className="form-group">
                                                    <input type='text'
                                                        name="Token"
                                                        value={device.Token}
                                                        onChange={handleInputDevice}
                                                        placeholder="Passcode" />
                                                </div>
                                                <div className="redone">
                                                    <span>{errors.passcodeError}</span>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="items text-center">
                                            <button type="submit" className="cmn--btn cd-popup-close repopup" >
                                                <span>Submit</span>
                                            </button>
                                            <div className='subloader'>
                                                {showSubmitLoader &&
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </div>
                                            <br></br><br></br>

                                            {showResError &&
                                                <span className='errmsg-ga'>{resMsg}</span>
                                            }

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Delete Google modal */}

                <div className="modal mylogin fade" id="exampleModal7" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" style={{ height: "181px" }}>
                            <div className="modal-header">
                                <div className="head">
                                   
                                    <h5>
                                       Do you want to delete this device?
                                    </h5>
                                </div>
                                <button type="button" className="close da" data-bs-dismiss="modal" aria-label="Close"><i className="fas fa-times"></i></button>
                            </div>

                            <div className="modal-body">
                                <div style={{ textAlign: "center" }}>
                                    <button className="cmn--btn" onClick={e => deleteDeviceData(delId)}> <span>Delete</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Auth2fa
