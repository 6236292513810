import React from 'react'

const Footer = () => {

const handletnc=()=>{
    window.open(`${process.env.PUBLIC_URL}#/tnc`,"_blank")
}

  return (
    <>
                {/*Footer Section*/}
                <footer className="footer-section">
                    <div className="container">
                        <div className="footer-wrapper">
                            {/* <p>
                                Copyright {new Date().getFullYear()} <a href="javascript:void(0);" className="text-base">Pilot Exchange</a> All Rights Reserved.
                            </p> */}
                            <p>
                            © Copyright {new Date().getFullYear()}. All Rights Reserved. 
                            </p>
                            <ul className="footer-link">
                                <li>
                                    <a href="javascript:void(0);">
                                        Affiliate program
                                    </a>
                                </li>
                                <li>
                                    <a style={{cursor:"pointer"}} onClick={e => handletnc()}>
                                        Terms &amp; conditions
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        Bonus terms &amp; conditions
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </footer>
    </>
  )
}

export default Footer