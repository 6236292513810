const setLocalDate = (dt) => {
    let localDate = "";
    if (dt != undefined && dt != null && dt != "") {
        let strDate = new Date(dt + "Z");
        let year = strDate.toLocaleString("default", { year: "numeric" });
        let month = strDate.toLocaleString("default", { month: "2-digit" });
        let day = strDate.toLocaleString("default", { day: "2-digit" });
        let time = strDate.toLocaleTimeString("en-US", { hour12: true });
        localDate = [[day, month, year].join("/"), time].join(" ");
    }
    return localDate;
}

const setLocalTime = (dt) => {

    let localDate = "";
    if (dt != undefined && dt != null && dt != "") {

        let milise = ""
        let ms = dt.split(".")
        if (ms && ms.length > 0) {
            milise = ms[1]
        }
        let strDate = new Date(dt + "Z");
        let time = (strDate.toTimeString());
        let newT = time.split(" ");
        let updTm = ""
        if (newT && newT.length > 0) {
            if (milise)
                updTm = newT[0] + '.' + milise
            else
                updTm = ""
        }

        localDate = updTm
    }

    return localDate;
}

const setLocalTime2 = (dt) => {

    let localDate = "";
    if (dt != undefined && dt != null && dt != "") {

        let milise = ""
        let ms = dt.split(".")
        if (ms && ms.length > 0) {
            milise = ms[1]
        }
        let strDate = new Date(dt + "Z");
        let time = (strDate.toTimeString());
        let newT = time.split(" ");
        let updTm = ""
        if (newT && newT.length > 0) {
            if (milise)
                updTm = newT[0]
            else
                updTm = ""
        }

        localDate = updTm
    }

    return localDate;
}

const Round = (num, decimals = 2) => {
    return +(Math.round(num + "e+" + decimals) + "e-" + decimals);
}

const Commas = (num, roundOff = true) => {

    if (roundOff)
        num = Round(num);

    let arrNum = num.toString().split(".");
    let result = parseInt(arrNum[0]).toLocaleString("en-IN", { minimumFractionDigits: 0, maximumFractionDigits: 2 });

    if (arrNum.length > 1)
        return result.concat(".", arrNum[1]);

    return result;
}

const SetLocalTime = (dt) => {

    let localDate = "";
    if (dt != undefined && dt != null && dt != "") {
        let strDate = new Date(dt + "Z");
        let time = strDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });
        localDate = time
    }
    return localDate;
}

const IstToUtc = (dt) => {
    let IstDateObj = new Date(dt + "T00:00:00");
    IstDateObj.setHours(IstDateObj.getHours() - 5);
    IstDateObj.setMinutes(IstDateObj.getMinutes() - 30);

    return formatDate(IstDateObj)
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        'T' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}

export default { setLocalDate, setLocalTime, setLocalTime2, Commas, Round, SetLocalTime, IstToUtc };
