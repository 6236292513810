import React from 'react'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Live = () => {
    const navigate = useNavigate();

    return (
        <>

            <div className="body-middle">
                {/*filter banner*/}
                <div className="filter-banner">
                    <div className="container">
                        <div className="filter-banner-wrap">
                            <div className="accordion" id="bannerfilter-accordion">
                                <div className="accordion-item">
                                    <div className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#bannerfilter" aria-expanded="false" aria-controls="bannerfilter">
                                            <span className="icon"><i className="fas fa-bars" /></span>
                                            <span>Selected disciplines: 3/14</span>
                                        </button>
                                        <div id="bannerfilter" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <ul className="banner-filter-components">
                                                    <li>
                                                        <span><i className="icon-basketball" /></span>
                                                        <a href="#0">
                                                            Basketball
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-basketball" /></span>
                                                        <a href="#0">
                                                            eBasketball
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-scgo" /></span>
                                                        <a href="#0">
                                                            CS:GO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-basketball" /></span>
                                                        <a href="#0">
                                                            Basketball
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-basketball" /></span>
                                                        <a href="#0">
                                                            eBasketball
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-tennis" /></span>
                                                        <a href="#0">
                                                            Tennis
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-etennis" /></span>
                                                        <a href="#0">
                                                            eTennis
                                                        </a>
                                                    </li><li>
                                                        <span><i className="icon-hockyball" /></span>
                                                        <a href="#0">
                                                            Ice hockey
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-hockyman" /></span>
                                                        <a href="#0">
                                                            eHockey
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-volleyball" /></span>
                                                        <a href="#0">
                                                            Volleyball
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-baseball" /></span>
                                                        <a href="#0">
                                                            Baseball
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-futsal" /></span>
                                                        <a href="#0">
                                                            Futsal
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <span><i className="icon-cricket" /></span>
                                                        <a href="#0">
                                                            Cricket
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*filter banner*/}
                {/*Main-body-tabing*/}
                <div className="main-body-tabbing">
                    <div className="container">
                        <div className="main-tabbing">
                            <div className="tab-content" id="tabContentmain">
                                {/*all tab start*/}
                                <div className="tab-pane fade show active" id="betsall" role="tabpanel">
                                    <div className="match-table">
                                        {/*table five*/}
                                        <div className="match-table-head">
                                            <h2>
                                                eBasketball
                                            </h2>
                                            <Link to="/details" href="details.html" className="tablebtn">
                                                More
                                            </Link>
                                        </div>
                                        <div className="table-wrap mb-2">
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/ncl.png" alt="flag" />
                                                        </span>
                                                        <span>
                                                            Australian Championship. NBL
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Quarter 3 '18
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total. Quarter 3
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/phonix.png" alt="flag" />
                                                                    </span>
                                                                    <span>Melbourne Phoenix</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/tasmania.png" alt="plag" />
                                                                    </span>
                                                                    <span>Tasmania Jackjumpers</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">43</span> Q1 Q2 Q3</a>
                                                            <a href="#0">22 16 5</a>
                                                            <a href="#0"><span className="blods">53</span> 17 30 6</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>12.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>37.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1.5</span>
                                                            <span>2.20</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 41.5</span>
                                                            <span>1.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 41.5</span>
                                                            <span>1.77</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="batbinton">
                                                                <img src="assets/img/table/batbintonplay.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/e.png" alt="flag" />
                                                        </span>
                                                        <span>
                                                            Championship of Japan. League B. Divisio...
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Quarter 3 '18
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total. Quarter 3
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/tokyo.png" alt="flag" />
                                                                    </span>
                                                                    <span>Earthfriends Tokyo Z</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/pass.png" alt="plag" />
                                                                    </span>
                                                                    <span>Pass Lab Yamagata Wyverns</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">43</span> Q1 Q2 Q3</a>
                                                            <a href="#0">22 16 5</a>
                                                            <a href="#0"><span className="blods">53</span> 17 30 6</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>12.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>37.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1.5</span>
                                                            <span>2.20</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 41.5</span>
                                                            <span>1.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 41.5</span>
                                                            <span>1.77</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="batbinton">
                                                                <img src="assets/img/table/batbintonplay.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/basketball.svg" alt="flag" />
                                                        </span>
                                                        <span>
                                                            Championship of the Philippines. UAAP
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Quarter 3 '18
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total. Quarter 3
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/basketball.svg" alt="flag" />
                                                                    </span>
                                                                    <span>Earthfriends Tokyo Z</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/basketball.svg" alt="plag" />
                                                                    </span>
                                                                    <span>Pass Lab Yamagata Wyverns</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">43</span> Q1 Q2 Q3</a>
                                                            <a href="#0">22 16 5</a>
                                                            <a href="#0"><span className="blods">53</span> 17 30 6</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>12.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>37.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1.5</span>
                                                            <span>2.20</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 41.5</span>
                                                            <span>1.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 41.5</span>
                                                            <span>1.77</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="batbinton">
                                                                <img src="assets/img/table/batbintonplay.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/wman.png" alt="flag" />
                                                        </span>
                                                        <span>
                                                            Championship of Turkey. tkbl. Women
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Quarter 3 '18
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total. Quarter 3
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/botas.png" alt="flag" />
                                                                    </span>
                                                                    <span>Botas Gelisim Ankara (w)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/karsi.png" alt="plag" />
                                                                    </span>
                                                                    <span>Karsiyaka Carshi (w)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">43</span> Q1 Q2 Q3</a>
                                                            <a href="#0">22 16 5</a>
                                                            <a href="#0"><span className="blods">53</span> 17 30 6</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>12.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>37.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1.5</span>
                                                            <span>2.20</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 41.5</span>
                                                            <span>1.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 41.5</span>
                                                            <span>1.77</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="batbinton">
                                                                <img src="assets/img/table/batbintonplay.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/ncl.png" alt="flag" />
                                                        </span>
                                                        <span>
                                                            Australian Championship. NBL
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Quarter 3 '18
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total. Quarter 3
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/h11.png" alt="flag" />
                                                                    </span>
                                                                    <span>Illawarra Hawks</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/h22.png" alt="plag" />
                                                                    </span>
                                                                    <span>Sydney Kings</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">22</span> Q1 Q2 Q3</a>
                                                            <a href="#0">22 16 5</a>
                                                            <a href="#0"><span className="blods">85</span> 17 30 6</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>12.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>37.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1.5</span>
                                                            <span>2.20</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 41.5</span>
                                                            <span>1.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 41.5</span>
                                                            <span>1.77</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="batbinton">
                                                                <img src="assets/img/table/batbintonplay.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/wman.png" alt="flag" />
                                                        </span>
                                                        <span>
                                                            Championship of Turkey. BSL
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Quarter 3 '18
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total. Quarter 3
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/diniz.png" alt="flag" />
                                                                    </span>
                                                                    <span>Merkezefendi Belediyesi</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <img src="assets/img/table/petkim.png" alt="plag" />
                                                                    </span>
                                                                    <span>Socar Petkim Spor</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">22</span> Q1 Q2 Q3</a>
                                                            <a href="#0">22 16 5</a>
                                                            <a href="#0"><span className="blods">85</span> 17 30 6</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>12.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>37.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1.5</span>
                                                            <span>2.20</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 41.5</span>
                                                            <span>1.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 41.5</span>
                                                            <span>1.77</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="batbinton">
                                                                <img src="assets/img/table/batbintonplay.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*Ehocky*/}
                                        {/*table five*/}
                                        <div className="match-table-head mt-5 pb-20">
                                            <h2>
                                                eHockey
                                            </h2>
                                        </div>
                                        <div className="table-wrap mb-2">
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey. EHC CUP (3x4 MиH.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Pittsburgh Penguins (Chuga)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Nashville Predators (Alpha)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">2</span>  P1 P2 P3</a>
                                                            <a href="#0">200</a>
                                                            <a href="#0"><span className="blods">4</span> 112</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play01.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey. EHC CUP (3x4 MиH.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Pittsburgh Penguins (Chuga)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Nashville Predators (Alpha)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">2</span>  P1 P2 P3</a>
                                                            <a href="#0">201</a>
                                                            <a href="#0"><span className="blods">4</span> 112</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play02.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyber Hockey. F5 Hockey World Champio.
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Germany (cyber)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Slovakia (cyber)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">2</span>  P1 P2 P3</a>
                                                            <a href="#0">201</a>
                                                            <a href="#0"><span className="blods">4</span> 112</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play03.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyber Hockey. F5 Hockey World Champio.
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>USA (Skylo)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Canada (delta)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span>  P1 P2 P3</a>
                                                            <a href="#0">821</a>
                                                            <a href="#0"><span className="blods">9</span> 1102</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play04.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey. EHC CUP (3x4 MHH.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Anaheim Ducks (Livni)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Vancouver Canucks (Di-Akon)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play06.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey. eSports Battle, Mixed Conference
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Carolina Hurricanes (VENIKSVENIKA)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Tampa Bay Lightning (Skylo)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span>  P1 P2 P3</a>
                                                            <a href="#0">821</a>
                                                            <a href="#0"><span className="blods">2</span> 1102</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play07.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey. EHC CUP (3x4 MHH.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Anaheim Ducks (Livni)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Washington Capitals (Polov)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play08.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyber Hockey. (3x4 min.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Canada (cyber)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Finland (cyber)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play09.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey, EHC CUP (3x4 M.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Seattle Kraken (Alexiss)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Vancouver Canucks (Di-Akon)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play10.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey, EHC CUP (3x4 MHH.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Washington Capitals (Polov)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Vancouver Canucks (Di-Akon)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play11.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey, EHC CUP (3x4 MHH.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Vancouver Canucks (Di-Akon)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Edmonton Oilers (Horokh)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play12.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyberhockey, EHC CUP (3x4 MHH.)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Vancouver Canucks (Di-Akon)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Edmonton Oilers (Horokh)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play13.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyber Hockey. F5 Hockey World Champio...
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Latvia (cyber)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Edmonton Oilers (Horokh)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play14.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Cyber Hockey. F5 Hockey World
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Canada (cyber)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Finland (cyber)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play15.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-hockyman" />
                                                        </span>
                                                        <span>
                                                            Edmonton Oilers (Horokh)
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Canada (cyber)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-hockyman" />
                                                                    </span>
                                                                    <span>Edmonton Oilers (Horokh)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>05.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.010</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/play16.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*table six*/}
                                        <div className="match-table-head mt-5 pb-20">
                                            <h2>
                                                Cricket
                                            </h2>
                                        </div>
                                        <div className="table-wrap">
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-cricket" />
                                                        </span>
                                                        <span>
                                                            Irani Cup
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Quarter 3 '18
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total. Quarter 3
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-cricket" />
                                                                    </span>
                                                                    <span>Melbourne Phoenix</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-cricket" />
                                                                    </span>
                                                                    <span>Rest Of India</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">90</span> 10</a>
                                                            <a href="#0">22 16 5</a>
                                                            <a href="#0"><span className="blods">98</span> 00</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>12.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1.5</span>
                                                            <span>2.20</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 41.5</span>
                                                            <span>1.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 41.5</span>
                                                            <span>1.77</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="batbinton">
                                                                <img src="assets/img/table/batbintonplay.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-cricket" />
                                                        </span>
                                                        <span>
                                                            Australian Championship. NBL
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Quarter 3 '18
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total. Quarter 3
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-cricket" />
                                                                    </span>
                                                                    <span>KCA Tigers</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="details.html">
                                                                    <span className="icon">
                                                                        <i className="icon-cricket" />
                                                                    </span>
                                                                    <span>KKA Eagles</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">63</span> 3</a>
                                                            <a href="#0">25</a>
                                                            <a href="#0"><span className="blods">0</span> 00</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>12.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>37.00</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 41.5</span>
                                                            <span>1.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 41.5</span>
                                                            <span>1.77</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="batbinton">
                                                                <img src="assets/img/table/batbintonplay.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*all tab End*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/*Main-body-tabing*/}
                <Footer />
                <a href="#0" className="click-btn" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                    <i className="icon-basketball" />
                </a>
            </div>

            {/*Right Box Chat*/}
            <div className="right-site-menu">
                <div className="right-box">
                    <div className="right-wrapper-area">
                        <div className="blance-items">
                            <div className="left-blance">
                                <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                                <span className="text-blabce">Balance</span>
                            </div>
                            <span className="blance">
                                $300
                            </span>
                        </div>
                        <div className="betslip-wrapper">
                            <a href="#0" className="left-betslip">
                                <span className="bed2"><img src="assets/img/header/right-icon/bed2.png" alt="icon" /></span>
                                <span className="bed1"><img src="assets/img/header/right-icon/bed.svg" alt="icon" /></span>
                                <span className="text-bets">Bet Slip</span>
                            </a>
                            <a href="#0" className="left-betslip">
                                <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                                <span className="text-bets">My Bets</span>
                            </a>
                        </div>
                        <div className="combo-box">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className="nav-link link-secondary" id="combo-tab" data-bs-toggle="tab" data-bs-target="#coombo" href="#"><span>Single bets</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-secondary active" id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>Combo</span></a>
                                </li>
                            </ul>
                            <div className="tab-content" id="tabContentboxes">
                                <div className="tab-pane fade" id="coombo" role="tabpanel">
                                    <div className="combo-wrapper">
                                        <div className="combo-wrapper">
                                            <div className="close-box">
                                                <div className="close-items">
                                                    <div className="close-head">
                                                        <span>Latvia vs Moldova</span>
                                                        <span className="close">
                                                            <i className="fas fa-xmark" />
                                                        </span>
                                                    </div>
                                                    <div className="match-fixing">
                                                        <div className="match-items">
                                                            <div className="match-items-left">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                </div>
                                                                <div className="cont">
                                                                    <span>Moldova</span>
                                                                    <span className="winner">Match Winner</span>
                                                                </div>
                                                            </div>
                                                            <div className="match-items-right">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                </div>
                                                                <span>5.20</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="combo-switch">
                                                <span>
                                                    Accept all odds changes
                                                </span>
                                                <label className="switch-com">
                                                    <input type="checkbox" className="checkbox" hidden />
                                                    <span className="checkbox-label">
                                                        <span className="ball" />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="ammount-items">
                                                <form action="#">
                                                    <input type="number" placeholder="Bet Amount" />
                                                    <button type="submit">
                                                        Max
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="possible-win">
                                                <span>Possible win</span>
                                                <span className="amount">$300</span>
                                            </div>
                                            <div className="combo-footer">
                                                <a href="#0" className="cmn--btn">
                                                    <span> Place Bet $300</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show active" id="bbets" role="tabpanel">
                                    <div className="combo-wrapper">
                                        <div className="close-box">
                                            <div className="close-items">
                                                <div className="close-head">
                                                    <span>Scotland vs Ukraine</span>
                                                    <span className="close">
                                                        <i className="fas fa-xmark" />
                                                    </span>
                                                </div>
                                                <div className="match-fixing">
                                                    <div className="match-items">
                                                        <div className="match-items-left">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                            </div>
                                                            <div className="cont">
                                                                <span>Scotland</span>
                                                                <span className="winner">Match Winner</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>3.20</span>
                                                        </div>
                                                    </div>
                                                    <div className="match-items">
                                                        <div className="match-items-left">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                            </div>
                                                            <div className="cont">
                                                                <span>Draw</span>
                                                                <span className="winner">Match Winner</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>4.60</span>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        Add 2 more outcome(s) with odds greater than 1.4 to get x1.08
                                                    </p>
                                                    <div className="match-items match-progress">
                                                        <div className="match-items-left">
                                                            <div className="prabox-wrap">
                                                                <div className="terminal-bar">
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                </div>
                                                                <div className="bar-count">
                                                                    <p>x1.08</p>
                                                                    <p>x1,15</p>
                                                                    <p>x1.20</p>
                                                                    <p>x1.50</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>1.00</span>
                                                        </div>
                                                    </div>
                                                    <div className="match-items match-odds">
                                                        <div className="match-items-left">
                                                            <div className="cont">
                                                                <span>Total odds</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>12.19</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="combo-switch">
                                            <span>
                                                Accept all odds changes
                                            </span>
                                            <label className="switch-com">
                                                <input type="checkbox" className="checkbox" hidden />
                                                <span className="checkbox-label">
                                                    <span className="ball" />
                                                </span>
                                            </label>
                                        </div>
                                        <div className="ammount-items">
                                            <form action="#">
                                                <input type="number" placeholder="Bet Amount" />
                                                <button type="submit">
                                                    Max
                                                </button>
                                            </form>
                                        </div>
                                        <div className="possible-win">
                                            <span>Possible win</span>
                                            <span className="amount">$300</span>
                                        </div>
                                        <div className="combo-footer">
                                            <a href="#0" className="cmn--btn">
                                                <span> Place Bet $300</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-box">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <span className="icon"><i className="fa-sharp fa-solid fa-gear" /></span>
                                            <span>Settings</span>
                                        </button>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="select-wrap">
                                                    <div className="select-one">
                                                        <select className="select" name="select" id="n-select">
                                                            <option value={1}>English</option>
                                                            <option value={1}>Spanish</option>
                                                        </select>
                                                        <span className="add">Lunguage</span>
                                                    </div>
                                                    <div className="select-two">
                                                        <select className="select" name="select" id="s-select">
                                                            <option value={1}>Decimal</option>
                                                            <option value={1}>Decimal Odds</option>
                                                        </select>
                                                        <span className="add">Odds</span>
                                                    </div>
                                                </div>
                                                <a href="#0" className="condition-box">
                                                    Terms &amp; Conditions
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popular-bets">
                        <div className="head">
                            <h6>
                                Popular bets
                            </h6>
                        </div>
                        <ul className="popoular-body">
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault1" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Prance
                                        </span>
                                        <span className="text3">
                                            France - Austria
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        2.37
                                    </span>
                                </div>
                            </li>
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault2" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Turkey
                                        </span>
                                        <span className="text3">
                                            Turkey - Luxembourg
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        1.37
                                    </span>
                                </div>
                            </li>
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault3" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Alkmaar
                                        </span>
                                        <span className="text3">
                                            Alkmaar - Apollon
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        3.47
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/*menu modal*/}
            <div className="modal right-menu-modal fade" id="exampleModal3" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <button type="button" className="btn-close cross-btn" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="right-site-menu">
                                <div className="right-box">
                                    <div className="right-wrapper-area">
                                        <div className="blance-items">
                                            <div className="left-blance">
                                                <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                                                <span className="text-blabce">Balance</span>
                                            </div>
                                            <span className="blance">
                                                $300
                                            </span>
                                        </div>
                                        <div className="betslip-wrapper">
                                            <a href="#0" className="left-betslip">
                                                <span><img src="assets/img/header/right-icon/bed.svg" alt="icon" /></span>
                                                <span className="text-bets">Bet Slip</span>
                                            </a>
                                            <a href="#0" className="left-betslip">
                                                <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                                                <span className="text-bets">My Bets</span>
                                            </a>
                                        </div>
                                        <div className="combo-box">
                                            <ul className="nav">
                                                <li className="nav-item">
                                                    <a className="nav-link link-secondary" data-bs-toggle="tab" data-bs-target="#coombomobile" href="#"><span>Single bets</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link link-secondary active" data-bs-toggle="tab" data-bs-target="#bbetsmobile" href="#"><span>Combo</span></a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="tabContentboxesmobile">
                                                <div className="tab-pane fade" id="coombomobile" role="tabpanel">
                                                    <div className="combo-wrapper">
                                                        <div className="combo-wrapper">
                                                            <div className="close-box">
                                                                <div className="close-items">
                                                                    <div className="close-head">
                                                                        <span>Latvia vs Moldova</span>
                                                                        <span className="close">
                                                                            <i className="fas fa-xmark" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="match-fixing">
                                                                        <div className="match-items">
                                                                            <div className="match-items-left">
                                                                                <div className="icon">
                                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                                </div>
                                                                                <div className="cont">
                                                                                    <span>Moldova</span>
                                                                                    <span className="winner">Match Winner</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="match-items-right">
                                                                                <div className="icon">
                                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                                </div>
                                                                                <span>5.20</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="combo-switch">
                                                                <span>
                                                                    Accept all odds changes
                                                                </span>
                                                                <label className="switch-com">
                                                                    <input type="checkbox" className="checkbox" hidden />
                                                                    <span className="checkbox-label">
                                                                        <span className="ball" />
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="ammount-items">
                                                                <form action="#">
                                                                    <input type="number" placeholder="Bet Amount" />
                                                                    <button type="submit">
                                                                        Max
                                                                    </button>
                                                                </form>
                                                            </div>
                                                            <div className="possible-win">
                                                                <span>Possible win</span>
                                                                <span className="amount">$300</span>
                                                            </div>
                                                            <div className="combo-footer">
                                                                <a href="#0" className="cmn--btn">
                                                                    <span> Place Bet $300</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade show active" id="bbetsmobile" role="tabpanel">
                                                    <div className="combo-wrapper">
                                                        <div className="close-box">
                                                            <div className="close-items">
                                                                <div className="close-head">
                                                                    <span>Scotland vs Ukraine</span>
                                                                    <span className="close">
                                                                        <i className="fas fa-xmark" />
                                                                    </span>
                                                                </div>
                                                                <div className="match-fixing">
                                                                    <div className="match-items">
                                                                        <div className="match-items-left">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                            </div>
                                                                            <div className="cont">
                                                                                <span>Scotland</span>
                                                                                <span className="winner">Match Winner</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>3.20</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="match-items">
                                                                        <div className="match-items-left">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                            </div>
                                                                            <div className="cont">
                                                                                <span>Draw</span>
                                                                                <span className="winner">Match Winner</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>4.60</span>
                                                                        </div>
                                                                    </div>
                                                                    <p>
                                                                        Add 2 more outcome(s) with odds greater than 1.4 to get x1.08
                                                                    </p>
                                                                    <div className="match-items match-progress">
                                                                        <div className="match-items-left">
                                                                            <div className="prabox-wrap">
                                                                                <div className="terminal-bar">
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                </div>
                                                                                <div className="bar-count">
                                                                                    <p>x1.08</p>
                                                                                    <p>x1,15</p>
                                                                                    <p>x1.20</p>
                                                                                    <p>x1.50</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>1.00</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="match-items match-odds">
                                                                        <div className="match-items-left">
                                                                            <div className="cont">
                                                                                <span>Total odds</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>12.19</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="combo-switch">
                                                            <span>
                                                                Accept all odds changes
                                                            </span>
                                                            <label className="switch-com">
                                                                <input type="checkbox" className="checkbox" hidden />
                                                                <span className="checkbox-label">
                                                                    <span className="ball" />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="ammount-items">
                                                            <form action="#">
                                                                <input type="number" placeholder="Bet Amount" />
                                                                <button type="submit">
                                                                    Max
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="possible-win">
                                                            <span>Possible win</span>
                                                            <span className="amount">$300</span>
                                                        </div>
                                                        <div className="combo-footer">
                                                            <a href="#0" className="cmn--btn">
                                                                <span> Place Bet $300</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-box">
                                            <div className="accordion" id="accordionExamplemy">
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingThree03">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            <span className="icon"><i className="fa-sharp fa-solid fa-gear" /></span>
                                                            <span>Settings</span>
                                                        </button>
                                                        <div id="collapseThreethree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <a href="#0" className="condition-box">
                                                                    Terms &amp; Conditions
                                                                </a>
                                                                <div className="select-wrap">
                                                                    <div className="select-one">
                                                                        <select className="select" name="select" id="n-select2">
                                                                            <option value={1}>Lunguage</option>
                                                                            <option value={1}>English</option>
                                                                            <option value={1}>Spanish</option>
                                                                        </select>
                                                                        <span className="add">English</span>
                                                                    </div>
                                                                    <div className="select-two">
                                                                        <select className="select" name="select" id="s-select2">
                                                                            <option value={1}>Odds</option>
                                                                            <option value={1}>Decimal</option>
                                                                            <option value={1}>Decimal Odds</option>
                                                                        </select>
                                                                        <span className="add">Decimal</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popular-bets">
                                        <div className="head">
                                            <h6>
                                                Popular bets
                                            </h6>
                                        </div>
                                        <ul className="popoular-body">
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault4" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Prance
                                                        </span>
                                                        <span className="text3">
                                                            France - Austria
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        2.37
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault5" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Turkey
                                                        </span>
                                                        <span className="text3">
                                                            Turkey - Luxembourg
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        1.37
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault6" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Alkmaar
                                                        </span>
                                                        <span className="text3">
                                                            Alkmaar - Apollon
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        3.47
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Live