import React from 'react'
import Footer from '../components/Footer'

const EBasketball = () => {
    return (
        <>
            <div className="body-middle">
                {/*Breadcumnd*/}
                <div className="breadcumnd-banner">
                    <div className="container">
                        <div className="breadcumd-content">
                            <h1>
                                eBasketball
                            </h1>
                            <ul className="bread-tag">
                                <li>
                                    <a href="#0">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fas fa-arrow-right" />
                                </li>
                                <li>
                                    eBasketball
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*Breadcumnd*/}
                {/*Main-body-tabing*/}
                <div className="main-body-tabbing">
                    <div className="container">
                        <div className="main-tabbing">
                            <div className="tab-content" id="tabContentmain">
                                {/*all tab start*/}
                                <div className="tab-pane fade show active" id="betsall" role="tabpanel">
                                    <div className="match-table">
                                        {/*table five*/}
                                        <div className="match-table-head mt-span-7 mb-3">
                                            <h3>
                                                Live events
                                            </h3>
                                        </div>
                                        <div className="table-wrap mb-2">
                                            <div className="table-inner efootball-customize">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span className="leuge-title">
                                                            Cyberfootball. eSports Battle. Europa League
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Half 2'47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Leicester City (Bulka)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Monaco (djamik_afc)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span> H1 H2</a>
                                                            <a href="#0">10</a>
                                                            <a href="#0"><span className="blods">1</span> 10</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/f1.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner efootball-customize">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span className="leuge-title">
                                                            Cyberfootball. eSports Battle. Europa League
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Half 2'47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Bayer 04 Leverkusen (Andy_M)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Real Sociedad (Papa_blond)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span> H1 H2</a>
                                                            <a href="#0">10</a>
                                                            <a href="#0"><span className="blods">1</span> 10</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/f2.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner efootball-customize">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span className="leuge-title">
                                                            Cyberfootball. eSports Battle. FA Cup
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Half 2'47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Manchester United (ECF_DaVa)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Tottenham Hotspur (ECF_dm1trena)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span> H1 H2</a>
                                                            <a href="#0">10</a>
                                                            <a href="#0"><span className="blods">1</span> 10</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/f3.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner efootball-customize">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span className="leuge-title">
                                                            Cyberfootball. eSports Battle. Europa League
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Half 2'47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Liverpool (ECF_slezaintima)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Manchester City (ECF_Upcake22)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span> H1 H2</a>
                                                            <a href="#0">10</a>
                                                            <a href="#0"><span className="blods">1</span> 10</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/f4.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner efootball-customize">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span className="leuge-title">
                                                            Cyberfootball. eSports Battle. Europa League
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Half 2'47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Bayer 04 Leverkusen (Andy_M)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Real Sociedad (Papa_blond)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span> H1 H2</a>
                                                            <a href="#0">10</a>
                                                            <a href="#0"><span className="blods">1</span> 10</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/f6.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner efootball-customize">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span className="leuge-title">
                                                            Cyberfootball. FIFA. Virtual eComp. La Liga
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Half 2'47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Chelsea (virtual_1)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Manchester City (Virtual_2)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span> H1 H2</a>
                                                            <a href="#0">10</a>
                                                            <a href="#0"><span className="blods">1</span> 10</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/f7.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner efootball-customize">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span className="leuge-title">
                                                            Cyberfootball. eSports Battle. Europa League
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Period 3 '47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Real Madrid (virtual_3)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Atletico Madrid (Virtual_4)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span> H1 H2</a>
                                                            <a href="#0">10</a>
                                                            <a href="#0"><span className="blods">1</span> 10</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/f8.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner efootball-customize">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span className="leuge-title">
                                                            Cyberfootball. eSports Battle. Europa League
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li className="dotsred">
                                                            Half 2'47
                                                        </li>
                                                        <li>
                                                            Winner. Main time
                                                        </li>
                                                        <li>
                                                            Total
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <div className="table-body-left ebasket-customize d-flex align-items-center justify-content-between">
                                                        <div className="ebasket-left">
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Bayer 04 Leverkusen (Andy_M)</span>
                                                                </a>
                                                            </div>
                                                            <div className="items">
                                                                <a href="#0">
                                                                    <span className="icon">
                                                                        <i className="icon-basketball" />
                                                                    </span>
                                                                    <span>Real Sociedad (Papa_blond)</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="ebasket-right-content">
                                                            <a href="#0"><span className="blods">1</span> H1 H2</a>
                                                            <a href="#0">10</a>
                                                            <a href="#0"><span className="blods">1</span> 10</a>
                                                        </div>
                                                    </div>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.05</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">X</span>
                                                            <span>7.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">5.4</span>
                                                            <span>5.10</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 1.50</span>
                                                            <span>8.02</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 71.5</span>
                                                            <span>1.27</span>
                                                        </a>
                                                        <a href="https://www.youtube.com/watch?v=Qg6zu49kXSA" className="table-pointing-box video-btn">
                                                            <span className="basketman">
                                                                <img src="assets/img/table/f2.png" alt="img" />
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="match-table-head pt-20">
                                            <h3 className="tody-space">
                                                Today
                                            </h3>
                                        </div>
                                        <div className="table-wrap pb-20 mb-3">
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/kbl.png" alt="icon" />
                                                        </span>
                                                        <span>
                                                            South Korean Championship. KBL
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            Live in: 12m
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>KCC Egis</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/kcc.png" alt="flag" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/promy.png" alt="flag" />
                                                                </span>
                                                                <span>Dongbu Promy</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>1.70</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>2.98</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+17</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span>
                                                            Championship of the Philippines. NCAA
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            13:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>UPHSD Altas</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/altas.png" alt="flag" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/eac.png" alt="flag" />
                                                                </span>
                                                                <span>EAC Generals</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>-</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>-</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+17</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span>
                                                            Championship of the Philippines. Maharlik...
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            13:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>General Santos Warriors</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/santos.png" alt="flag" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <i className="icon-football" />
                                                                </span>
                                                                <span>Laguna Hiros</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.25</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+17</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-basketball" />
                                                        </span>
                                                        <span>
                                                            Championship of the Philippines. Maharlik...
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            13:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>Caloocan Excellence</span>
                                                                <span className="icon">
                                                                    <i className="icon-football" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/cagers.png" alt="img" />
                                                                </span>
                                                                <span>Muntinlupa
                                                                    Cagers</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+17</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/korislinga.png" alt="img" />
                                                        </span>
                                                        <span>
                                                            Championship of Finland. Korisliiga
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            11:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total. Half 1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>BC Nokia</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/nokia.png" alt="img" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/eagul.png" alt="img" />
                                                                </span>
                                                                <span>Helsinki Seagulls</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">x</span>
                                                            <span>3.51</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.86</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+42</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/korislinga.png" alt="img" />
                                                        </span>
                                                        <span>
                                                            Championship of Finland. Korisliiga
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            11:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total. Half 1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>KTP Kotka Basket</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/kataja.png" alt="img" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/kataja2.png" alt="img" />
                                                                </span>
                                                                <span>Kataja</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">x</span>
                                                            <span>3.51</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.86</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+42</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/korislinga.png" alt="img" />
                                                        </span>
                                                        <span>
                                                            Championship of Finland. Korisliiga
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            11:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total. Half 1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>Korihait Uusikaupunki</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/oriha.png" alt="img" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/honka.png" alt="img" />
                                                                </span>
                                                                <span>Tapiola Honka</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">x</span>
                                                            <span>3.51</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.86</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+42</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/paf.png" alt="img" />
                                                        </span>
                                                        <span>
                                                            Championship of Latvia and Estonia
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            11:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total. Half 1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>BC Kalev Cramo Tallinn</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/cramo.png" alt="img" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/rakvere.png" alt="img" />
                                                                </span>
                                                                <span>Rakvere Tarvas</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">x</span>
                                                            <span>3.51</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.86</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+42</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/c-fifa.png" alt="img" />
                                                        </span>
                                                        <span>
                                                            Champions League FIBA
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            11:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total. Half 1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>VEF Riga</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/vef.png" alt="img" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/slb.png" alt="img" />
                                                                </span>
                                                                <span>Sport Lisboa Benfica</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">x</span>
                                                            <span>3.51</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.86</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+42</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/c-fifa.png" alt="img" />
                                                        </span>
                                                        <span>
                                                            Champions League FIBA
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            11:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total. Half 1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>Pınar Karşıyaka</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/pinar.png" alt="img" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/aek.png" alt="img" />
                                                                </span>
                                                                <span>AEK Athens</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">x</span>
                                                            <span>3.51</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.86</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+42</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/c-fifa.png" alt="img" />
                                                        </span>
                                                        <span>
                                                            Champions League FIBA
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            11:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total. Half 1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>Nymburk</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/nymburk.png" alt="img" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/bilbao.png" alt="img" />
                                                                </span>
                                                                <span>Bilbao</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">x</span>
                                                            <span>3.51</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.86</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+42</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-football" />
                                                        </span>
                                                        <span>
                                                            Swedish Championship
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            11:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total. Half 1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>Uppsala Basket</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/uppsala.png" alt="img" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/lulea.png" alt="img" />
                                                                </span>
                                                                <span>BC Lulea</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>11.97</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">x</span>
                                                            <span>3.51</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>12.86</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+42</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*Prematch*/}
                                        <div className="match-table-head">
                                            <h3>
                                                Tomorrow
                                            </h3>
                                        </div>
                                        {/*tommorw*/}
                                        <div className="table-wrap">
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/csgo2.png" alt="icon" />
                                                        </span>
                                                        <span>
                                                            CS:GO. Esports Battle 2x2
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            Live in: 6m
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>Rhyno</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/rhyno.png" alt="flag" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/partherss.png" alt="flag" />
                                                                </span>
                                                                <span>Panthers eSports</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.58</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>2.8</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.9</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.4</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+68</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/ecl.png" alt="icon" />
                                                        </span>
                                                        <span>
                                                            CS:GO. ESL Mistrzostwa Polski
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            13:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>Lodis by Illuminar</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/lodis.png" alt="flag" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/nin.png" alt="flag" />
                                                                </span>
                                                                <span>9Nine</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>4.32</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>2.98</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+25</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/international.png" alt="icon" />
                                                        </span>
                                                        <span>
                                                            CS:GO. Mystery Hype International
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            13:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>ONYX</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/dnyx.png" alt="flag" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/oopro.png" alt="flag" />
                                                                </span>
                                                                <span>OOProspects</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>5.8</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>2.98</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+25</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <img src="assets/img/table/ecl.png" alt="icon" />
                                                        </span>
                                                        <span>
                                                            CS:GO. ESL Mistrzostwa Polski
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            13:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>AGO</span>
                                                                <span className="icon">
                                                                    <img src="assets/img/table/sgo.png" alt="flag" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <img src="assets/img/table/nin.png" alt="flag" />
                                                                </span>
                                                                <span>9INE</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>1.25</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>2.98</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+25</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-inner">
                                                <div className="table-head">
                                                    <a href="details.html" className="left-compo">
                                                        <span>
                                                            <i className="icon-scgo" />
                                                        </span>
                                                        <span>
                                                            CS:GO. IEM Road to Rio EU RMR
                                                        </span>
                                                    </a>
                                                    <ul className="right-compo">
                                                        <li>
                                                            13:00
                                                        </li>
                                                        <li>
                                                            Match Winner
                                                        </li>
                                                        <li>
                                                            Total maps
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-body">
                                                    <ul className="table-body-left">
                                                        <li>
                                                            <a href="#0">
                                                                <span>Bad News Eagles</span>
                                                                <span className="icon">
                                                                    <i className="icon-scgo" />
                                                                </span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0" className="vs">
                                                                VS
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#0">
                                                                <span className="icon">
                                                                    <i className="icon-scgo" />
                                                                </span>
                                                                <span>Eternal Fire</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="table-body-right">
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">1</span>
                                                            <span>8.32</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">2</span>
                                                            <span>2.98</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">O 2.5</span>
                                                            <span>1.84</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="list">U 2.5</span>
                                                            <span>5.7</span>
                                                        </a>
                                                        <a href="#0" className="table-pointing-box">
                                                            <span className="last-digit">+25</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*all tab End*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/*Main-body-tabing*/}
                <Footer />

                {/*Footer Section*/}
                <a href="#0" className="click-btn" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                    <i className="icon-basketball" />
                </a>
            </div>
            {/*Middle Body*/}
            {/*Right Box Chat*/}
            <div className="right-site-menu">
                <div className="right-box">
                    <div className="right-wrapper-area">
                        <div className="blance-items">
                            <div className="left-blance">
                                <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                                <span className="text-blabce">Balance</span>
                            </div>
                            <span className="blance">
                                $300
                            </span>
                        </div>
                        <div className="betslip-wrapper">
                            <a href="#0" className="left-betslip">
                                <span className="bed2"><img src="assets/img/header/right-icon/bed2.png" alt="icon" /></span>
                                <span className="bed1"><img src="assets/img/header/right-icon/bed.svg" alt="icon" /></span>
                                <span className="text-bets">Bet Slip</span>
                            </a>
                            <a href="#0" className="left-betslip">
                                <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                                <span className="text-bets">My Bets</span>
                            </a>
                        </div>
                        <div className="combo-box">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className="nav-link link-secondary" id="combo-tab" data-bs-toggle="tab" data-bs-target="#coombo" href="#"><span>Single bets</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link link-secondary active" id="bets-tab" data-bs-toggle="tab" data-bs-target="#bbets" href="#"><span>Combo</span></a>
                                </li>
                            </ul>
                            <div className="tab-content" id="tabContentboxes">
                                <div className="tab-pane fade" id="coombo" role="tabpanel">
                                    <div className="combo-wrapper">
                                        <div className="combo-wrapper">
                                            <div className="close-box">
                                                <div className="close-items">
                                                    <div className="close-head">
                                                        <span>Latvia vs Moldova</span>
                                                        <span className="close">
                                                            <i className="fas fa-xmark" />
                                                        </span>
                                                    </div>
                                                    <div className="match-fixing">
                                                        <div className="match-items">
                                                            <div className="match-items-left">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                </div>
                                                                <div className="cont">
                                                                    <span>Moldova</span>
                                                                    <span className="winner">Match Winner</span>
                                                                </div>
                                                            </div>
                                                            <div className="match-items-right">
                                                                <div className="icon">
                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                </div>
                                                                <span>5.20</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="combo-switch">
                                                <span>
                                                    Accept all odds changes
                                                </span>
                                                <label className="switch-com">
                                                    <input type="checkbox" className="checkbox" hidden />
                                                    <span className="checkbox-label">
                                                        <span className="ball" />
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="ammount-items">
                                                <form action="#">
                                                    <input type="number" placeholder="Bet Amount" />
                                                    <button type="submit">
                                                        Max
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="possible-win">
                                                <span>Possible win</span>
                                                <span className="amount">$300</span>
                                            </div>
                                            <div className="combo-footer">
                                                <a href="#0" className="cmn--btn">
                                                    <span> Place Bet $300</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show active" id="bbets" role="tabpanel">
                                    <div className="combo-wrapper">
                                        <div className="close-box">
                                            <div className="close-items">
                                                <div className="close-head">
                                                    <span>Scotland vs Ukraine</span>
                                                    <span className="close">
                                                        <i className="fas fa-xmark" />
                                                    </span>
                                                </div>
                                                <div className="match-fixing">
                                                    <div className="match-items">
                                                        <div className="match-items-left">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                            </div>
                                                            <div className="cont">
                                                                <span>Scotland</span>
                                                                <span className="winner">Match Winner</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>3.20</span>
                                                        </div>
                                                    </div>
                                                    <div className="match-items">
                                                        <div className="match-items-left">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                            </div>
                                                            <div className="cont">
                                                                <span>Draw</span>
                                                                <span className="winner">Match Winner</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>4.60</span>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        Add 2 more outcome(s) with odds greater than 1.4 to get x1.08
                                                    </p>
                                                    <div className="match-items match-progress">
                                                        <div className="match-items-left">
                                                            <div className="prabox-wrap">
                                                                <div className="terminal-bar">
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                    <span />
                                                                </div>
                                                                <div className="bar-count">
                                                                    <p>x1.08</p>
                                                                    <p>x1,15</p>
                                                                    <p>x1.20</p>
                                                                    <p>x1.50</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>1.00</span>
                                                        </div>
                                                    </div>
                                                    <div className="match-items match-odds">
                                                        <div className="match-items-left">
                                                            <div className="cont">
                                                                <span>Total odds</span>
                                                            </div>
                                                        </div>
                                                        <div className="match-items-right">
                                                            <div className="icon">
                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                            </div>
                                                            <span>12.19</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="combo-switch">
                                            <span>
                                                Accept all odds changes
                                            </span>
                                            <label className="switch-com">
                                                <input type="checkbox" className="checkbox" hidden />
                                                <span className="checkbox-label">
                                                    <span className="ball" />
                                                </span>
                                            </label>
                                        </div>
                                        <div className="ammount-items">
                                            <form action="#">
                                                <input type="number" placeholder="Bet Amount" />
                                                <button type="submit">
                                                    Max
                                                </button>
                                            </form>
                                        </div>
                                        <div className="possible-win">
                                            <span>Possible win</span>
                                            <span className="amount">$300</span>
                                        </div>
                                        <div className="combo-footer">
                                            <a href="#0" className="cmn--btn">
                                                <span> Place Bet $300</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-box">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <span className="icon"><i className="fa-sharp fa-solid fa-gear" /></span>
                                            <span>Settings</span>
                                        </button>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="select-wrap">
                                                    <div className="select-one">
                                                        <select className="select" name="select" id="n-select">
                                                            <option value={1}>English</option>
                                                            <option value={1}>Spanish</option>
                                                        </select>
                                                        <span className="add">Lunguage</span>
                                                    </div>
                                                    <div className="select-two">
                                                        <select className="select" name="select" id="s-select">
                                                            <option value={1}>Decimal</option>
                                                            <option value={1}>Decimal Odds</option>
                                                        </select>
                                                        <span className="add">Odds</span>
                                                    </div>
                                                </div>
                                                <a href="#0" className="condition-box">
                                                    Terms &amp; Conditions
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popular-bets">
                        <div className="head">
                            <h6>
                                Popular bets
                            </h6>
                        </div>
                        <ul className="popoular-body">
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault1" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Prance
                                        </span>
                                        <span className="text3">
                                            France - Austria
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        2.37
                                    </span>
                                </div>
                            </li>
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault2" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Turkey
                                        </span>
                                        <span className="text3">
                                            Turkey - Luxembourg
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        1.37
                                    </span>
                                </div>
                            </li>
                            <li className="popular-items">
                                <label className="popular-itmes-left">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault3" />
                                    <span className="icon">
                                        <i className="icon-football" />
                                    </span>
                                    <span className="cont">
                                        <span className="text1">
                                            1x2
                                        </span>
                                        <span className="text2">
                                            Alkmaar
                                        </span>
                                        <span className="text3">
                                            Alkmaar - Apollon
                                        </span>
                                    </span>
                                </label>
                                <div className="popular-itmes-right">
                                    <span>
                                        3.47
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="modal right-menu-modal fade" id="exampleModal3" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <button type="button" className="btn-close cross-btn" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-body">
                            <div className="right-site-menu">
                                <div className="right-box">
                                    <div className="right-wrapper-area">
                                        <div className="blance-items">
                                            <div className="left-blance">
                                                <span><img src="assets/img/header/right-icon/wallet.svg" alt="icon" /></span>
                                                <span className="text-blabce">Balance</span>
                                            </div>
                                            <span className="blance">
                                                $300
                                            </span>
                                        </div>
                                        <div className="betslip-wrapper">
                                            <a href="#0" className="left-betslip">
                                                <span><img src="assets/img/header/right-icon/bed.svg" alt="icon" /></span>
                                                <span className="text-bets">Bet Slip</span>
                                            </a>
                                            <a href="#0" className="left-betslip">
                                                <span><img src="assets/img/header/right-icon/bets.svg" alt="icon" /></span>
                                                <span className="text-bets">My Bets</span>
                                            </a>
                                        </div>
                                        <div className="combo-box">
                                            <ul className="nav">
                                                <li className="nav-item">
                                                    <a className="nav-link link-secondary" data-bs-toggle="tab" data-bs-target="#coombomobile" href="#"><span>Single bets</span></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link link-secondary active" data-bs-toggle="tab" data-bs-target="#bbetsmobile" href="#"><span>Combo</span></a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="tabContentboxesmobile">
                                                <div className="tab-pane fade" id="coombomobile" role="tabpanel">
                                                    <div className="combo-wrapper">
                                                        <div className="combo-wrapper">
                                                            <div className="close-box">
                                                                <div className="close-items">
                                                                    <div className="close-head">
                                                                        <span>Latvia vs Moldova</span>
                                                                        <span className="close">
                                                                            <i className="fas fa-xmark" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="match-fixing">
                                                                        <div className="match-items">
                                                                            <div className="match-items-left">
                                                                                <div className="icon">
                                                                                    <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                                </div>
                                                                                <div className="cont">
                                                                                    <span>Moldova</span>
                                                                                    <span className="winner">Match Winner</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="match-items-right">
                                                                                <div className="icon">
                                                                                    <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                                </div>
                                                                                <span>5.20</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="combo-switch">
                                                                <span>
                                                                    Accept all odds changes
                                                                </span>
                                                                <label className="switch-com">
                                                                    <input type="checkbox" className="checkbox" hidden />
                                                                    <span className="checkbox-label">
                                                                        <span className="ball" />
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="ammount-items">
                                                                <form action="#">
                                                                    <input type="number" placeholder="Bet Amount" />
                                                                    <button type="submit">
                                                                        Max
                                                                    </button>
                                                                </form>
                                                            </div>
                                                            <div className="possible-win">
                                                                <span>Possible win</span>
                                                                <span className="amount">$300</span>
                                                            </div>
                                                            <div className="combo-footer">
                                                                <a href="#0" className="cmn--btn">
                                                                    <span> Place Bet $300</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade show active" id="bbetsmobile" role="tabpanel">
                                                    <div className="combo-wrapper">
                                                        <div className="close-box">
                                                            <div className="close-items">
                                                                <div className="close-head">
                                                                    <span>Scotland vs Ukraine</span>
                                                                    <span className="close">
                                                                        <i className="fas fa-xmark" />
                                                                    </span>
                                                                </div>
                                                                <div className="match-fixing">
                                                                    <div className="match-items">
                                                                        <div className="match-items-left">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                            </div>
                                                                            <div className="cont">
                                                                                <span>Scotland</span>
                                                                                <span className="winner">Match Winner</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>3.20</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="match-items">
                                                                        <div className="match-items-left">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/footaball.svg" alt="icon" />
                                                                            </div>
                                                                            <div className="cont">
                                                                                <span>Draw</span>
                                                                                <span className="winner">Match Winner</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>4.60</span>
                                                                        </div>
                                                                    </div>
                                                                    <p>
                                                                        Add 2 more outcome(s) with odds greater than 1.4 to get x1.08
                                                                    </p>
                                                                    <div className="match-items match-progress">
                                                                        <div className="match-items-left">
                                                                            <div className="prabox-wrap">
                                                                                <div className="terminal-bar">
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                    <span />
                                                                                </div>
                                                                                <div className="bar-count">
                                                                                    <p>x1.08</p>
                                                                                    <p>x1,15</p>
                                                                                    <p>x1.20</p>
                                                                                    <p>x1.50</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>1.00</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="match-items match-odds">
                                                                        <div className="match-items-left">
                                                                            <div className="cont">
                                                                                <span>Total odds</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="match-items-right">
                                                                            <div className="icon">
                                                                                <img src="assets/img/header/right-icon/uptodwon.svg" alt="icon" />
                                                                            </div>
                                                                            <span>12.19</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="combo-switch">
                                                            <span>
                                                                Accept all odds changes
                                                            </span>
                                                            <label className="switch-com">
                                                                <input type="checkbox" className="checkbox" hidden />
                                                                <span className="checkbox-label">
                                                                    <span className="ball" />
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="ammount-items">
                                                            <form action="#">
                                                                <input type="number" placeholder="Bet Amount" />
                                                                <button type="submit">
                                                                    Max
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="possible-win">
                                                            <span>Possible win</span>
                                                            <span className="amount">$300</span>
                                                        </div>
                                                        <div className="combo-footer">
                                                            <a href="#0" className="cmn--btn">
                                                                <span> Place Bet $300</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-box">
                                            <div className="accordion" id="accordionExamplemy">
                                                <div className="accordion-item">
                                                    <div className="accordion-header" id="headingThree03">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            <span className="icon"><i className="fa-sharp fa-solid fa-gear" /></span>
                                                            <span>Settings</span>
                                                        </button>
                                                        <div id="collapseThreethree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <a href="#0" className="condition-box">
                                                                    Terms &amp; Conditions
                                                                </a>
                                                                <div className="select-wrap">
                                                                    <div className="select-one">
                                                                        <select className="select" name="select" id="n-select2">
                                                                            <option value={1}>Lunguage</option>
                                                                            <option value={1}>English</option>
                                                                            <option value={1}>Spanish</option>
                                                                        </select>
                                                                        <span className="add">English</span>
                                                                    </div>
                                                                    <div className="select-two">
                                                                        <select className="select" name="select" id="s-select2">
                                                                            <option value={1}>Odds</option>
                                                                            <option value={1}>Decimal</option>
                                                                            <option value={1}>Decimal Odds</option>
                                                                        </select>
                                                                        <span className="add">Decimal</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="popular-bets">
                                        <div className="head">
                                            <h6>
                                                Popular bets
                                            </h6>
                                        </div>
                                        <ul className="popoular-body">
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault4" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Prance
                                                        </span>
                                                        <span className="text3">
                                                            France - Austria
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        2.37
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault5" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Turkey
                                                        </span>
                                                        <span className="text3">
                                                            Turkey - Luxembourg
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        1.37
                                                    </span>
                                                </div>
                                            </li>
                                            <li className="popular-items">
                                                <label className="popular-itmes-left">
                                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault6" />
                                                    <span className="icon">
                                                        <i className="icon-football" />
                                                    </span>
                                                    <span className="cont">
                                                        <span className="text1">
                                                            1x2
                                                        </span>
                                                        <span className="text2">
                                                            Alkmaar
                                                        </span>
                                                        <span className="text3">
                                                            Alkmaar - Apollon
                                                        </span>
                                                    </span>
                                                </label>
                                                <div className="popular-itmes-right">
                                                    <span>
                                                        3.47
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default EBasketball